import normalize from "json-api-normalizer";

import { GET, PATCH } from "../utility/api";

export const RECEIVE_LOAN_REQUEST_MATCHES = "RECEIVE_LOAN_REQUEST_MATCHES";
export const RECEIVE_LOAN_REQUEST_MATCH = "RECEIVE_LOAN_REQUEST_MATCH";
export const SAVE_LOAN_REQUEST_MATCHES = "SAVE_LOAN_REQUEST_MATCHES";
export const SAVE_ALL_LOAN_REQUEST_MATCHES = "SAVE_ALL_LOAN_REQUEST_MATCHES"
export const DELETE_LOAN_REQUEST_MATCHES = "DELETE_LOAN_REQUEST_MATCHES"
export const DELETE_ALL_LOAN_REQUEST_MATCHES = "DELETE_ALL_LOAN_REQUEST_MATCHES"

export const receiveLoanRequestMatches = (loanRequestMatches) => ({
  type: RECEIVE_LOAN_REQUEST_MATCHES,
  payload: loanRequestMatches,
});

export const receiveLoanRequestMatch = (loanRequestMatch) => ({
  type: RECEIVE_LOAN_REQUEST_MATCH,
  payload: loanRequestMatch,
});

export const deleteLoanRequestMatches = (loanRequestMatch) => {
  return (
  {
   type: DELETE_LOAN_REQUEST_MATCHES,
   payload: loanRequestMatch
  }
 )
};

export const deleteAllLoanRequestMatches = (loanRequestMatches) => {
  return (
  {
   type: DELETE_ALL_LOAN_REQUEST_MATCHES,
   payload: loanRequestMatches
  }
 )
};

export const saveLoanRequestMatches = (loanRequestMatch) => {
  return (
  {
   type: SAVE_LOAN_REQUEST_MATCHES,
   payload: loanRequestMatch
  }
 )
};

export const saveAllLoanRequestMatches = (loanRequestMatches) => {
  return (
  {
   type: SAVE_ALL_LOAN_REQUEST_MATCHES,
   payload: loanRequestMatches
  }
 )
};

export const saveLoanRequest = ({ loanRequestMatch }) => (dispatch) =>  {
  const result = saveLoanRequestMatches(loanRequestMatch);
  return dispatch(result);
};

export const deleteLoanRequest = ({ loanRequestMatch }) => (dispatch) =>  {
  const result = deleteLoanRequestMatches(loanRequestMatch);
  return dispatch(result);
};

export const deleteAllLoanRequest = ({ loanRequestMatch }) => (dispatch) =>  {
  const result = deleteLoanRequestMatches(loanRequestMatch);
  return dispatch(result);
};

export const saveAllLoanRequest = ( loanRequestMatches ) => (dispatch) =>  {
  const result = saveAllLoanRequestMatches(loanRequestMatches);
  return dispatch(result);
};


export const fetchLoanRequestMatches = ({ loanRequestId }) => (dispatch) => {
  return GET("/api/dashboard/loan_requests/" + loanRequestId + "/loan_request_matches").then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveLoanRequestMatches(normalized.loanRequestMatch));
  });
};

export const updateLoanRequestMatchInterest = ({ loanRequestId, id, interest }) => (dispatch) => {
  return PATCH("/api/loan_requests/" + loanRequestId + "/loan_request_matches/" + id, { interest }).then(
    (res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveLoanRequestMatch(normalized.loanRequestMatch));
    }
  );
};
