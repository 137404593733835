import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import { updateLoanRequestMatchInterest } from "../../../../../actions/loanRequestMatchesActions";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";
import { CheckIcon } from "@heroicons/react/24/outline";
import { saveLoanRequest } from "../../../../../actions/loanRequestMatchesActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MatchListItem({
                         loanRequestId,
                         id,
                         matches,
                         setMatches,
                         allSelectedItems,
                       }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);
  const [active, setActive] = useState(false);
  const loanRequestMatch = useSelector(
    (state) => state.loanRequestMatches.items[id]
  );
  useEffect(() => {
    setActive(matches.map((match) => match.id).includes(loanRequestMatch.id));
  }, [matches]);

  const handleInterest = (interest) => {
    dispatch(updateLoanRequestMatchInterest({ loanRequestId, id, interest }));
  };
  if (!loanRequestMatch) return null;

  return (
    <div className=" min-w-[900px]">
      <div className="">
        <div className="p-4 sm:px-6">
          <dl
            className="grid grid-cols-1 gap-x-4 gap-y-8"
            style={{ gridTemplateColumns: "repeat(24, minmax(0, 1fr))" }}
          >
            {loanRequestMatch.attributes.notInterestedAt == null &&
            loanRequestMatch.attributes.interestedAt == null ? (
              <button
                className="sm:col-span-1 self-center"
                onClick={() => handleInterest(true)}
              >
                <MarkIcon filled={false}/>
              </button>
            ) : loanRequestMatch.attributes.interestedAt ? (
              <button
                className="sm:col-span-1 self-center"
                onClick={() => handleInterest(false)}
              >
                <MarkIcon filled={true}/>
              </button>
            ) : (
              <button
                className="sm:col-span-1 self-center"
                onClick={() => handleInterest(true)}
              >
                <MarkIcon filled={false}/>
              </button>
            )}
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                {loanRequestMatch?.attributes?.userProfile?.companyName || loanRequestMatch?.attributes?.userProfile?.title || "LenderPrism"}
              </dt>
              {/* <dd className="mt-1 text-sm text-gray-900">{bankName}</dd> */}
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                {loanRequestMatch?.attributes?.userProfile?.firstName} {loanRequestMatch?.attributes?.userProfile?.lastName}
              </dd>
            </div>
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Minimum Loan Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                ${" "}
                {loanRequestMatch?.attributes?.minimumLoanAmountCents
                  ? loanRequestMatch?.attributes?.minimumLoanAmountCents
                  : "0"}
              </dd>
            </div>
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Maximum Loan Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                ${" "}
                {loanRequestMatch?.attributes?.maximumLoanAmountCents
                  ? loanRequestMatch?.attributes?.maximumLoanAmountCents
                  : "0"}
              </dd>
            </div>
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Financing Services
              </dt>
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                {loanRequestMatch?.attributes?.typeOfFinancing
                  ? loanRequestMatch?.attributes?.typeOfFinancing
                  : "s"}
              </dd>
            </div>
            <div
              style={{ gridColumn: "span 3" }}
              className="self-center rounded-md"
            >
              {active ? (
                <button
                  rel="noopener noreferrer"
                  className="flex gap-2 items-center w-full cursor-pointer border border-transparent px-4 py-2 text-[14px] font-medium text-emerald-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    className="min-w-min"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.8632 3.32223C13.926 3.36993 13.9789 3.42956 14.0186 3.49769C14.0584 3.56583 14.0843 3.64114 14.0949 3.71931C14.1056 3.79748 14.1007 3.87698 14.0805 3.95325C14.0604 4.02953 14.0254 4.10109 13.9776 4.16383L7.57761 12.5638C7.5257 12.6319 7.45983 12.688 7.38443 12.7285C7.30902 12.769 7.22583 12.7929 7.14044 12.7986C7.05504 12.8042 6.96942 12.7916 6.88931 12.7615C6.80921 12.7313 6.73648 12.6844 6.67601 12.6238L3.07601 9.02383C2.97003 8.91009 2.91233 8.75965 2.91507 8.60421C2.91781 8.44877 2.98078 8.30046 3.09071 8.19053C3.20064 8.0806 3.34895 8.01763 3.50439 8.01489C3.65983 8.01215 3.81027 8.06985 3.92401 8.17583L7.03921 11.2902L13.0232 3.43663C13.1195 3.31019 13.2621 3.22713 13.4195 3.20569C13.577 3.18424 13.7366 3.22615 13.8632 3.32223Z"
                      fill="#059669"
                    />
                  </svg>
                  Selected
                </button>
              ) : (
                <button
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    setSelected(true);
                    dispatch(saveLoanRequest({ loanRequestMatch }));
                    setMatches((prev) => {
                      return [...prev, loanRequestMatch];
                    });
                  }}
                  className="w-full cursor-pointer rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-[14px] font-medium text-white hover:bg-emerald-700"
                >
                  Select Lender
                </button>
              )}
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default withGlobalProviders(MatchListItem);
