import React from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";

import withGlobalProviders from "../../../../general/withGlobalProvider";

import LenderProfileListItem from "./LenderProfileListItem";
import LenderProfileEmptyState from "./LenderProfileEmptyState";

function LenderProfileItems(unapprovedLenders) {
  // const { loaded, unapprovedLenders } = useSelector((state) => state.loanRequests);

  const lenders = unapprovedLenders.unapprovedLenders

  // if (loaded && !unapprovedLenders) return <LenderProfileEmptyState />;
  if (!lenders) return <LenderProfileEmptyState />;

  return (
    <div className="flex space-y-6 flex-col">
      {
        lenders.map((unapprovedLender) => (
        <LenderProfileListItem unapprovedLender={unapprovedLender} />
        ))
      }
      {Object.keys(lenders).length == 0 && <LenderProfileEmptyState next={true} />}
    </div>
  );
}

export default withGlobalProviders(LenderProfileItems);
