import React, {useState} from "react";
import PageBody from "../authenticated/general/PageBody";
import Hero from "../general/Hero";
import CuDocumentIcon from "../../assets/svgs/CuDocumentIcon";
import MapPinIcon from "../../assets/svgs/MapPinIcon";
import CheckBoxIcon from "../../assets/svgs/CheckBoxIcon";
import PrivacyTermsIcon from "../../assets/svgs/PrivacyTermsIcon";
import {supportQuestion} from "../../actions/supportQuestionActions";
import {useDispatch} from "react-redux";

import withGlobalProviders from "../general/withGlobalProvider";

function Index() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    let formData = [];
    form.forEach(item => {
      if(item.name) formData[item.name] = item.value;
    });
    dispatch(supportQuestion({...formData}));

    setName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
  };

  return (
    <PageBody>
      <Hero />
      <div className="flex flex-col">
        <div className="px-5 md:px-12 lg:px-32">
          <p className="text-2xl text-black font-bold mt-20 mb-7 text-center md:text-left">How LenderPrism Works</p>
          <p className="text-base text-black">
            LenderPrism is not a
            <span className="font-bold"> LOAN </span>
            matching engine.
          </p>
          <p className="text-base text-black mb-7">
            It's a
            <span className="font-bold"> LENDER </span>
            matching engine.
          </p>
          <p className="text-base text-black mb-10">
            What does that mean? Well, LenderPrism is a powerful resource to help you scour <br />
            the market for real estate lenders. It put's brokers and borrowers in the driver's seat.
          </p>
          <p className="text-base text-black mb-7">
            LenderPrism is a robust tool comprised of two Products:
            <span className="font-bold"> The Lender Funnel </span>
            <span className="text-xs align-top">TM </span>
            and
            <span className="font-bold"> The Prospector </span>
            <span className="text-xs align-top">TM</span>
          </p>
        </div>
        <div className="flex md:flex-row flex-col mt-16 py-20 bg-gray-100">
          <div className="w-full px-5 md:px-12 lg:pl-32 md:w-1/2">
            <div className="flex flex-row items-center mb-3">
              <CuDocumentIcon />
              <p className="ml-2 text-2xl text-black font-bold">
                Lender Funnel
                <span className="text-xs align-top">TM</span>
              </p>
            </div>
            <p className="text-base text-congress-blue-600 font-bold mb-7">Free to Use</p>
            <p className="text-base text-gray-700 mb-7">
              Use The Prospector TM to identify over 10,000 banks and credit unions in over 90,000 locations nationwide.
            </p>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Search hundreds of non-bank lenders, verified to be active</p>
            </div>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Enter basic search criteria</p>
            </div>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Identify the best possible matches to fit your lending needs</p>
            </div>
            <div className="flex flex-row gap-3">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p>Gain access to contact information to lenders who are real people offering lending solutions</p>
            </div>
          </div>
          <div className="w-full mt-10 md:mt-0 px-5 md:px-12 lg:pr-32 md:w-1/2">
            <div className="flex flex-row items-center mb-3">
              <MapPinIcon />
              <p className="ml-2 text-2xl text-black font-bold">
                The Prospector
                <span className="text-xs align-top">TM</span>
              </p>
            </div>
            <p className="text-base text-congress-blue-600 font-bold mb-7">Free to Use</p>
            <p className="text-base text-gray-700 mb-7">
              Use The Prospector TM to identify over 10,000 banks and credit unions in over 90,000 locations nationwide.
            </p>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Search more than 10,000 banks and credit unions</p>
            </div>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Simply enter the property zip code and search by distance</p>
            </div>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Select the lenders that match your needs to add to your list and compare results</p>
            </div>
            <div className="flex flex-row">
              <CheckBoxIcon className="pt-[3px] h-5 w-5 text-green-600" />
              <p className="px-2">Contact the lenders you’re most inerested in</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row px-5 md:px-12 lg:px-32 mt-20">
          <a href="#for_brokers" className="px-5 py-3 bg-gray-100 text-center mb-5 mr-5 text-lg">I'm a Mortgage Broker</a>
          <a href="#for_borrowers" className="px-5 py-3 bg-gray-100 text-center mb-5 mr-5 text-lg">I'm a Borrower</a>
          <a href="#for_lenders" className="px-5 py-3 bg-gray-100 text-center mb-5 mr-5 text-lg">I'm a Direct Lender</a>
        </div>
        <div id="for_brokers" className="flex flex-row py-14 mt-20 bg-gray-100">
          <div className="lg:w-7/12 px-5 md:px-12 lg:pl-32">
            <p className="text-2xl text-black text-center md:text-left font-bold mb-10">For Brokers</p>
            <p className="text-lg mb-3">
              Use either the Lender Funnel to identify active lenders or the Prospector to find 1,000s of lenders within a radius.
            </p>
            <p className="text-lg mb-6">
              We offer you the most comprehensive lender database on the market for FREE! Identify the right lenders for your transactions.
            </p>
            <div className="border-2 border-slate-800 rounded-lg w-auto h-auto p-5">
              <p className="text-2xl text-black text-center md:text-left font-bold mb-5">Need more?</p>
              <div className="flex flex-col md:flex-row">
                <p className="text-gray-700 text-center md:text-left text-md lg:w-3/4">
                  Get Access to exclusive lender presentations, promote yourself to Borrows, and collaborate with your peers
                </p>
                <a href="/login?ref=subscribe">
                  <button className="rounded-lg text-white bg-congress-blue-600 mt-5 lg:mt-0 lg:ml-4 text-md p-1 md:p-3">
                    Subscribe Now
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="hidden place-content-center lg:block lg:w-5/12 lg:pr-32 m-auto 4xl:w-1/2">
            <img
              className="rounded-lg h-auto"
              src="/forBrokers.png"
              type="image/png"
            >
            </img>
          </div>
        </div>
        <div id="for_borrowers" className="flex flex-row mt-20 px-5 md:px-12 lg:px-32">
          <div className="hidden lg:block lg:w-5/12 lg:mr-5 m-auto 4xl:w-1/2">
            <img
              className="rounded-lg h-auto"
              src="/forBorrowers.png"
              type="image/png"
            >
            </img>
          </div>
          <div className="lg:w-7/12 md:ml-10">
            <p className="text-2xl text-black text-center md:text-left font-bold mb-10">For Borrowers</p>
            <p className="text-lg mb-3">
              Search for multifamily / CRE lenders and mortgage brokers all in one place.
            </p>
            <p className="text-lg mb-3">
              Get all your multifamily and commercial real estate funding needs met with LenderPrism.
            </p>
            <p className="text-lg mb-10">
              Search and compare lenders for the best funding options to match your needs.
            </p>
            <div className="border-2 border-slate-800 rounded-lg w-auto h-auto p-5">
              <p className="text-2xl text-black text-center md:text-left font-bold mb-5">Need more?</p>
              <div className="flex flex-col md:flex-row">
                <p className="text-gray-700 text-center md:text-left text-md lg:w-3/4">
                  Get Access to exclusive lender presentations and collaborate with your peers to source the best loan for you.
                </p>
                <a href="/login?ref=subscribe">
                  <button className="rounded-lg text-white bg-congress-blue-600 mt-5 lg:ml-4 lg:mt-0 text-md p-1 md:p-3">
                    Subscribe Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="for_lenders" className="flex flex-row mt-20 content-center bg-gray-100 py-14 px-5 md:px-12 lg:px-32">
          <div className="w-full lg:w-1/2 md:pl-12 md:pr-6 lg:pr-10">
            <p className="text-2xl text-black text-center md:text-left font-bold mb-10">For Lenders</p>
            <p className="text-lg mb-3">
              Get in front of borrowers and brokers looking for multifamily and CRE loans in real-time.
            </p>
            <p className="text-lg mb-3">
              Create a FREE profile to be matched to transactions that fit your “credit box.”
            </p>
            <p className="text-lg mb-3">
              Showcase your products and services in live, exclusive presentations to an engaged audience.
            </p>
            <p className="text-lg mb-6">
              Grow your network and expand your business with premium listings in the search results.
            </p>
            <button className="rounded-lg text-white bg-congress-blue-600 text-md p-1 md:p-2 mt-5">
              Create Your Free Profile
            </button>
          </div>
          <div className="hidden lg:block lg:w-1/2 pr-5 m-auto pl-4 4xl:w-1/2">
            <img
              className="rounded-lg w-auto"
              src="/forLenders.png"
              type="image/png"
            >
            </img>
          </div>
        </div>
        <div className="w-full lg:w-2/3 h-auto bg-gray-100 flex-col mx-auto my-28 py-10 px-30">
          <p className="text-center text-4xl text-black font-bold mt-5">
            Need help? Have questions or comments?
          </p>
          <form className="flex flex-col justify-center mt-20 px-12 md:px-24" onSubmit={handleSubmit}>
            <div className="flex flex-row justify-center items-center px-3 py-1">
              <input
                type="text"
                name="name"
                className="w-full h-14 rounded-md"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="flex flex-row flex-wrap justify-between items-center mt-5">
              <div className="w-full md:w-1/2 px-3 py-1">
                <input
                  type="text"
                  name="email"
                  className="w-full h-14 rounded-md"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="w-full md:w-1/2 px-3 py-1 mt-5 md:mt-0">
                <input
                  type="number"
                  name="phoneNumber"
                  className="w-full h-14 rounded-md"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)} />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center mt-5 px-3 py-1">
              <textarea
                name="message"
                className="w-full h-40 rounded-md"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)} />
            </div>
            <div className="flex flex-row justify-between px-3 mt-5 py-3">
              <div className="flex flex-col border-gray-200 bg-white px-4 py-2 rounded-md justify-center items-center">
                <PrivacyTermsIcon className="justify-center" />
                <p className="text-[8px] text-gray-500">Privacy - Terms</p>
              </div>
              <button className="bg-congress-blue-600 bg-origin-border px-4 md:px-12 py-3 border border-transparent text-base font-medium rounded-md text-white hover:bg-congress-blue-700">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
