import { PATCH } from "../utility/api";

export const SET_LOADING = "SET_LOADING";

export const setLoading = (loading = false) => ({
  type: SET_LOADING,
  payload: loading,
});

export const updateSetup = (data) => (_dispatch) => {
  _dispatch(setLoading(true));
  return PATCH("/api/setup", { ...data }).then((res) => {
    return { redirect: res.data.redirect, profile: res.data.profile_id };
  });
};

export const updateLendingParameter = (data) => (_dispatch) => {
  _dispatch(setLoading(true));
  return PATCH("/api/update_lending_parameter", { ...data }).then((res) => {
    return true;
  });
};
