import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const MapView = () => {
  const position = [32.84463, -96.69722];
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 relative">
        <section className="text-gray-600 body-font relative">
          <div className="absolute inset-0 bg-gray-300 z-0">
            <MapContainer
              center={position}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position}>
                <Popup>
                  <div className="p-1">
                    {"9020 Garland Rd,Dallas, TX 75218"}
                  </div>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="z-10 px-5 py-4 mx-auto flex">
            <div className="lg:w-1/3 md:w-1/2 bg-oray-50 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative shadow-md">
              <h2 className="text-gray-900 text-3xl mb-3 font-semibold title-font">
                Get in Touch
              </h2>
              <div className="relative mb-4 bg-white rounded-xl shadow-md overflow-hidden">
                <div className="md:flex">
                  <div className="p-8">
                    <div className="tracking-wide text-sm text-blue-500 font-semibold flex">
                      <div className="flex h-6 w-6 items-center justify-center rounded bg-blue-900 text-gray-50">
                        <svg
                          className="h-4 w-4 flex"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="currentColor"
                        >
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                        </svg>
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 uppercase">
                          Address:
                        </h3>
                        <p className="text-gray-600 dark:text-slate-400">
                          9020 Garland Rd
                        </p>
                        <p className="text-gray-600 dark:text-slate-400">
                          Dallas, TX 75218
                        </p>
                      </div>
                    </div>
                    <div className="tracking-wide text-sm text-blue-500 font-semibold flex">
                      <div className="flex h-6 w-6 items-center justify-center rounded bg-blue-900 text-gray-50">
                        <svg
                          className="h-4 w-4 flex"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="currentColor"
                        >
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                          <path d="M15 7a2 2 0 0 1 2 2"></path>
                          <path d="M15 3a6 6 0 0 1 6 6"></path>
                        </svg>
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 uppercase">
                          Contact:
                        </h3>
                        <p className="text-gray-600 dark:text-slate-400">
                          <span className="uppercase">Mobile:</span> <br />
                          +1 214-370-4444
                        </p>
                        <p className="text-gray-600 dark:text-slate-400">
                          <span className="uppercase">Email:</span> <br />
                          tony@multifamilyfountainhead.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mb-4">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  className="w-full bg-white rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write your message..."
                  className="w-full bg-white rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button className="text-white bg-congress-blue-600 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded-lg text-lg">
                Send Me
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MapView;
