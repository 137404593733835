import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import withGlobalProviders from "../../../general/withGlobalProvider";

import PageBody from "../../general/PageBody";
import SavedShowSection from "./show/SavedShowSection";
import Skeleton from "react-loading-skeleton";
import { PrinterIcon } from "@heroicons/react/24/outline";
import "react-loading-skeleton/dist/skeleton.css";

function Show({ searchId }) {
  const search = useSelector((state) => state.searches.items[searchId]);
  const [loading, setLoading] = useState(false);

  const navbar = () => {
    return (
      <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {search
              ? '"Search for ' + search.attributes.name + '"'
              : "Search Results"}
          </h1>
        </div>
        <div className="flex justify-start items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
          <p className="text-xl font-bold leading-tight text-gray-900 ">
            Saved Lenders
          </p>
        </div>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  }, []);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
            {loading ? <Skeleton height={60} width={1214} /> : navbar()}
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main>
              {/* Page header */}
              <div className="mt-4">
                <SavedShowSection {...{ searchId }} />
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Show);
