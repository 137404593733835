import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import withGlobalProviders from "../../../general/withGlobalProvider";

import PageBody from "../../general/PageBody";
import SearchSelect from "./searchSelectList";

import EmptyState from "./EmptyState";
import BrokerDetail from "./BrokerDetail";
import { propertyTypeOptions, stateOptions } from "../searches/forms/helpers";

const marketsList = [
  {
    value: "All",
    label: "All",
  },
  ...propertyTypeOptions,
];
const StateList = [{ value: "All", label: "All" }, ...stateOptions];

function Index({ brokers, bankers, organizationId, organizationBranchId }) {
  // const search = useSelector((state) => {
  // });
  const [selectedMarket, setSelectedMarket] = useState("All");
  const [selectedState, setSelectedState] = useState("All");
  const [brokerList, setBrokerList] = useState(brokers);

  useEffect(() => {
    setBrokerList(
      brokers.filter(broker => {
        return (selectedState === "All" || broker.attributes.profileState == selectedState) &&
               (selectedMarket === "All" || broker.attributes.lendingParameters?.propertyTypesFinanced == selectedMarket)
      })
    );
  }, [selectedState, selectedMarket]);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header className="flex flex-col gap-4">
          <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 flex justify-between">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 text-shadow-sm">
              Brokers
            </h1>
          </div>
          <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 grid grid-cols-12 gap-4">
            <div className="col-span-6 lg:col-span-3">
              <SearchSelect
                options={marketsList}
                onSelectionChange={setSelectedMarket}
                label={"market"}
              />
            </div>
            <div className="col-span-6 lg:col-span-3">
              <SearchSelect
                options={StateList}
                onSelectionChange={setSelectedState}
                label={"state"}
              />
            </div>
            <div className="col-span-6 lg:col-span-3">
              {/* <InputField
                name="search"
                color="congress-blue"
                type="string"
                label="Search"
                required={false}
              /> */}
            </div>
          </div>
        </header>
        <main>
          <div className="mt-8 flex flex-col gap-5 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main className="bg-white rounded-lg">
              {/* Page header */}
              <div className="p-8 flex flex-col gap-5 overflow-x-scroll">
                {Object.keys(brokerList).length == 0 && (
                  <EmptyState organizationBranchId={organizationBranchId}/>
                )}
                <div
                  className="flex space-y-6 flex-col [&>*:nth-child(even)]:bg-gray-50"
                  style={{ minWidth: "1000px" }}
                >
                  {brokerList.map((broker, index) => (
                    <div key={index}>
                      <BrokerDetail
                        {...{
                          brokerDetail: broker,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
