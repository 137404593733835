import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "@headlessui/react";
import {
  KeyIcon,
  UserCircleIcon,
  BellAlertIcon,
} from "@heroicons/react/24/outline";
import PageBody from "../PageBody";
import ProfileTab from "./edit/ProfileTab";
import AreasOfFocusTab from "./edit/AreasOfFocusTab";
import PasswordTab from "./edit/PasswordTab";
import NotificationTab from "./edit/NotificationTab";
import SubscriptionTab from "./subscription/SubscriptionTab";
import withGlobalProviders from "../../../general/withGlobalProvider";
import { destroySession } from "../../../../actions/sessionActions";

// const user = {
//   name: "Debbie Lewis",
//   handle: "deblewis",
//   email: "debbielewis@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
// };

// let subNavigation = [
//   { name: "Profile", icon: UserCircleIcon, current: true },
//   // { name: "Account", href: "#", icon: CogIcon, current: false },
//   { name: "Password", icon: KeyIcon, current: false },
//   {
//     name: "Sign out",
//     href: user.attributes.destroyUserSessionPath,
//     current: false,
//     method: () => {
//       dispatch(destroySession())
//         .then(() => {})
//         .catch(() => {
//           window.location.assign(window.location.origin);
//         });
//     },
//   },
// { name: "Notifications", href: "#", icon: BellIcon, current: false },
// { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
// { name: "Integrations", href: "#", icon: ViewGridAddIcon, current: false },
// ];

let subNavigation = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProfileEdit() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.currentUser);
  const [selectedSection, setSelectedSection] = useState("Profile");
  if (user) {
    const { userType, userRole } = user.attributes;
    if (userType === "lender") {
      subNavigation = [
        { name: "Profile", icon: UserCircleIcon, current: true },
        // { name: "Account", href: "#", icon: CogIcon, current: false },
        { name: "Password", icon: KeyIcon, current: false },
        { name: "Subscription", current: false },
        { name: "Notifications", icon: BellAlertIcon, current: false },
        // { name: "Integrations", href: "#", icon: ViewGridAddIcon, current: false },
      ];
    }
    if (userType === "borrower") {
      subNavigation = [
        { name: "Profile", icon: UserCircleIcon, current: true },
        // { name: "Account", href: "#", icon: CogIcon, current: false },
        { name: "Password", icon: KeyIcon, current: false },
        { name: "Subscription", current: false },
        {
          name: "Sign out",
          href: user.attributes.destroyUserSessionPath,
          current: false,
          method: () => {
            dispatch(destroySession())
              .then(() => {})
              .catch(() => {
                window.location.assign(window.location.origin);
              });
          },
        }
        // { name: "Notifications", href: "#", icon: BellIcon, current: false },
        // { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
        // { name: "Integrations", href: "#", icon: ViewGridAddIcon, current: false },
      ];

      if(userRole === 'broker') {
        subNavigation.splice(1, 0, {name: 'Areas of Focus', current: false});
      }
    }
  }
  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Profile Settings
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
            <div className="bg-white rounded-lg shadow overflow-visible">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="pb-14 p-6 lg:pb-6  lg:col-span-3 relative">
                  <nav className="space-y-1">
                    {subNavigation.map((item) => (
                      <span
                        key={item.name}
                        onClick={
                          item.method
                            ? item.method
                            : () => setSelectedSection(item.name)
                        }
                        className={classNames(
                          "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 flex items-center text-sm font-medium cursor-pointer"
                        )}
                        aria-current={
                          selectedSection === item.name ? "page" : undefined
                        }
                      >
                        {/* <item.icon
                          className={classNames(
                            selectedSection === item.name
                              ? "text-teal-500 group-hover:text-teal-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        /> */}
                        <span
                          aria-hidden="true"
                          className={`truncate ${
                            selectedSection === item.name
                              ? "text-black"
                              : "text-gray-400"
                          }`}
                        >
                          {item.name}
                        </span>
                      </span>
                    ))}
                  </nav>
                  <p className="text-sm absolute bottom-6">
                    You are a{" "}
                    <span className="text-sm rounded bg-yellow-500 p-1">
                      {`${user.attributes.userRole}`.toUpperCase()}
                    </span>
                  </p>
                </aside>
                {selectedSection === "Profile" && <ProfileTab />}
                {selectedSection === "Areas of Focus" && <AreasOfFocusTab />}
                {selectedSection === "Password" && <PasswordTab />}
                {selectedSection === "Notifications" && <NotificationTab />}
                {selectedSection === "Subscription" && <SubscriptionTab />}
              </div>
            </div>
          </div>
        </main>
      </div>
    </PageBody>
  );
}
export default withGlobalProviders(ProfileEdit);
