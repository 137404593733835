import React, { Fragment, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";
import join from "lodash/join";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { destroySearch } from "../../../../../actions/searchActions";
import { lenderLendingParameter } from "../../../../../actions/admin/lendingParamaterActions";

import withGlobalProviders from "../../../../general/withGlobalProvider";

const handleDelete = () => dispatch(destroySearch(id));

function LenderProfileListItem(unapprovedLender) {
  if (!unapprovedLender.unapprovedLender) return null;

  const dispatch = useDispatch();

  const [profile, setProfile] = useState({ attributes: {} });
  const [isLoading, setIsLoading] = useState(true);

  const {
    id,
    email,
    created_at,
    updated_at,
    is_active,
  } = unapprovedLender.unapprovedLender;

  const {
    firstName,
    lastName,
    lendingStates,
    companyName,
    minimumLoanAmountCents,
    maximumLoanAmountCents,
    propertyTypesFinanced,
    typeOfInstitution,
    typeOfFinancing,
    directLenderMortgageBroker,
    retailWholesale,
    recourse,
  } = profile.attributes;

  useEffect(() => {
    dispatch(lenderLendingParameter(id)).then((res) => {
      if (res.payload.data) {
        setProfile(res.payload.data);
      }
    });
  }, [1000]);

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      <div>
        <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg">
          <div className="px-4 py-3 sm:px-6 flex justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {firstName + lastName || email}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Created: {moment(created_at).format("MMMM Do [at] h:mm A ")} |
                Last Updated:{" "}
                {moment(updated_at).format("MMMM Do [at] h:mm A ")}
              </p>
            </div>
            <div className="flex items-center">
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <a
                  href={"/approve-lender/" + id}
                  className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-congress-blue-500 focus:z-10 focus:outline-none focus:ring-1 focus:ring-congress-blue-500 focus:border-congress-blue-500"
                >
                  Approve
                </a>
                <a
                  href={"/decline-lender/" + id}
                  className="relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-congress-blue-500 focus:z-10 focus:outline-none focus:ring-1 focus:ring-congress-blue-500 focus:border-congress-blue-500"
                >
                  Decline
                </a>
              </span>
            </div>
          </div>
          {/* <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Company Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{companyName}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Minimum Loan Amount Requested
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {"$ " + (minimumLoanAmountCents || 0)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Maximum Loan Amount Requested
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {"$ " + (maximumLoanAmountCents || 0)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Property Type Financed
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {propertyTypesFinanced}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Lending State
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{lendingStates}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Type of Institution
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {typeOfInstitution}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Type of Financing Provided
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {typeOfFinancing}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Direct Lender / Mortgage Broker
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {directLenderMortgageBroker}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Profile Approved
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {is_active ? "Yes" : "No"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Recourse</dt>
                <dd className="mt-1 text-sm text-gray-900">{recourse}</dd>
              </div>
            </dl>
          </div> */}
        </div>
      </div>
    </Transition>
  );
}

export default withGlobalProviders(LenderProfileListItem);
