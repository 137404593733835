import L from "leaflet";

export const greenIcon = new L.Icon({
  iconUrl:
    'https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/icon-blue-map-pos.png',
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [28, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const blueIcon = new L.Icon({
  iconUrl:
    "https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/icon-blue-map-pos.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [28, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});