import { SET_LOADING } from "../actions/setupActions";

const defaultState = {
  loading: false,
};

const setupReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_LOADING:
      return { loading: action.payload };
    default:
      return state;
  }
};

export default setupReducer;
