import { RECEIVE_BROKERS } from "../actions/brokerActions";

const defaultState = {
  brokers: [],
};

const brokerReducer = (state = defaultState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_BROKERS:
      return { brokers: action.payload };
    default:
      return state;
  }
};

export default brokerReducer;
