import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Popover from "@material-ui/core/Popover";
import { Transition } from "@headlessui/react";

import {
  BuildingOffice2Icon,
  MinusIcon as MinusIconSolid,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";
import withGlobalProviders from "../../../../general/withGlobalProvider";
import includes from "lodash/includes";
import DropdownItem from "./DropdownItem";
function SavedSelectedListItem({
  id,
  userSavedOrganizations,
  updateSelectedOrganizations,
}) {
  const item = useSelector(
    (state) => state.organizations.items[id],
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = useState(null);

  if (!item) return null;

  const userSaved = includes(userSavedOrganizations, id);

  const handlePopoverOpen = (event) => {
    if (userSaved) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      <div key={item.id}>
        <div className="block sm:rounded-lg my-4 bg-white">
          <div className="px-0 py-4 grid grid-cols-12 border-b">
            <div className="flex justify-center items-center col-span-1">
              <MarkIcon />
            </div>
            <div className="flex flex-col items-start justify-center col-span-4">
              <p className="text-md font-medium text-gray-600 truncate">
                Lender
              </p>
              <p className="text-md text-black truncate">
                {item.attributes.name}
              </p>
            </div>
            <div className="flex flex-col items-start justify-center col-span-6">
              <p className="text-md font-medium text-gray-600 truncate">
                Branches
              </p>
              <p className="text-md text-black truncate">
                {item.relationships.organizationBranches.data.length}
              </p>
            </div>
            <div className="flex justify-center items-center text-sm text-gray-500 col-span-1">
              <button
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                type="button"
                className={
                  userSaved
                    ? "ml-3 inline-flex items-center p-1 text-gray bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    : "ml-3 inline-flex items-center p-1 text-red bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 cursor-pointer"
                }
                onClick={
                  userSaved
                    ? (e) => {
                        e.stopPropagation();
                      }
                    : () => updateSelectedOrganizations(id, true)
                }
              >
                <TrashIcon
                  className={
                    userSaved
                      ? "h-5 w-5 text-gray-700"
                      : "h-5 w-5 text-gray-700"
                  }
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {item?.relationships?.organizationBranches?.data.map((el) => (
              <div key={el.id}>
                <a
                  // href={
                  //   "/bankers?organization_id=" +
                  //   item.id +
                  //   "&organization_branch_id=" +
                  //   el.id
                  // }
                  className="block"
                >
                  <DropdownItem
                    {...{
                      id: el.id,
                      branche: el,
                      bankers: item.attributes.bankers.filter(
                        (i) => i.organizationBranchId === el.id
                      ),
                    }}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default withGlobalProviders(SavedSelectedListItem);
