import React from 'react';
import { useSelector } from 'react-redux';

import withGlobalProviders from '../../../general/withGlobalProvider';
import PageBody from '../PageBody';
import { availableLinks } from './helpers';

function Confirmation() {
  const { subscribeType } = useSelector((state) => state.session.currentUser.attributes);
  const links = availableLinks[subscribeType];

  return (
    <PageBody>
      <div className="grid justify-items-center">
        <div className="py-20 bg-gray-100 my-12 mx-8 sm:mx-20 rounded-md px-4 sm:px-8 lg:px-16 lg:w-2/3 text-wrap text-2xl">
          <p>
            Thank you for subscribing.
            { links.length != 0 && <span> Follow this link to join:</span> }
          </p>
          { links.map((link) => (<a href={ link.link } key={ link.title } className="block mx-12 text-xl text-congress-blue-600 underline mt-3">{ link.title }</a>)) }
          { !subscribeType.includes('premium_placement') && <p className="mt-3">You will receive access as soon as the admin reviews the request.</p> }
          <p className="mt-10 text-xl">
            Contact
            <a href="mailto:support@lenderprism.zohodesk.com?subject=LenderPrism&body=" className="text-congress-blue-600 underline"> support@lenderprism.zohodesk.com </a>
            with any questions/ comments/ issues.
          </p>
        </div>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Confirmation);
