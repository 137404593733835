import React, { Fragment, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";
import { Menu, Transition } from "@headlessui/react";

import { destroySavedLender } from "../../../../../actions/userSavedLendersActions";
import { destroySearch , destroySavedSearch} from "../../../../../actions/searchActions";
import withGlobalProviders from "../../../../general/withGlobalProvider";
import { createHandleSubmit } from "../../../../../utility/helpers";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import EyeIcon from "../../../../../assets/svgs/EyeIcon";
import EditIcon from "../../../../../assets/svgs/EditIcon";
import DelIcon from "../../../../../assets/svgs/DelIcon";

function ProspectListItem({ prospector, onRemoveSavedProspector }) {
  const dispatch = useDispatch();
  const [loanRequest, setLoanRequest] = useState(prospector);

  useEffect(() => {
    setLoanRequest(prospector);
  }, [prospector]);

  const deleteSubmit = createHandleSubmit({
    handleSubmit: () => {
      return dispatch(destroySavedSearch(prospector.id));
    },
    handleSuccess: () => {
      // return dispatch(fetchUserSavedOrganizations(searchId))
      onRemoveSavedProspector(prospector.id); // Call the function passed as a prop
      window.location.reload()
    },
    handleErrors: () => {},
  });

  const {
    name,
    createdAt,
    updatedAt,
    loanAmountRequestedCents,
    propertyTypeFinanced,
    locationState,
    postalCode,
    locationCity,
    locationRange,
    typeOfInstitution,
    directLenderMortgageBroker,
    retailWholesale,
    typeOfFinancing,
    recourse,
    foreignNationalsConsidered,
  } = loanRequest.attributes;

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      <div>
        <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg">
          <div className="px-4 py-3 sm:px-6 flex justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.5 8.75C12.5 9.41304 12.2366 10.0489 11.7678 10.5178C11.2989 10.9866 10.663 11.25 10 11.25C9.33696 11.25 8.70107 10.9866 8.23223 10.5178C7.76339 10.0489 7.5 9.41304 7.5 8.75C7.5 8.08696 7.76339 7.45107 8.23223 6.98223C8.70107 6.51339 9.33696 6.25 10 6.25C10.663 6.25 11.2989 6.51339 11.7678 6.98223C12.2366 7.45107 12.5 8.08696 12.5 8.75Z"
                    stroke="#059669"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.9887 10.8333C16.1551 10.1784 16.25 9.48339 16.25 8.75C16.25 7.0924 15.5915 5.50268 14.4194 4.33058C13.2473 3.15848 11.6576 2.5 10 2.5C8.3424 2.5 6.75268 3.15848 5.58058 4.33058C4.40848 5.50268 3.75 7.0924 3.75 8.75C3.75 14.7017 10 18.125 10 18.125C10 18.125 10.3278 17.9454 10.8333 17.5971"
                    stroke="#059669"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4012 16.2342L16.667 17.5M15.4012 16.2342C15.6043 16.031 15.7655 15.79 15.8755 15.5246C15.9854 15.2592 16.042 14.9748 16.042 14.6875C16.042 14.1073 15.8115 13.5509 15.4013 13.1407C14.9911 12.7305 14.4347 12.5 13.8545 12.5C13.2743 12.5 12.7179 12.7305 12.3077 13.1407C11.8975 13.5509 11.667 14.1073 11.667 14.6875C11.667 14.9748 11.7236 15.2592 11.8335 15.5246C11.9434 15.79 12.1046 16.0312 12.3077 16.2343C12.5108 16.4374 12.752 16.5986 13.0174 16.7085C13.2828 16.8184 13.5672 16.875 13.8545 16.875C14.1418 16.875 14.4262 16.8184 14.6916 16.7085C14.957 16.5986 15.198 16.4373 15.4012 16.2342Z"
                    stroke="#059669"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {name || "Unnamed Search"}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500 ml-6">
                <span className="font-bold">Created:</span>{" "}
                {moment(createdAt).format("MMMM Do [at] h:mm A ")} |
                <span className="font-bold"> Last Updated:</span>{" "}
                {moment(updatedAt).format("MMMM Do [at] h:mm A ")}
              </p>
            </div>
            <div className="flex items-center">
              <span className="relative z-0 inline-flex">
                <a
                  href={"/lenders/search/saved/" + loanRequest.id}
                  className="relative inline-flex items-center gap-1 px-4 py-2 bg-white text-sm text-gray-700 font-bold hover:bg-gray-50 hover:border-congress-blue-500"
                >
                  <EyeIcon />
                  View saved lenders
                </a>
              </span>
              <span className="relative z-0 inline-flex cursor-pointer">
                <a
                  onClick={deleteSubmit}
                  className="relative inline-flex items-center gap-1 px-4 py-2 bg-white text-sm text-customYellow font-bold hover:bg-gray-50"
                >
                  <DelIcon color="text-red-600" />
                  Delete
                </a>
              </span>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Loan Amount Requested
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $
                  {(loanAmountRequestedCents / 100.0)
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Property Type Financed
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {propertyTypeFinanced}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">City</dt>
                <dd className="mt-1 text-sm text-gray-900">{locationCity}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">State</dt>
                <dd className="mt-1 text-sm text-gray-900">{locationState}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Postal Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{postalCode}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Country</dt>
                <dd className="mt-1 text-sm text-gray-900">-</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Range / Distance
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {locationRange} miles
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default withGlobalProviders(ProspectListItem);
