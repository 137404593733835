import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import toast  from "react-hot-toast";
import map from "lodash/map";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import { createHandleSubmit } from "../../../../../utility/helpers";
import { initialValues as baseValues, validationSchema } from "./areasOfFocusHelper";
import Select from "../../../../forms/SelectField";
import InputField from "../../../../forms/InputField";
import { updateLendingParameter } from "../../../../../actions/setupActions";
import {
  directLenderMortgageBrokerOptions,
  propertyTypeOptions,
  stateOptions,
  typeOfInstitutionOptions
} from "../../../lender/setup/forms/helpers";

function toSelectOption(item) {
  if (!item) return null;
  return { value: item, label: item };
}

function toSelectOptions(items) {
  return map(items, toSelectOption);
}

function AreasOfFocusTab() {
  const { lendingParameters }  = useSelector((state) => state.session.currentUser.attributes);
  const dispatch = useDispatch();

  let initialValues;
  if(lendingParameters) {
    initialValues = lendingParameters;

    initialValues.typeOfInstitution = toSelectOptions(initialValues.typeOfInstitution);
    initialValues.directLenderMortgageBroker = toSelectOption(initialValues.directLenderMortgageBroker);
    initialValues.lendingStates = toSelectOptions(initialValues.lendingStates)
    initialValues.propertyTypesFinanced = toSelectOptions(initialValues.propertyTypesFinanced);
  } else {
    initialValues = baseValues;
  }

  const onSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      return dispatch(updateLendingParameter(values));
    },
    handleSuccess: () => {
      toast.success("Lending parameters updated");
    },
    handleErrors: () => {
      toast.error('Unexpected error occurred');
    },
  });

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        <Form>
          <div className="py-6 px-4 sm:p-6 lg:pb-8">
            <div>
              <h2 className="text-lg leading-6 font-bold text-gray-900">
                Areas of Focus
              </h2>
            </div>

            <div className="mt-6 flex flex-col lg:flex-row">
              <div className="flex-grow grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                  <Select
                    name="typeOfInstitution"
                    options={typeOfInstitutionOptions}
                    isMulti={true}
                    label={"Type of Institution"}
                    required={true}
                    placeholder={<div className=" text-gray-500 text-base">Type of Institution<span
                      className=" text-red-400 ml-1">*</span></div>}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Select
                    name="directLenderMortgageBroker"
                    options={directLenderMortgageBrokerOptions}
                    label={"Direct Lender / Mortgage Broker"}
                    isClearable={true}
                    required={true}
                    placeholder={<div className=" text-gray-500 text-base">Direct Lender / Mortgage Broker<span
                      className=" text-red-400 ml-1">*</span></div>}
                  />
                </div>
                <div className="col-span-12">
                  <Select
                    name="lendingStates"
                    options={stateOptions}
                    label={"States In Which You Operate"}
                    isClearable={true}
                    required={true}
                    isMulti={true}
                    placeholder={<div className=" text-gray-500 text-base">States In Which You Operate<span
                      className=" text-red-400 ml-1">*</span></div>}
                  />
                </div>
                <div className="col-span-12">
                  <Select
                    name="propertyTypesFinanced"
                    options={propertyTypeOptions}
                    isMulti={true}
                    label={"Property Type Financed"}
                    required={true}
                    placeholder={<div className=" text-gray-500 text-base">Property Type Financed<span
                      className=" text-red-400 ml-1">*</span></div>}
                  />
                </div>
                <div className="col-span-12">
                  <InputField
                    name="elevatorPitch"
                    type="textarea"
                    label="Elevator Pitch"
                    color="congress-blue"
                  />
                </div>
                <div className="col-span-12">
                  <InputField
                    name="comments"
                    type="textarea"
                    label="Comments"
                    color="congress-blue"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
            <button
              type="submit"
              className="ml-5 bg-congress-blue-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-congress-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default withGlobalProviders(AreasOfFocusTab);
