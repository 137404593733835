import * as yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  officePhone: "",
  personalPhone: "",
  companyName: "",
  title: "",
  profileState: "",
  city: "",
  typeOfInstitution: "",
  directLenderMortgageBroker: "",
  minimumLoanAmount: "",
  maximumLoanAmount: "",
  recourse: "",
  lendingStates: "",
  propertyTypesFinanced: "",
  typeOfFinancing: "",
  elevatorPitch: "",
  comments: "",
};

export const updateValidationSchema = yup.object().shape({
  typeOfInstitution: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  directLenderMortgageBroker: yup
    .object()
    .nullable()
    .required("Can't be blank."),
  minimumLoanAmount: yup
    .number()
    .integer()
    .min(1000)
    .nullable()
    .lessThan(
      yup.ref("maximumLoanAmount"),
      "Must be less than the Maximum Loan Amount."
    )
    .required("Can't be blank."),
  maximumLoanAmount: yup
    .number()
    .integer()
    .min(1000)
    .nullable()
    .moreThan(
      yup.ref("minimumLoanAmount"),
      "Must be more than the Minimum Loan Amount."
    )
    .required("Can't be blank."),
  recourse: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  lendingStates: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  propertyTypesFinanced: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  typeOfFinancing: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
});

export const validationSchema = yup.object().shape({
  firstName: yup.string().trim().nullable().required("Can't be blank."),
  lastName: yup.string().trim().nullable().required("Can't be blank."),
  officePhone: yup.string().trim().nullable(),
  personalPhone: yup.string().trim().nullable(),
  companyName: yup.string().trim().nullable().required("Can't be blank."),
  title: yup.string().trim().nullable(),
  profileState: yup.object().nullable().required("Can't be blank."),
  city: yup.string().trim().nullable(),
  typeOfInstitution: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  directLenderMortgageBroker: yup
    .object()
    .nullable()
    .required("Can't be blank."),
  minimumLoanAmount: yup
    .number()
    .integer()
    .min(1000)
    .nullable()
    .lessThan(
      yup.ref("maximumLoanAmount"),
      "Must be less than the Maximum Loan Amount."
    )
    .required("Can't be blank."),
  maximumLoanAmount: yup
    .number()
    .integer()
    .min(1000)
    .nullable()
    .moreThan(
      yup.ref("minimumLoanAmount"),
      "Must be more than the Minimum Loan Amount."
    )
    .required("Can't be blank."),
  recourse: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  lendingStates: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  propertyTypesFinanced: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  typeOfFinancing: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
});

export const propertyTypeOptions = [
  {
    value: "Assisted Living / Memory Care",
    label: "Assisted Living / Memory Care",
  },
  { value: "Hotel/Motel", label: "Hotel/Motel" },
  { value: "Industrial", label: "Industrial" },
  { value: "Land", label: "Land" },
  {
    value: "Manufactured Housing / Mobile Home Park",
    label: "Manufactured Housing / Mobile Home Park",
  },
  { value: "Marina", label: "Marina" },
  { value: "Medical Office", label: "Medical Office" },
  { value: "Mixed-Use", label: "Mixed-Use" },
  { value: "Multifamily", label: "Multifamily" },
  { value: "Nursing Facility", label: "Nursing Facility" },
  { value: "Office-Multi Tenant", label: "Office-Multi Tenant" },
  { value: "Office-Single Tenant", label: "Office-Single Tenant" },
  { value: "Office-Owner/User", label: "Office-Owner/User" },
  { value: "Other", label: "Other" },
  { value: "Retail-Multi Tenant", label: "Retail-Multi Tenant" },
  { value: "Retail-Single Tenant", label: "Retail-Single Tenant" },
  { value: "RV Park", label: "RV Park" },
  { value: "Self-Storage", label: "Self-Storage" },
  { value: "Senior Housing", label: "Senior Housing" },
  {
    value: "Single-Family Rentals (1-4 Units)",
    label: "Single-Family Rentals (1-4 Units)",
  },
  { value: "Student Housing", label: "Student Housing" },
];

export const stateOptions = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District of Columbia", label: "District of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

export const locationRangeOptions = [
  { value: 10, label: "10 Miles" },
  { value: 25, label: "25 Miles" },
  { value: 50, label: "50 Miles" },
  { value: 75, label: "75 Miles" },
  { value: 100, label: "100 Miles" },
];

export const typeOfInstitutionOptions = [
  { value: "Bank", label: "Bank" },
  { value: "Agency (Fannie Mae)", label: "Agency (Fannie Mae)" },
  { value: "Agency (Freddie Mac)", label: "Agency (Freddie Mac)" },
  { value: "CMBS/Conduit Lender", label: "CMBS/Conduit Lender" },
  { value: "Credit Union", label: "Credit Union" },
  { value: "FHA/HUD", label: "FHA/HUD" },
  { value: "Life Insurance Company", label: "Life Insurance Company" },
  { value: "Mortgage Bank", label: "Mortgage Bank" },
  { value: "Mortgage Broker", label: "Mortgage Broker" },
  { value: "Non-Bank / Investment Fund", label: "Non-Bank / Investment Fund" },
  { value: "Private Lender", label: "Private Lender" },
];

export const directLenderMortgageBrokerOptions = [
  {
    value: "We are direct lenders using our own funds",
    label: "We are direct lenders using our own funds",
  },
  {
    value: "We are mortgage brokers placing loans for borrowers",
    label: "We are mortgage brokers placing loans for borrowers",
  },
  {
    value: "We are both a direct lender and mortgage broker",
    label: "We are both a direct lender and mortgage broker",
  },
];

export const retailWholesaleOptions = [
  {
    value: "We work with both direct borrowers and mortgage brokers",
    label: "We work with both direct borrowers and mortgage brokers",
  },
  {
    value: "We work only with direct borrowers",
    label: "We work only with direct borrowers",
  },
  {
    value: "We work only with mortgage brokers / correspondent lenders",
    label: "We work only with mortgage brokers / correspondent lenders",
  },
];

export const typeOfFinancingOptions = [
  { value: "Fixed-Rate Loan (> 3 years)", label: "Fixed-Rate Loan (> 3 years)" },
  { value: "Bridge Loan (< 3 years)", label: "Bridge Loan (< 3 years)" },
  { value: "Construction Loan", label: "Construction Loan" },
  { value: "Equity and/or Mezz", label: "Equity and/or Mezz" },
  // { value: "Agency (Fannie/Freddie)", label: "Agency (Fannie/Freddie)" },
  // { value: "Balance Sheet Lender", label: "Balance Sheet Lender" },
  // { value: "Bridge / Short-Term Debt", label: "Bridge / Short-Term Debt" },
  // { value: "CMBS", label: "CMBS" },
  // { value: "Construction", label: "Construction" },
  // { value: "Hard Money", label: "Hard Money" },
  // { value: "Equity", label: "Equity" },
  // { value: "Land / Lot Development", label: "Land / Lot Development" },
  // { value: "Mezz/2nd Lien", label: "Mezz/2nd Lien" },
  // { value: "Note Purchase Financing", label: "Note Purchase Financing" },
  // { value: "Permanent / Long-Term Debt", label: "Permanent / Long-Term Debt" },
];

export const recourseOptions = [
  { value: "Full Recourse", label: "Full Recourse" },
  { value: "Partial Recourse", label: "Partial Recourse" },
  { value: "Non-Recourse", label: "Non-Recourse" },
];

export const foreignNationalsConsideredOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const counties = {
  Alabama: [
    { value: "Autauga", label: "Autauga" },
    { value: "Baldwin", label: "Baldwin" },
    { value: "Barbour", label: "Barbour" },
    { value: "Bibb", label: "Bibb" },
    { value: "Blount", label: "Blount" },
    { value: "Bullock", label: "Bullock" },
    { value: "Butler", label: "Butler" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Chambers", label: "Chambers" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Chilton", label: "Chilton" },
    { value: "Choctaw", label: "Choctaw" },
    { value: "Clarke", label: "Clarke" },
    { value: "Clay", label: "Clay" },
    { value: "Cleburne", label: "Cleburne" },
    { value: "Coffee", label: "Coffee" },
    { value: "Colbert", label: "Colbert" },
    { value: "Conecuh", label: "Conecuh" },
    { value: "Coosa", label: "Coosa" },
    { value: "Covington", label: "Covington" },
    { value: "Crenshaw", label: "Crenshaw" },
    { value: "Cullman", label: "Cullman" },
    { value: "Dale", label: "Dale" },
    { value: "Dallas", label: "Dallas" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Elmore", label: "Elmore" },
    { value: "Escambia", label: "Escambia" },
    { value: "Etowah", label: "Etowah" },
    { value: "Fayette", label: "Fayette" },
    { value: "Franklin", label: "Franklin" },
    { value: "Geneva", label: "Geneva" },
    { value: "Greene", label: "Greene" },
    { value: "Hale", label: "Hale" },
    { value: "Henry", label: "Henry" },
    { value: "Houston", label: "Houston" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Lamar", label: "Lamar" },
    { value: "Lauderdale", label: "Lauderdale" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lee", label: "Lee" },
    { value: "Limestone", label: "Limestone" },
    { value: "Lowndes", label: "Lowndes" },
    { value: "Macon", label: "Macon" },
    { value: "Madison", label: "Madison" },
    { value: "Marengo", label: "Marengo" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Mobile", label: "Mobile" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Perry", label: "Perry" },
    { value: "Pickens", label: "Pickens" },
    { value: "Pike", label: "Pike" },
    { value: "Randolph", label: "Randolph" },
    { value: "Russell", label: "Russell" },
    { value: "Shelby", label: "Shelby" },
    { value: "St. Clair", label: "St. Clair" },
    { value: "Sumter", label: "Sumter" },
    { value: "Talladega", label: "Talladega" },
    { value: "Tallapoosa", label: "Tallapoosa" },
    { value: "Tuscaloosa", label: "Tuscaloosa" },
    { value: "Walker", label: "Walker" },
    { value: "Washington", label: "Washington" },
    { value: "Wilcox", label: "Wilcox" },
    { value: "Winston", label: "Winston" },
  ],
  Alaska: [
    { value: "Aleutians East", label: "Aleutians East" },
    { value: "Aleutians West", label: "Aleutians West" },
    { value: "Anchorage", label: "Anchorage" },
    { value: "Bethel", label: "Bethel" },
    { value: "Bristol Bay", label: "Bristol Bay" },
    { value: "Denali", label: "Denali" },
    { value: "Dillingham", label: "Dillingham" },
    { value: "Fairbanks North Star", label: "Fairbanks North Star" },
    { value: "Haines", label: "Haines" },
    { value: "Hoonah-Angoon", label: "Hoonah-Angoon" },
    { value: "Juneau", label: "Juneau" },
    { value: "Kenai Peninsula", label: "Kenai Peninsula" },
    { value: "Ketchikan Gateway", label: "Ketchikan Gateway" },
    { value: "Kodiak Island", label: "Kodiak Island" },
    { value: "Kusilvak", label: "Kusilvak" },
    { value: "Lake and Peninsula", label: "Lake and Peninsula" },
    { value: "Matanuska-Susitna", label: "Matanuska-Susitna" },
    { value: "Nome", label: "Nome" },
    { value: "North Slope", label: "North Slope" },
    { value: "Northwest Arctic", label: "Northwest Arctic" },
    { value: "Petersburg", label: "Petersburg" },
    { value: "Prince of Wales-Hyder", label: "Prince of Wales-Hyder" },
    { value: "Sitka", label: "Sitka" },
    { value: "Skagway", label: "Skagway" },
    { value: "Southeast Fairbanks", label: "Southeast Fairbanks" },
    { value: "Valdez-Cordova", label: "Valdez-Cordova" },
    { value: "Wrangell", label: "Wrangell" },
    { value: "Yakutat", label: "Yakutat" },
    { value: "Yukon-Koyukuk", label: "Yukon-Koyukuk" },
  ],
  Arizona: [
    { value: "Apache", label: "Apache" },
    { value: "Cochise", label: "Cochise" },
    { value: "Coconino", label: "Coconino" },
    { value: "Gila", label: "Gila" },
    { value: "Graham", label: "Graham" },
    { value: "Greenlee", label: "Greenlee" },
    { value: "La Paz", label: "La Paz" },
    { value: "Maricopa", label: "Maricopa" },
    { value: "Mohave", label: "Mohave" },
    { value: "Navajo", label: "Navajo" },
    { value: "Pima", label: "Pima" },
    { value: "Pinal", label: "Pinal" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Yavapai", label: "Yavapai" },
    { value: "Yuma", label: "Yuma" },
  ],
  Arkansas: [
    { value: "Arkansas", label: "Arkansas" },
    { value: "Ashley", label: "Ashley" },
    { value: "Baxter", label: "Baxter" },
    { value: "Benton", label: "Benton" },
    { value: "Boone", label: "Boone" },
    { value: "Bradley", label: "Bradley" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Carroll", label: "Carroll" },
    { value: "Chicot", label: "Chicot" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Cleburne", label: "Cleburne" },
    { value: "Cleveland", label: "Cleveland" },
    { value: "Columbia", label: "Columbia" },
    { value: "Conway", label: "Conway" },
    { value: "Craighead", label: "Craighead" },
    { value: "Crawford", label: "Crawford" },
    { value: "Crittenden", label: "Crittenden" },
    { value: "Cross", label: "Cross" },
    { value: "Dallas", label: "Dallas" },
    { value: "Desha", label: "Desha" },
    { value: "Drew", label: "Drew" },
    { value: "Faulkner", label: "Faulkner" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Garland", label: "Garland" },
    { value: "Grant", label: "Grant" },
    { value: "Greene", label: "Greene" },
    { value: "Hempstead", label: "Hempstead" },
    { value: "Hot Spring", label: "Hot Spring" },
    { value: "Howard", label: "Howard" },
    { value: "Independence", label: "Independence" },
    { value: "Izard", label: "Izard" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnson", label: "Johnson" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lee", label: "Lee" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Little River", label: "Little River" },
    { value: "Logan", label: "Logan" },
    { value: "Lonoke", label: "Lonoke" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Miller", label: "Miller" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Nevada", label: "Nevada" },
    { value: "Newton", label: "Newton" },
    { value: "Ouachita", label: "Ouachita" },
    { value: "Perry", label: "Perry" },
    { value: "Phillips", label: "Phillips" },
    { value: "Pike", label: "Pike" },
    { value: "Poinsett", label: "Poinsett" },
    { value: "Polk", label: "Polk" },
    { value: "Pope", label: "Pope" },
    { value: "Prairie", label: "Prairie" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Randolph", label: "Randolph" },
    { value: "Saline", label: "Saline" },
    { value: "Scott", label: "Scott" },
    { value: "Searcy", label: "Searcy" },
    { value: "Sebastian", label: "Sebastian" },
    { value: "Sevier", label: "Sevier" },
    { value: "Sharp", label: "Sharp" },
    { value: "St. Francis", label: "St. Francis" },
    { value: "Stone", label: "Stone" },
    { value: "Union", label: "Union" },
    { value: "Van Buren", label: "Van Buren" },
    { value: "Washington", label: "Washington" },
    { value: "White", label: "White" },
    { value: "Woodruff", label: "Woodruff" },
    { value: "Yell", label: "Yell" },
  ],
  California: [
    { value: "Alameda", label: "Alameda" },
    { value: "Alpine", label: "Alpine" },
    { value: "Amador", label: "Amador" },
    { value: "Butte", label: "Butte" },
    { value: "Calaveras", label: "Calaveras" },
    { value: "Colusa", label: "Colusa" },
    { value: "Contra Costa", label: "Contra Costa" },
    { value: "Del Norte", label: "Del Norte" },
    { value: "El Dorado", label: "El Dorado" },
    { value: "Fresno", label: "Fresno" },
    { value: "Glenn", label: "Glenn" },
    { value: "Humboldt", label: "Humboldt" },
    { value: "Imperial", label: "Imperial" },
    { value: "Inyo", label: "Inyo" },
    { value: "Kern", label: "Kern" },
    { value: "Kings", label: "Kings" },
    { value: "Lake", label: "Lake" },
    { value: "Lassen", label: "Lassen" },
    { value: "Los Angeles", label: "Los Angeles" },
    { value: "Madera", label: "Madera" },
    { value: "Marin", label: "Marin" },
    { value: "Mariposa", label: "Mariposa" },
    { value: "Mendocino", label: "Mendocino" },
    { value: "Merced", label: "Merced" },
    { value: "Modoc", label: "Modoc" },
    { value: "Mono", label: "Mono" },
    { value: "Monterey", label: "Monterey" },
    { value: "Napa", label: "Napa" },
    { value: "Nevada", label: "Nevada" },
    { value: "Orange", label: "Orange" },
    { value: "Placer", label: "Placer" },
    { value: "Plumas", label: "Plumas" },
    { value: "Riverside", label: "Riverside" },
    { value: "Sacramento", label: "Sacramento" },
    { value: "San Benito", label: "San Benito" },
    { value: "San Bernardino", label: "San Bernardino" },
    { value: "San Diego", label: "San Diego" },
    { value: "San Francisco", label: "San Francisco" },
    { value: "San Joaquin", label: "San Joaquin" },
    { value: "San Luis Obispo", label: "San Luis Obispo" },
    { value: "San Mateo", label: "San Mateo" },
    { value: "Santa Barbara", label: "Santa Barbara" },
    { value: "Santa Clara", label: "Santa Clara" },
    { value: "Santa Cruz", label: "Santa Cruz" },
    { value: "Shasta", label: "Shasta" },
    { value: "Sierra", label: "Sierra" },
    { value: "Siskiyou", label: "Siskiyou" },
    { value: "Solano", label: "Solano" },
    { value: "Sonoma", label: "Sonoma" },
    { value: "Stanislaus", label: "Stanislaus" },
    { value: "Sutter", label: "Sutter" },
    { value: "Tehama", label: "Tehama" },
    { value: "Trinity", label: "Trinity" },
    { value: "Tulare", label: "Tulare" },
    { value: "Tuolumne", label: "Tuolumne" },
    { value: "Ventura", label: "Ventura" },
    { value: "Yolo", label: "Yolo" },
    { value: "Yuba", label: "Yuba" },
  ],
  Colorado: [
    { value: "Adams", label: "Adams" },
    { value: "Alamosa", label: "Alamosa" },
    { value: "Arapahoe", label: "Arapahoe" },
    { value: "Archuleta", label: "Archuleta" },
    { value: "Baca", label: "Baca" },
    { value: "Bent", label: "Bent" },
    { value: "Boulder", label: "Boulder" },
    { value: "Broomfield", label: "Broomfield" },
    { value: "Chaffee", label: "Chaffee" },
    { value: "Cheyenne", label: "Cheyenne" },
    { value: "Clear Creek", label: "Clear Creek" },
    { value: "Conejos", label: "Conejos" },
    { value: "Costilla", label: "Costilla" },
    { value: "Crowley", label: "Crowley" },
    { value: "Custer", label: "Custer" },
    { value: "Delta", label: "Delta" },
    { value: "Denver", label: "Denver" },
    { value: "Dolores", label: "Dolores" },
    { value: "Douglas", label: "Douglas" },
    { value: "Eagle", label: "Eagle" },
    { value: "El Paso", label: "El Paso" },
    { value: "Elbert", label: "Elbert" },
    { value: "Fremont", label: "Fremont" },
    { value: "Garfield", label: "Garfield" },
    { value: "Gilpin", label: "Gilpin" },
    { value: "Grand", label: "Grand" },
    { value: "Gunnison", label: "Gunnison" },
    { value: "Hinsdale", label: "Hinsdale" },
    { value: "Huerfano", label: "Huerfano" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Kiowa", label: "Kiowa" },
    { value: "Kit Carson", label: "Kit Carson" },
    { value: "La Plata", label: "La Plata" },
    { value: "Lake", label: "Lake" },
    { value: "Larimer", label: "Larimer" },
    { value: "Las Animas", label: "Las Animas" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Logan", label: "Logan" },
    { value: "Mesa", label: "Mesa" },
    { value: "Mineral", label: "Mineral" },
    { value: "Moffat", label: "Moffat" },
    { value: "Montezuma", label: "Montezuma" },
    { value: "Montrose", label: "Montrose" },
    { value: "Morgan", label: "Morgan" },
    { value: "Otero", label: "Otero" },
    { value: "Ouray", label: "Ouray" },
    { value: "Park", label: "Park" },
    { value: "Phillips", label: "Phillips" },
    { value: "Pitkin", label: "Pitkin" },
    { value: "Prowers", label: "Prowers" },
    { value: "Pueblo", label: "Pueblo" },
    { value: "Rio Blanco", label: "Rio Blanco" },
    { value: "Rio Grande", label: "Rio Grande" },
    { value: "Routt", label: "Routt" },
    { value: "Saguache", label: "Saguache" },
    { value: "San Juan", label: "San Juan" },
    { value: "San Miguel", label: "San Miguel" },
    { value: "Sedgwick", label: "Sedgwick" },
    { value: "Summit", label: "Summit" },
    { value: "Teller", label: "Teller" },
    { value: "Washington", label: "Washington" },
    { value: "Weld", label: "Weld" },
    { value: "Yuma", label: "Yuma" },
  ],
  Connecticut: [
    { value: "Fairfield", label: "Fairfield" },
    { value: "Hartford", label: "Hartford" },
    { value: "Litchfield", label: "Litchfield" },
    { value: "Middlesex", label: "Middlesex" },
    { value: "New Haven", label: "New Haven" },
    { value: "New London", label: "New London" },
    { value: "Tolland", label: "Tolland" },
    { value: "Windham", label: "Windham" },
  ],
  Delaware: [
    { value: "Kent", label: "Kent" },
    { value: "New Castle", label: "New Castle" },
    { value: "Sussex", label: "Sussex" },
  ],
  "District of Columbia": [
    { value: "District of Columbia", label: "District of Columbia" },
  ],
  Florida: [
    { value: "Alachua", label: "Alachua" },
    { value: "Baker", label: "Baker" },
    { value: "Bay", label: "Bay" },
    { value: "Bradford", label: "Bradford" },
    { value: "Brevard", label: "Brevard" },
    { value: "Broward", label: "Broward" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Charlotte", label: "Charlotte" },
    { value: "Citrus", label: "Citrus" },
    { value: "Clay", label: "Clay" },
    { value: "Collier", label: "Collier" },
    { value: "Columbia", label: "Columbia" },
    { value: "DeSoto", label: "DeSoto" },
    { value: "Dixie", label: "Dixie" },
    { value: "Duval", label: "Duval" },
    { value: "Escambia", label: "Escambia" },
    { value: "Flagler", label: "Flagler" },
    { value: "Franklin", label: "Franklin" },
    { value: "Gadsden", label: "Gadsden" },
    { value: "Gilchrist", label: "Gilchrist" },
    { value: "Glades", label: "Glades" },
    { value: "Gulf", label: "Gulf" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hardee", label: "Hardee" },
    { value: "Hendry", label: "Hendry" },
    { value: "Hernando", label: "Hernando" },
    { value: "Highlands", label: "Highlands" },
    { value: "Hillsborough", label: "Hillsborough" },
    { value: "Holmes", label: "Holmes" },
    { value: "Indian River", label: "Indian River" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Lake", label: "Lake" },
    { value: "Lee", label: "Lee" },
    { value: "Leon", label: "Leon" },
    { value: "Levy", label: "Levy" },
    { value: "Liberty", label: "Liberty" },
    { value: "Madison", label: "Madison" },
    { value: "Manatee", label: "Manatee" },
    { value: "Marion", label: "Marion" },
    { value: "Martin", label: "Martin" },
    { value: "Miami-Dade", label: "Miami-Dade" },
    { value: "Monroe", label: "Monroe" },
    { value: "Nassau", label: "Nassau" },
    { value: "Okaloosa", label: "Okaloosa" },
    { value: "Okeechobee", label: "Okeechobee" },
    { value: "Orange", label: "Orange" },
    { value: "Osceola", label: "Osceola" },
    { value: "Palm Beach", label: "Palm Beach" },
    { value: "Pasco", label: "Pasco" },
    { value: "Pinellas", label: "Pinellas" },
    { value: "Polk", label: "Polk" },
    { value: "Putnam", label: "Putnam" },
    { value: "Santa Rosa", label: "Santa Rosa" },
    { value: "Sarasota", label: "Sarasota" },
    { value: "Seminole", label: "Seminole" },
    { value: "St. Johns", label: "St. Johns" },
    { value: "St. Lucie", label: "St. Lucie" },
    { value: "Sumter", label: "Sumter" },
    { value: "Suwannee", label: "Suwannee" },
    { value: "Taylor", label: "Taylor" },
    { value: "Union", label: "Union" },
    { value: "Volusia", label: "Volusia" },
    { value: "Wakulla", label: "Wakulla" },
    { value: "Walton", label: "Walton" },
    { value: "Washington", label: "Washington" },
  ],
  Georgia: [
    { value: "Appling", label: "Appling" },
    { value: "Atkinson", label: "Atkinson" },
    { value: "Bacon", label: "Bacon" },
    { value: "Baker", label: "Baker" },
    { value: "Baldwin", label: "Baldwin" },
    { value: "Banks", label: "Banks" },
    { value: "Barrow", label: "Barrow" },
    { value: "Bartow", label: "Bartow" },
    { value: "Ben Hill", label: "Ben Hill" },
    { value: "Berrien", label: "Berrien" },
    { value: "Bibb", label: "Bibb" },
    { value: "Bleckley", label: "Bleckley" },
    { value: "Brantley", label: "Brantley" },
    { value: "Brooks", label: "Brooks" },
    { value: "Bryan", label: "Bryan" },
    { value: "Bulloch", label: "Bulloch" },
    { value: "Burke", label: "Burke" },
    { value: "Butts", label: "Butts" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Camden", label: "Camden" },
    { value: "Candler", label: "Candler" },
    { value: "Carroll", label: "Carroll" },
    { value: "Catoosa", label: "Catoosa" },
    { value: "Charlton", label: "Charlton" },
    { value: "Chatham", label: "Chatham" },
    { value: "Chattahoochee", label: "Chattahoochee" },
    { value: "Chattooga", label: "Chattooga" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Clarke", label: "Clarke" },
    { value: "Clay", label: "Clay" },
    { value: "Clayton", label: "Clayton" },
    { value: "Clinch", label: "Clinch" },
    { value: "Cobb", label: "Cobb" },
    { value: "Coffee", label: "Coffee" },
    { value: "Colquitt", label: "Colquitt" },
    { value: "Columbia", label: "Columbia" },
    { value: "Cook", label: "Cook" },
    { value: "Coweta", label: "Coweta" },
    { value: "Crawford", label: "Crawford" },
    { value: "Crisp", label: "Crisp" },
    { value: "Dade", label: "Dade" },
    { value: "Dawson", label: "Dawson" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Decatur", label: "Decatur" },
    { value: "Dodge", label: "Dodge" },
    { value: "Dooly", label: "Dooly" },
    { value: "Dougherty", label: "Dougherty" },
    { value: "Douglas", label: "Douglas" },
    { value: "Early", label: "Early" },
    { value: "Echols", label: "Echols" },
    { value: "Effingham", label: "Effingham" },
    { value: "Elbert", label: "Elbert" },
    { value: "Emanuel", label: "Emanuel" },
    { value: "Evans", label: "Evans" },
    { value: "Fannin", label: "Fannin" },
    { value: "Fayette", label: "Fayette" },
    { value: "Floyd", label: "Floyd" },
    { value: "Forsyth", label: "Forsyth" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Gilmer", label: "Gilmer" },
    { value: "Glascock", label: "Glascock" },
    { value: "Glynn", label: "Glynn" },
    { value: "Gordon", label: "Gordon" },
    { value: "Grady", label: "Grady" },
    { value: "Greene", label: "Greene" },
    { value: "Gwinnett", label: "Gwinnett" },
    { value: "Habersham", label: "Habersham" },
    { value: "Hall", label: "Hall" },
    { value: "Hancock", label: "Hancock" },
    { value: "Haralson", label: "Haralson" },
    { value: "Harris", label: "Harris" },
    { value: "Hart", label: "Hart" },
    { value: "Heard", label: "Heard" },
    { value: "Henry", label: "Henry" },
    { value: "Houston", label: "Houston" },
    { value: "Irwin", label: "Irwin" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jeff Davis", label: "Jeff Davis" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jenkins", label: "Jenkins" },
    { value: "Johnson", label: "Johnson" },
    { value: "Jones", label: "Jones" },
    { value: "Lamar", label: "Lamar" },
    { value: "Lanier", label: "Lanier" },
    { value: "Laurens", label: "Laurens" },
    { value: "Lee", label: "Lee" },
    { value: "Liberty", label: "Liberty" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Long", label: "Long" },
    { value: "Lowndes", label: "Lowndes" },
    { value: "Lumpkin", label: "Lumpkin" },
    { value: "Macon", label: "Macon" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "McDuffie", label: "McDuffie" },
    { value: "McIntosh", label: "McIntosh" },
    { value: "Meriwether", label: "Meriwether" },
    { value: "Miller", label: "Miller" },
    { value: "Mitchell", label: "Mitchell" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Murray", label: "Murray" },
    { value: "Muscogee", label: "Muscogee" },
    { value: "Newton", label: "Newton" },
    { value: "Oconee", label: "Oconee" },
    { value: "Oglethorpe", label: "Oglethorpe" },
    { value: "Paulding", label: "Paulding" },
    { value: "Peach", label: "Peach" },
    { value: "Pickens", label: "Pickens" },
    { value: "Pierce", label: "Pierce" },
    { value: "Pike", label: "Pike" },
    { value: "Polk", label: "Polk" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Putnam", label: "Putnam" },
    { value: "Quitman", label: "Quitman" },
    { value: "Rabun", label: "Rabun" },
    { value: "Randolph", label: "Randolph" },
    { value: "Richmond", label: "Richmond" },
    { value: "Rockdale", label: "Rockdale" },
    { value: "Schley", label: "Schley" },
    { value: "Screven", label: "Screven" },
    { value: "Seminole", label: "Seminole" },
    { value: "Spalding", label: "Spalding" },
    { value: "Stephens", label: "Stephens" },
    { value: "Stewart", label: "Stewart" },
    { value: "Sumter", label: "Sumter" },
    { value: "Talbot", label: "Talbot" },
    { value: "Taliaferro", label: "Taliaferro" },
    { value: "Tattnall", label: "Tattnall" },
    { value: "Taylor", label: "Taylor" },
    { value: "Telfair", label: "Telfair" },
    { value: "Terrell", label: "Terrell" },
    { value: "Thomas", label: "Thomas" },
    { value: "Tift", label: "Tift" },
    { value: "Toombs", label: "Toombs" },
    { value: "Towns", label: "Towns" },
    { value: "Treutlen", label: "Treutlen" },
    { value: "Troup", label: "Troup" },
    { value: "Turner", label: "Turner" },
    { value: "Twiggs", label: "Twiggs" },
    { value: "Union", label: "Union" },
    { value: "Upson", label: "Upson" },
    { value: "Walker", label: "Walker" },
    { value: "Walton", label: "Walton" },
    { value: "Ware", label: "Ware" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Wheeler", label: "Wheeler" },
    { value: "White", label: "White" },
    { value: "Whitfield", label: "Whitfield" },
    { value: "Wilcox", label: "Wilcox" },
    { value: "Wilkes", label: "Wilkes" },
    { value: "Wilkinson", label: "Wilkinson" },
    { value: "Worth", label: "Worth" },
  ],
  Hawaii: [
    { value: "Hawaii", label: "Hawaii" },
    { value: "Honolulu", label: "Honolulu" },
    { value: "Kalawao", label: "Kalawao" },
    { value: "Kauai", label: "Kauai" },
    { value: "Maui", label: "Maui" },
  ],
  Idaho: [
    { value: "Ada", label: "Ada" },
    { value: "Adams", label: "Adams" },
    { value: "Bannock", label: "Bannock" },
    { value: "Bear Lake", label: "Bear Lake" },
    { value: "Benewah", label: "Benewah" },
    { value: "Bingham", label: "Bingham" },
    { value: "Blaine", label: "Blaine" },
    { value: "Boise", label: "Boise" },
    { value: "Bonner", label: "Bonner" },
    { value: "Bonneville", label: "Bonneville" },
    { value: "Boundary", label: "Boundary" },
    { value: "Butte", label: "Butte" },
    { value: "Camas", label: "Camas" },
    { value: "Canyon", label: "Canyon" },
    { value: "Caribou", label: "Caribou" },
    { value: "Cassia", label: "Cassia" },
    { value: "Clark", label: "Clark" },
    { value: "Clearwater", label: "Clearwater" },
    { value: "Custer", label: "Custer" },
    { value: "Elmore", label: "Elmore" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fremont", label: "Fremont" },
    { value: "Gem", label: "Gem" },
    { value: "Gooding", label: "Gooding" },
    { value: "Idaho", label: "Idaho" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jerome", label: "Jerome" },
    { value: "Kootenai", label: "Kootenai" },
    { value: "Latah", label: "Latah" },
    { value: "Lemhi", label: "Lemhi" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Madison", label: "Madison" },
    { value: "Minidoka", label: "Minidoka" },
    { value: "Nez Perce", label: "Nez Perce" },
    { value: "Oneida", label: "Oneida" },
    { value: "Owyhee", label: "Owyhee" },
    { value: "Payette", label: "Payette" },
    { value: "Power", label: "Power" },
    { value: "Shoshone", label: "Shoshone" },
    { value: "Teton", label: "Teton" },
    { value: "Twin Falls", label: "Twin Falls" },
    { value: "Valley", label: "Valley" },
    { value: "Washington", label: "Washington" },
  ],
  Illinois: [
    { value: "Adams", label: "Adams" },
    { value: "Alexander", label: "Alexander" },
    { value: "Bond", label: "Bond" },
    { value: "Boone", label: "Boone" },
    { value: "Brown", label: "Brown" },
    { value: "Bureau", label: "Bureau" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Carroll", label: "Carroll" },
    { value: "Cass", label: "Cass" },
    { value: "Champaign", label: "Champaign" },
    { value: "Christian", label: "Christian" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Clinton", label: "Clinton" },
    { value: "Coles", label: "Coles" },
    { value: "Cook", label: "Cook" },
    { value: "Crawford", label: "Crawford" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "De Witt", label: "De Witt" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Douglas", label: "Douglas" },
    { value: "DuPage", label: "DuPage" },
    { value: "Edgar", label: "Edgar" },
    { value: "Edwards", label: "Edwards" },
    { value: "Effingham", label: "Effingham" },
    { value: "Fayette", label: "Fayette" },
    { value: "Ford", label: "Ford" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Gallatin", label: "Gallatin" },
    { value: "Greene", label: "Greene" },
    { value: "Grundy", label: "Grundy" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardin", label: "Hardin" },
    { value: "Henderson", label: "Henderson" },
    { value: "Henry", label: "Henry" },
    { value: "Iroquois", label: "Iroquois" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jo Daviess", label: "Jo Daviess" },
    { value: "Johnson", label: "Johnson" },
    { value: "Kane", label: "Kane" },
    { value: "Kankakee", label: "Kankakee" },
    { value: "Kendall", label: "Kendall" },
    { value: "Knox", label: "Knox" },
    { value: "LaSalle", label: "LaSalle" },
    { value: "Lake", label: "Lake" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lee", label: "Lee" },
    { value: "Livingston", label: "Livingston" },
    { value: "Logan", label: "Logan" },
    { value: "Macon", label: "Macon" },
    { value: "Macoupin", label: "Macoupin" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Mason", label: "Mason" },
    { value: "Massac", label: "Massac" },
    { value: "McDonough", label: "McDonough" },
    { value: "McHenry", label: "McHenry" },
    { value: "McLean", label: "McLean" },
    { value: "Menard", label: "Menard" },
    { value: "Mercer", label: "Mercer" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Moultrie", label: "Moultrie" },
    { value: "Ogle", label: "Ogle" },
    { value: "Peoria", label: "Peoria" },
    { value: "Perry", label: "Perry" },
    { value: "Piatt", label: "Piatt" },
    { value: "Pike", label: "Pike" },
    { value: "Pope", label: "Pope" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Putnam", label: "Putnam" },
    { value: "Randolph", label: "Randolph" },
    { value: "Richland", label: "Richland" },
    { value: "Rock Island", label: "Rock Island" },
    { value: "Saline", label: "Saline" },
    { value: "Sangamon", label: "Sangamon" },
    { value: "Schuyler", label: "Schuyler" },
    { value: "Scott", label: "Scott" },
    { value: "Shelby", label: "Shelby" },
    { value: "St. Clair", label: "St. Clair" },
    { value: "Stark", label: "Stark" },
    { value: "Stephenson", label: "Stephenson" },
    { value: "Tazewell", label: "Tazewell" },
    { value: "Union", label: "Union" },
    { value: "Vermilion", label: "Vermilion" },
    { value: "Wabash", label: "Wabash" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "White", label: "White" },
    { value: "Whiteside", label: "Whiteside" },
    { value: "Will", label: "Will" },
    { value: "Williamson", label: "Williamson" },
    { value: "Winnebago", label: "Winnebago" },
    { value: "Woodford", label: "Woodford" },
  ],
  Indiana: [
    { value: "Adams", label: "Adams" },
    { value: "Allen", label: "Allen" },
    { value: "Bartholomew", label: "Bartholomew" },
    { value: "Benton", label: "Benton" },
    { value: "Blackford", label: "Blackford" },
    { value: "Boone", label: "Boone" },
    { value: "Brown", label: "Brown" },
    { value: "Carroll", label: "Carroll" },
    { value: "Cass", label: "Cass" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Clinton", label: "Clinton" },
    { value: "Crawford", label: "Crawford" },
    { value: "Daviess", label: "Daviess" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Dearborn", label: "Dearborn" },
    { value: "Decatur", label: "Decatur" },
    { value: "Delaware", label: "Delaware" },
    { value: "Dubois", label: "Dubois" },
    { value: "Elkhart", label: "Elkhart" },
    { value: "Fayette", label: "Fayette" },
    { value: "Floyd", label: "Floyd" },
    { value: "Fountain", label: "Fountain" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Gibson", label: "Gibson" },
    { value: "Grant", label: "Grant" },
    { value: "Greene", label: "Greene" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hancock", label: "Hancock" },
    { value: "Harrison", label: "Harrison" },
    { value: "Hendricks", label: "Hendricks" },
    { value: "Henry", label: "Henry" },
    { value: "Howard", label: "Howard" },
    { value: "Huntington", label: "Huntington" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jay", label: "Jay" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jennings", label: "Jennings" },
    { value: "Johnson", label: "Johnson" },
    { value: "Knox", label: "Knox" },
    { value: "Kosciusko", label: "Kosciusko" },
    { value: "LaGrange", label: "LaGrange" },
    { value: "LaPorte", label: "LaPorte" },
    { value: "Lake", label: "Lake" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Martin", label: "Martin" },
    { value: "Miami", label: "Miami" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Newton", label: "Newton" },
    { value: "Noble", label: "Noble" },
    { value: "Ohio", label: "Ohio" },
    { value: "Orange", label: "Orange" },
    { value: "Owen", label: "Owen" },
    { value: "Parke", label: "Parke" },
    { value: "Perry", label: "Perry" },
    { value: "Pike", label: "Pike" },
    { value: "Porter", label: "Porter" },
    { value: "Posey", label: "Posey" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Putnam", label: "Putnam" },
    { value: "Randolph", label: "Randolph" },
    { value: "Ripley", label: "Ripley" },
    { value: "Rush", label: "Rush" },
    { value: "Scott", label: "Scott" },
    { value: "Shelby", label: "Shelby" },
    { value: "Spencer", label: "Spencer" },
    { value: "St. Joseph", label: "St. Joseph" },
    { value: "Starke", label: "Starke" },
    { value: "Steuben", label: "Steuben" },
    { value: "Sullivan", label: "Sullivan" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Tippecanoe", label: "Tippecanoe" },
    { value: "Tipton", label: "Tipton" },
    { value: "Union", label: "Union" },
    { value: "Vanderburgh", label: "Vanderburgh" },
    { value: "Vermillion", label: "Vermillion" },
    { value: "Vigo", label: "Vigo" },
    { value: "Wabash", label: "Wabash" },
    { value: "Warren", label: "Warren" },
    { value: "Warrick", label: "Warrick" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Wells", label: "Wells" },
    { value: "White", label: "White" },
    { value: "Whitley", label: "Whitley" },
  ],
  Iowa: [
    { value: "Adair", label: "Adair" },
    { value: "Adams", label: "Adams" },
    { value: "Allamakee", label: "Allamakee" },
    { value: "Appanoose", label: "Appanoose" },
    { value: "Audubon", label: "Audubon" },
    { value: "Benton", label: "Benton" },
    { value: "Black Hawk", label: "Black Hawk" },
    { value: "Boone", label: "Boone" },
    { value: "Bremer", label: "Bremer" },
    { value: "Buchanan", label: "Buchanan" },
    { value: "Buena Vista", label: "Buena Vista" },
    { value: "Butler", label: "Butler" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Carroll", label: "Carroll" },
    { value: "Cass", label: "Cass" },
    { value: "Cedar", label: "Cedar" },
    { value: "Cerro Gordo", label: "Cerro Gordo" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Chickasaw", label: "Chickasaw" },
    { value: "Clarke", label: "Clarke" },
    { value: "Clay", label: "Clay" },
    { value: "Clayton", label: "Clayton" },
    { value: "Clinton", label: "Clinton" },
    { value: "Crawford", label: "Crawford" },
    { value: "Dallas", label: "Dallas" },
    { value: "Davis", label: "Davis" },
    { value: "Decatur", label: "Decatur" },
    { value: "Delaware", label: "Delaware" },
    { value: "Des Moines", label: "Des Moines" },
    { value: "Dickinson", label: "Dickinson" },
    { value: "Dubuque", label: "Dubuque" },
    { value: "Emmet", label: "Emmet" },
    { value: "Fayette", label: "Fayette" },
    { value: "Floyd", label: "Floyd" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fremont", label: "Fremont" },
    { value: "Greene", label: "Greene" },
    { value: "Grundy", label: "Grundy" },
    { value: "Guthrie", label: "Guthrie" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardin", label: "Hardin" },
    { value: "Harrison", label: "Harrison" },
    { value: "Henry", label: "Henry" },
    { value: "Howard", label: "Howard" },
    { value: "Humboldt", label: "Humboldt" },
    { value: "Ida", label: "Ida" },
    { value: "Iowa", label: "Iowa" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnson", label: "Johnson" },
    { value: "Jones", label: "Jones" },
    { value: "Keokuk", label: "Keokuk" },
    { value: "Kossuth", label: "Kossuth" },
    { value: "Lee", label: "Lee" },
    { value: "Linn", label: "Linn" },
    { value: "Louisa", label: "Louisa" },
    { value: "Lucas", label: "Lucas" },
    { value: "Lyon", label: "Lyon" },
    { value: "Madison", label: "Madison" },
    { value: "Mahaska", label: "Mahaska" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Mills", label: "Mills" },
    { value: "Mitchell", label: "Mitchell" },
    { value: "Monona", label: "Monona" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Muscatine", label: "Muscatine" },
    { value: "O'Brien", label: "O'Brien" },
    { value: "Osceola", label: "Osceola" },
    { value: "Page", label: "Page" },
    { value: "Palo Alto", label: "Palo Alto" },
    { value: "Plymouth", label: "Plymouth" },
    { value: "Pocahontas", label: "Pocahontas" },
    { value: "Polk", label: "Polk" },
    { value: "Pottawattamie", label: "Pottawattamie" },
    { value: "Poweshiek", label: "Poweshiek" },
    { value: "Ringgold", label: "Ringgold" },
    { value: "Sac", label: "Sac" },
    { value: "Scott", label: "Scott" },
    { value: "Shelby", label: "Shelby" },
    { value: "Sioux", label: "Sioux" },
    { value: "Story", label: "Story" },
    { value: "Tama", label: "Tama" },
    { value: "Taylor", label: "Taylor" },
    { value: "Union", label: "Union" },
    { value: "Van Buren", label: "Van Buren" },
    { value: "Wapello", label: "Wapello" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Winnebago", label: "Winnebago" },
    { value: "Winneshiek", label: "Winneshiek" },
    { value: "Woodbury", label: "Woodbury" },
    { value: "Worth", label: "Worth" },
    { value: "Wright", label: "Wright" },
  ],
  Kansas: [
    { value: "Allen", label: "Allen" },
    { value: "Anderson", label: "Anderson" },
    { value: "Atchison", label: "Atchison" },
    { value: "Barber", label: "Barber" },
    { value: "Barton", label: "Barton" },
    { value: "Bourbon", label: "Bourbon" },
    { value: "Brown", label: "Brown" },
    { value: "Butler", label: "Butler" },
    { value: "Chase", label: "Chase" },
    { value: "Chautauqua", label: "Chautauqua" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Cheyenne", label: "Cheyenne" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Cloud", label: "Cloud" },
    { value: "Coffey", label: "Coffey" },
    { value: "Comanche", label: "Comanche" },
    { value: "Cowley", label: "Cowley" },
    { value: "Crawford", label: "Crawford" },
    { value: "Decatur", label: "Decatur" },
    { value: "Dickinson", label: "Dickinson" },
    { value: "Doniphan", label: "Doniphan" },
    { value: "Douglas", label: "Douglas" },
    { value: "Edwards", label: "Edwards" },
    { value: "Elk", label: "Elk" },
    { value: "Ellis", label: "Ellis" },
    { value: "Ellsworth", label: "Ellsworth" },
    { value: "Finney", label: "Finney" },
    { value: "Ford", label: "Ford" },
    { value: "Franklin", label: "Franklin" },
    { value: "Geary", label: "Geary" },
    { value: "Gove", label: "Gove" },
    { value: "Graham", label: "Graham" },
    { value: "Grant", label: "Grant" },
    { value: "Gray", label: "Gray" },
    { value: "Greeley", label: "Greeley" },
    { value: "Greenwood", label: "Greenwood" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Harper", label: "Harper" },
    { value: "Harvey", label: "Harvey" },
    { value: "Haskell", label: "Haskell" },
    { value: "Hodgeman", label: "Hodgeman" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jewell", label: "Jewell" },
    { value: "Johnson", label: "Johnson" },
    { value: "Kearny", label: "Kearny" },
    { value: "Kingman", label: "Kingman" },
    { value: "Kiowa", label: "Kiowa" },
    { value: "Labette", label: "Labette" },
    { value: "Lane", label: "Lane" },
    { value: "Leavenworth", label: "Leavenworth" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Linn", label: "Linn" },
    { value: "Logan", label: "Logan" },
    { value: "Lyon", label: "Lyon" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "McPherson", label: "McPherson" },
    { value: "Meade", label: "Meade" },
    { value: "Miami", label: "Miami" },
    { value: "Mitchell", label: "Mitchell" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morris", label: "Morris" },
    { value: "Morton", label: "Morton" },
    { value: "Nemaha", label: "Nemaha" },
    { value: "Neosho", label: "Neosho" },
    { value: "Ness", label: "Ness" },
    { value: "Norton", label: "Norton" },
    { value: "Osage", label: "Osage" },
    { value: "Osborne", label: "Osborne" },
    { value: "Ottawa", label: "Ottawa" },
    { value: "Pawnee", label: "Pawnee" },
    { value: "Phillips", label: "Phillips" },
    { value: "Pottawatomie", label: "Pottawatomie" },
    { value: "Pratt", label: "Pratt" },
    { value: "Rawlins", label: "Rawlins" },
    { value: "Reno", label: "Reno" },
    { value: "Republic", label: "Republic" },
    { value: "Rice", label: "Rice" },
    { value: "Riley", label: "Riley" },
    { value: "Rooks", label: "Rooks" },
    { value: "Rush", label: "Rush" },
    { value: "Russell", label: "Russell" },
    { value: "Saline", label: "Saline" },
    { value: "Scott", label: "Scott" },
    { value: "Sedgwick", label: "Sedgwick" },
    { value: "Seward", label: "Seward" },
    { value: "Shawnee", label: "Shawnee" },
    { value: "Sheridan", label: "Sheridan" },
    { value: "Sherman", label: "Sherman" },
    { value: "Smith", label: "Smith" },
    { value: "Stafford", label: "Stafford" },
    { value: "Stanton", label: "Stanton" },
    { value: "Stevens", label: "Stevens" },
    { value: "Sumner", label: "Sumner" },
    { value: "Thomas", label: "Thomas" },
    { value: "Trego", label: "Trego" },
    { value: "Wabaunsee", label: "Wabaunsee" },
    { value: "Wallace", label: "Wallace" },
    { value: "Washington", label: "Washington" },
    { value: "Wichita", label: "Wichita" },
    { value: "Wilson", label: "Wilson" },
    { value: "Woodson", label: "Woodson" },
    { value: "Wyandotte", label: "Wyandotte" },
  ],
  Kentucky: [
    { value: "Adair", label: "Adair" },
    { value: "Allen", label: "Allen" },
    { value: "Anderson", label: "Anderson" },
    { value: "Ballard", label: "Ballard" },
    { value: "Barren", label: "Barren" },
    { value: "Bath", label: "Bath" },
    { value: "Bell", label: "Bell" },
    { value: "Boone", label: "Boone" },
    { value: "Bourbon", label: "Bourbon" },
    { value: "Boyd", label: "Boyd" },
    { value: "Boyle", label: "Boyle" },
    { value: "Bracken", label: "Bracken" },
    { value: "Breathitt", label: "Breathitt" },
    { value: "Breckinridge", label: "Breckinridge" },
    { value: "Bullitt", label: "Bullitt" },
    { value: "Butler", label: "Butler" },
    { value: "Caldwell", label: "Caldwell" },
    { value: "Calloway", label: "Calloway" },
    { value: "Campbell", label: "Campbell" },
    { value: "Carlisle", label: "Carlisle" },
    { value: "Carroll", label: "Carroll" },
    { value: "Carter", label: "Carter" },
    { value: "Casey", label: "Casey" },
    { value: "Christian", label: "Christian" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Clinton", label: "Clinton" },
    { value: "Crittenden", label: "Crittenden" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Daviess", label: "Daviess" },
    { value: "Edmonson", label: "Edmonson" },
    { value: "Elliott", label: "Elliott" },
    { value: "Estill", label: "Estill" },
    { value: "Fayette", label: "Fayette" },
    { value: "Fleming", label: "Fleming" },
    { value: "Floyd", label: "Floyd" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Gallatin", label: "Gallatin" },
    { value: "Garrard", label: "Garrard" },
    { value: "Grant", label: "Grant" },
    { value: "Graves", label: "Graves" },
    { value: "Grayson", label: "Grayson" },
    { value: "Green", label: "Green" },
    { value: "Greenup", label: "Greenup" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardin", label: "Hardin" },
    { value: "Harlan", label: "Harlan" },
    { value: "Harrison", label: "Harrison" },
    { value: "Hart", label: "Hart" },
    { value: "Henderson", label: "Henderson" },
    { value: "Henry", label: "Henry" },
    { value: "Hickman", label: "Hickman" },
    { value: "Hopkins", label: "Hopkins" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jessamine", label: "Jessamine" },
    { value: "Johnson", label: "Johnson" },
    { value: "Kenton", label: "Kenton" },
    { value: "Knott", label: "Knott" },
    { value: "Knox", label: "Knox" },
    { value: "Larue", label: "Larue" },
    { value: "Laurel", label: "Laurel" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lee", label: "Lee" },
    { value: "Leslie", label: "Leslie" },
    { value: "Letcher", label: "Letcher" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Livingston", label: "Livingston" },
    { value: "Logan", label: "Logan" },
    { value: "Lyon", label: "Lyon" },
    { value: "Madison", label: "Madison" },
    { value: "Magoffin", label: "Magoffin" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Martin", label: "Martin" },
    { value: "Mason", label: "Mason" },
    { value: "McCracken", label: "McCracken" },
    { value: "McCreary", label: "McCreary" },
    { value: "McLean", label: "McLean" },
    { value: "Meade", label: "Meade" },
    { value: "Menifee", label: "Menifee" },
    { value: "Mercer", label: "Mercer" },
    { value: "Metcalfe", label: "Metcalfe" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Muhlenberg", label: "Muhlenberg" },
    { value: "Nelson", label: "Nelson" },
    { value: "Nicholas", label: "Nicholas" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oldham", label: "Oldham" },
    { value: "Owen", label: "Owen" },
    { value: "Owsley", label: "Owsley" },
    { value: "Pendleton", label: "Pendleton" },
    { value: "Perry", label: "Perry" },
    { value: "Pike", label: "Pike" },
    { value: "Powell", label: "Powell" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Robertson", label: "Robertson" },
    { value: "Rockcastle", label: "Rockcastle" },
    { value: "Rowan", label: "Rowan" },
    { value: "Russell", label: "Russell" },
    { value: "Scott", label: "Scott" },
    { value: "Shelby", label: "Shelby" },
    { value: "Simpson", label: "Simpson" },
    { value: "Spencer", label: "Spencer" },
    { value: "Taylor", label: "Taylor" },
    { value: "Todd", label: "Todd" },
    { value: "Trigg", label: "Trigg" },
    { value: "Trimble", label: "Trimble" },
    { value: "Union", label: "Union" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Whitley", label: "Whitley" },
    { value: "Wolfe", label: "Wolfe" },
    { value: "Woodford", label: "Woodford" },
  ],
  Louisiana: [
    { value: "Acadia", label: "Acadia" },
    { value: "Allen", label: "Allen" },
    { value: "Ascension", label: "Ascension" },
    { value: "Assumption", label: "Assumption" },
    { value: "Avoyelles", label: "Avoyelles" },
    { value: "Beauregard", label: "Beauregard" },
    { value: "Bienville", label: "Bienville" },
    { value: "Bossier", label: "Bossier" },
    { value: "Caddo", label: "Caddo" },
    { value: "Calcasieu", label: "Calcasieu" },
    { value: "Caldwell", label: "Caldwell" },
    { value: "Cameron", label: "Cameron" },
    { value: "Catahoula", label: "Catahoula" },
    { value: "Claiborne", label: "Claiborne" },
    { value: "Concordia", label: "Concordia" },
    { value: "De Soto", label: "De Soto" },
    { value: "East Baton Rouge", label: "East Baton Rouge" },
    { value: "East Carroll", label: "East Carroll" },
    { value: "East Feliciana", label: "East Feliciana" },
    { value: "Evangeline", label: "Evangeline" },
    { value: "Franklin", label: "Franklin" },
    { value: "Grant", label: "Grant" },
    { value: "Iberia", label: "Iberia" },
    { value: "Iberville", label: "Iberville" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jefferson Davis", label: "Jefferson Davis" },
    { value: "La Salle", label: "La Salle" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Lafourche", label: "Lafourche" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Livingston", label: "Livingston" },
    { value: "Madison", label: "Madison" },
    { value: "Morehouse", label: "Morehouse" },
    { value: "Natchitoches", label: "Natchitoches" },
    { value: "Orleans", label: "Orleans" },
    { value: "Ouachita", label: "Ouachita" },
    { value: "Plaquemines", label: "Plaquemines" },
    { value: "Pointe Coupee", label: "Pointe Coupee" },
    { value: "Rapides", label: "Rapides" },
    { value: "Red River", label: "Red River" },
    { value: "Richland", label: "Richland" },
    { value: "Sabine", label: "Sabine" },
    { value: "St. Bernard", label: "St. Bernard" },
    { value: "St. Charles", label: "St. Charles" },
    { value: "St. Helena", label: "St. Helena" },
    { value: "St. James", label: "St. James" },
    { value: "St. John the Baptist", label: "St. John the Baptist" },
    { value: "St. Landry", label: "St. Landry" },
    { value: "St. Martin", label: "St. Martin" },
    { value: "St. Mary", label: "St. Mary" },
    { value: "St. Tammany", label: "St. Tammany" },
    { value: "Tangipahoa", label: "Tangipahoa" },
    { value: "Tensas", label: "Tensas" },
    { value: "Terrebonne", label: "Terrebonne" },
    { value: "Union", label: "Union" },
    { value: "Vermilion", label: "Vermilion" },
    { value: "Vernon", label: "Vernon" },
    { value: "Washington", label: "Washington" },
    { value: "Webster", label: "Webster" },
    { value: "West Baton Rouge", label: "West Baton Rouge" },
    { value: "West Carroll", label: "West Carroll" },
    { value: "West Feliciana", label: "West Feliciana" },
    { value: "Winn", label: "Winn" },
  ],
  Maine: [
    { value: "Androscoggin", label: "Androscoggin" },
    { value: "Aroostook", label: "Aroostook" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Franklin", label: "Franklin" },
    { value: "Hancock", label: "Hancock" },
    { value: "Kennebec", label: "Kennebec" },
    { value: "Knox", label: "Knox" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Oxford", label: "Oxford" },
    { value: "Penobscot", label: "Penobscot" },
    { value: "Piscataquis", label: "Piscataquis" },
    { value: "Sagadahoc", label: "Sagadahoc" },
    { value: "Somerset", label: "Somerset" },
    { value: "Waldo", label: "Waldo" },
    { value: "Washington", label: "Washington" },
    { value: "York", label: "York" },
  ],
  Maryland: [
    { value: "Allegany", label: "Allegany" },
    { value: "Anne Arundel", label: "Anne Arundel" },
    { value: "Baltimore City", label: "Baltimore City" },
    { value: "Baltimore County", label: "Baltimore County" },
    { value: "Calvert", label: "Calvert" },
    { value: "Caroline", label: "Caroline" },
    { value: "Carroll", label: "Carroll" },
    { value: "Cecil", label: "Cecil" },
    { value: "Charles", label: "Charles" },
    { value: "Dorchester", label: "Dorchester" },
    { value: "Frederick", label: "Frederick" },
    { value: "Garrett", label: "Garrett" },
    { value: "Harford", label: "Harford" },
    { value: "Howard", label: "Howard" },
    { value: "Kent", label: "Kent" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Prince George's", label: "Prince George's" },
    { value: "Queen Anne's", label: "Queen Anne's" },
    { value: "Somerset", label: "Somerset" },
    { value: "St. Mary's", label: "St. Mary's" },
    { value: "Talbot", label: "Talbot" },
    { value: "Washington", label: "Washington" },
    { value: "Wicomico", label: "Wicomico" },
    { value: "Worcester", label: "Worcester" },
  ],
  Massachusetts: [
    { value: "Barnstable", label: "Barnstable" },
    { value: "Berkshire", label: "Berkshire" },
    { value: "Bristol", label: "Bristol" },
    { value: "Dukes", label: "Dukes" },
    { value: "Essex", label: "Essex" },
    { value: "Franklin", label: "Franklin" },
    { value: "Hampden", label: "Hampden" },
    { value: "Hampshire", label: "Hampshire" },
    { value: "Middlesex", label: "Middlesex" },
    { value: "Nantucket", label: "Nantucket" },
    { value: "Norfolk", label: "Norfolk" },
    { value: "Plymouth", label: "Plymouth" },
    { value: "Suffolk", label: "Suffolk" },
    { value: "Worcester", label: "Worcester" },
  ],
  Michigan: [
    { value: "Alcona", label: "Alcona" },
    { value: "Alger", label: "Alger" },
    { value: "Allegan", label: "Allegan" },
    { value: "Alpena", label: "Alpena" },
    { value: "Antrim", label: "Antrim" },
    { value: "Arenac", label: "Arenac" },
    { value: "Baraga", label: "Baraga" },
    { value: "Barry", label: "Barry" },
    { value: "Bay", label: "Bay" },
    { value: "Benzie", label: "Benzie" },
    { value: "Berrien", label: "Berrien" },
    { value: "Branch", label: "Branch" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Cass", label: "Cass" },
    { value: "Charlevoix", label: "Charlevoix" },
    { value: "Cheboygan", label: "Cheboygan" },
    { value: "Chippewa", label: "Chippewa" },
    { value: "Clare", label: "Clare" },
    { value: "Clinton", label: "Clinton" },
    { value: "Crawford", label: "Crawford" },
    { value: "Delta", label: "Delta" },
    { value: "Dickinson", label: "Dickinson" },
    { value: "Eaton", label: "Eaton" },
    { value: "Emmet", label: "Emmet" },
    { value: "Genesee", label: "Genesee" },
    { value: "Gladwin", label: "Gladwin" },
    { value: "Gogebic", label: "Gogebic" },
    { value: "Grand Traverse", label: "Grand Traverse" },
    { value: "Gratiot", label: "Gratiot" },
    { value: "Hillsdale", label: "Hillsdale" },
    { value: "Houghton", label: "Houghton" },
    { value: "Huron", label: "Huron" },
    { value: "Ingham", label: "Ingham" },
    { value: "Ionia", label: "Ionia" },
    { value: "Iosco", label: "Iosco" },
    { value: "Iron", label: "Iron" },
    { value: "Isabella", label: "Isabella" },
    { value: "Jackson", label: "Jackson" },
    { value: "Kalamazoo", label: "Kalamazoo" },
    { value: "Kalkaska", label: "Kalkaska" },
    { value: "Kent", label: "Kent" },
    { value: "Keweenaw", label: "Keweenaw" },
    { value: "Lake", label: "Lake" },
    { value: "Lapeer", label: "Lapeer" },
    { value: "Leelanau", label: "Leelanau" },
    { value: "Lenawee", label: "Lenawee" },
    { value: "Livingston", label: "Livingston" },
    { value: "Luce", label: "Luce" },
    { value: "Mackinac", label: "Mackinac" },
    { value: "Macomb", label: "Macomb" },
    { value: "Manistee", label: "Manistee" },
    { value: "Marquette", label: "Marquette" },
    { value: "Mason", label: "Mason" },
    { value: "Mecosta", label: "Mecosta" },
    { value: "Menominee", label: "Menominee" },
    { value: "Midland", label: "Midland" },
    { value: "Missaukee", label: "Missaukee" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montcalm", label: "Montcalm" },
    { value: "Montmorency", label: "Montmorency" },
    { value: "Muskegon", label: "Muskegon" },
    { value: "Newaygo", label: "Newaygo" },
    { value: "Oakland", label: "Oakland" },
    { value: "Oceana", label: "Oceana" },
    { value: "Ogemaw", label: "Ogemaw" },
    { value: "Ontonagon", label: "Ontonagon" },
    { value: "Osceola", label: "Osceola" },
    { value: "Oscoda", label: "Oscoda" },
    { value: "Otsego", label: "Otsego" },
    { value: "Ottawa", label: "Ottawa" },
    { value: "Presque Isle", label: "Presque Isle" },
    { value: "Roscommon", label: "Roscommon" },
    { value: "Saginaw", label: "Saginaw" },
    { value: "Sanilac", label: "Sanilac" },
    { value: "Schoolcraft", label: "Schoolcraft" },
    { value: "Shiawassee", label: "Shiawassee" },
    { value: "St. Clair", label: "St. Clair" },
    { value: "St. Joseph", label: "St. Joseph" },
    { value: "Tuscola", label: "Tuscola" },
    { value: "Van Buren", label: "Van Buren" },
    { value: "Washtenaw", label: "Washtenaw" },
    { value: "Wayne", label: "Wayne" },
    { value: "Wexford", label: "Wexford" },
  ],
  Minnesota: [
    { value: "Aitkin", label: "Aitkin" },
    { value: "Anoka", label: "Anoka" },
    { value: "Becker", label: "Becker" },
    { value: "Beltrami", label: "Beltrami" },
    { value: "Benton", label: "Benton" },
    { value: "Big Stone", label: "Big Stone" },
    { value: "Blue Earth", label: "Blue Earth" },
    { value: "Brown", label: "Brown" },
    { value: "Carlton", label: "Carlton" },
    { value: "Carver", label: "Carver" },
    { value: "Cass", label: "Cass" },
    { value: "Chippewa", label: "Chippewa" },
    { value: "Chisago", label: "Chisago" },
    { value: "Clay", label: "Clay" },
    { value: "Clearwater", label: "Clearwater" },
    { value: "Cook", label: "Cook" },
    { value: "Cottonwood", label: "Cottonwood" },
    { value: "Crow Wing", label: "Crow Wing" },
    { value: "Dakota", label: "Dakota" },
    { value: "Dodge", label: "Dodge" },
    { value: "Douglas", label: "Douglas" },
    { value: "Faribault", label: "Faribault" },
    { value: "Fillmore", label: "Fillmore" },
    { value: "Freeborn", label: "Freeborn" },
    { value: "Goodhue", label: "Goodhue" },
    { value: "Grant", label: "Grant" },
    { value: "Hennepin", label: "Hennepin" },
    { value: "Houston", label: "Houston" },
    { value: "Hubbard", label: "Hubbard" },
    { value: "Isanti", label: "Isanti" },
    { value: "Itasca", label: "Itasca" },
    { value: "Jackson", label: "Jackson" },
    { value: "Kanabec", label: "Kanabec" },
    { value: "Kandiyohi", label: "Kandiyohi" },
    { value: "Kittson", label: "Kittson" },
    { value: "Koochiching", label: "Koochiching" },
    { value: "Lac qui Parle", label: "Lac qui Parle" },
    { value: "Lake", label: "Lake" },
    { value: "Lake of the Woods", label: "Lake of the Woods" },
    { value: "Le Sueur", label: "Le Sueur" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Lyon", label: "Lyon" },
    { value: "Mahnomen", label: "Mahnomen" },
    { value: "Marshall", label: "Marshall" },
    { value: "Martin", label: "Martin" },
    { value: "McLeod", label: "McLeod" },
    { value: "Meeker", label: "Meeker" },
    { value: "Mille Lacs", label: "Mille Lacs" },
    { value: "Morrison", label: "Morrison" },
    { value: "Mower", label: "Mower" },
    { value: "Murray", label: "Murray" },
    { value: "Nicollet", label: "Nicollet" },
    { value: "Nobles", label: "Nobles" },
    { value: "Norman", label: "Norman" },
    { value: "Olmsted", label: "Olmsted" },
    { value: "Otter Tail", label: "Otter Tail" },
    { value: "Pennington", label: "Pennington" },
    { value: "Pine", label: "Pine" },
    { value: "Pipestone", label: "Pipestone" },
    { value: "Polk", label: "Polk" },
    { value: "Pope", label: "Pope" },
    { value: "Ramsey", label: "Ramsey" },
    { value: "Red Lake", label: "Red Lake" },
    { value: "Redwood", label: "Redwood" },
    { value: "Renville", label: "Renville" },
    { value: "Rice", label: "Rice" },
    { value: "Rock", label: "Rock" },
    { value: "Roseau", label: "Roseau" },
    { value: "Scott", label: "Scott" },
    { value: "Sherburne", label: "Sherburne" },
    { value: "Sibley", label: "Sibley" },
    { value: "St. Louis", label: "St. Louis" },
    { value: "Stearns", label: "Stearns" },
    { value: "Steele", label: "Steele" },
    { value: "Stevens", label: "Stevens" },
    { value: "Swift", label: "Swift" },
    { value: "Todd", label: "Todd" },
    { value: "Traverse", label: "Traverse" },
    { value: "Wabasha", label: "Wabasha" },
    { value: "Wadena", label: "Wadena" },
    { value: "Waseca", label: "Waseca" },
    { value: "Washington", label: "Washington" },
    { value: "Watonwan", label: "Watonwan" },
    { value: "Wilkin", label: "Wilkin" },
    { value: "Winona", label: "Winona" },
    { value: "Wright", label: "Wright" },
    { value: "Yellow Medicine", label: "Yellow Medicine" },
  ],
  Mississippi: [
    { value: "Adams", label: "Adams" },
    { value: "Alcorn", label: "Alcorn" },
    { value: "Amite", label: "Amite" },
    { value: "Attala", label: "Attala" },
    { value: "Benton", label: "Benton" },
    { value: "Bolivar", label: "Bolivar" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Carroll", label: "Carroll" },
    { value: "Chickasaw", label: "Chickasaw" },
    { value: "Choctaw", label: "Choctaw" },
    { value: "Claiborne", label: "Claiborne" },
    { value: "Clarke", label: "Clarke" },
    { value: "Clay", label: "Clay" },
    { value: "Coahoma", label: "Coahoma" },
    { value: "Copiah", label: "Copiah" },
    { value: "Covington", label: "Covington" },
    { value: "DeSoto", label: "DeSoto" },
    { value: "Forrest", label: "Forrest" },
    { value: "Franklin", label: "Franklin" },
    { value: "George", label: "George" },
    { value: "Greene", label: "Greene" },
    { value: "Grenada", label: "Grenada" },
    { value: "Hancock", label: "Hancock" },
    { value: "Harrison", label: "Harrison" },
    { value: "Hinds", label: "Hinds" },
    { value: "Holmes", label: "Holmes" },
    { value: "Humphreys", label: "Humphreys" },
    { value: "Issaquena", label: "Issaquena" },
    { value: "Itawamba", label: "Itawamba" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jefferson Davis", label: "Jefferson Davis" },
    { value: "Jones", label: "Jones" },
    { value: "Kemper", label: "Kemper" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Lamar", label: "Lamar" },
    { value: "Lauderdale", label: "Lauderdale" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Leake", label: "Leake" },
    { value: "Lee", label: "Lee" },
    { value: "Leflore", label: "Leflore" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Lowndes", label: "Lowndes" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Neshoba", label: "Neshoba" },
    { value: "Newton", label: "Newton" },
    { value: "Noxubee", label: "Noxubee" },
    { value: "Oktibbeha", label: "Oktibbeha" },
    { value: "Panola", label: "Panola" },
    { value: "Pearl River", label: "Pearl River" },
    { value: "Perry", label: "Perry" },
    { value: "Pike", label: "Pike" },
    { value: "Pontotoc", label: "Pontotoc" },
    { value: "Prentiss", label: "Prentiss" },
    { value: "Quitman", label: "Quitman" },
    { value: "Rankin", label: "Rankin" },
    { value: "Scott", label: "Scott" },
    { value: "Sharkey", label: "Sharkey" },
    { value: "Simpson", label: "Simpson" },
    { value: "Smith", label: "Smith" },
    { value: "Stone", label: "Stone" },
    { value: "Sunflower", label: "Sunflower" },
    { value: "Tallahatchie", label: "Tallahatchie" },
    { value: "Tate", label: "Tate" },
    { value: "Tippah", label: "Tippah" },
    { value: "Tishomingo", label: "Tishomingo" },
    { value: "Tunica", label: "Tunica" },
    { value: "Union", label: "Union" },
    { value: "Walthall", label: "Walthall" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Wilkinson", label: "Wilkinson" },
    { value: "Winston", label: "Winston" },
    { value: "Yalobusha", label: "Yalobusha" },
    { value: "Yazoo", label: "Yazoo" },
  ],
  Missouri: [
    { value: "Adair", label: "Adair" },
    { value: "Andrew", label: "Andrew" },
    { value: "Atchison", label: "Atchison" },
    { value: "Audrain", label: "Audrain" },
    { value: "Barry", label: "Barry" },
    { value: "Barton", label: "Barton" },
    { value: "Bates", label: "Bates" },
    { value: "Benton", label: "Benton" },
    { value: "Bollinger", label: "Bollinger" },
    { value: "Boone", label: "Boone" },
    { value: "Buchanan", label: "Buchanan" },
    { value: "Butler", label: "Butler" },
    { value: "Caldwell", label: "Caldwell" },
    { value: "Callaway", label: "Callaway" },
    { value: "Camden", label: "Camden" },
    { value: "Cape Girardeau", label: "Cape Girardeau" },
    { value: "Carroll", label: "Carroll" },
    { value: "Carter", label: "Carter" },
    { value: "Cass", label: "Cass" },
    { value: "Cedar", label: "Cedar" },
    { value: "Chariton", label: "Chariton" },
    { value: "Christian", label: "Christian" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Clinton", label: "Clinton" },
    { value: "Cole", label: "Cole" },
    { value: "Cooper", label: "Cooper" },
    { value: "Crawford", label: "Crawford" },
    { value: "Dade", label: "Dade" },
    { value: "Dallas", label: "Dallas" },
    { value: "Daviess", label: "Daviess" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Dent", label: "Dent" },
    { value: "Douglas", label: "Douglas" },
    { value: "Dunklin", label: "Dunklin" },
    { value: "Franklin", label: "Franklin" },
    { value: "Gasconade", label: "Gasconade" },
    { value: "Gentry", label: "Gentry" },
    { value: "Greene", label: "Greene" },
    { value: "Grundy", label: "Grundy" },
    { value: "Harrison", label: "Harrison" },
    { value: "Henry", label: "Henry" },
    { value: "Hickory", label: "Hickory" },
    { value: "Holt", label: "Holt" },
    { value: "Howard", label: "Howard" },
    { value: "Howell", label: "Howell" },
    { value: "Iron", label: "Iron" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnson", label: "Johnson" },
    { value: "Knox", label: "Knox" },
    { value: "Laclede", label: "Laclede" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Linn", label: "Linn" },
    { value: "Livingston", label: "Livingston" },
    { value: "Macon", label: "Macon" },
    { value: "Madison", label: "Madison" },
    { value: "Maries", label: "Maries" },
    { value: "Marion", label: "Marion" },
    { value: "McDonald", label: "McDonald" },
    { value: "Mercer", label: "Mercer" },
    { value: "Miller", label: "Miller" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Moniteau", label: "Moniteau" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "New Madrid", label: "New Madrid" },
    { value: "Newton", label: "Newton" },
    { value: "Nodaway", label: "Nodaway" },
    { value: "Oregon", label: "Oregon" },
    { value: "Osage", label: "Osage" },
    { value: "Ozark", label: "Ozark" },
    { value: "Pemiscot", label: "Pemiscot" },
    { value: "Perry", label: "Perry" },
    { value: "Pettis", label: "Pettis" },
    { value: "Phelps", label: "Phelps" },
    { value: "Pike", label: "Pike" },
    { value: "Platte", label: "Platte" },
    { value: "Polk", label: "Polk" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Putnam", label: "Putnam" },
    { value: "Ralls", label: "Ralls" },
    { value: "Randolph", label: "Randolph" },
    { value: "Ray", label: "Ray" },
    { value: "Reynolds", label: "Reynolds" },
    { value: "Ripley", label: "Ripley" },
    { value: "Saline", label: "Saline" },
    { value: "Schuyler", label: "Schuyler" },
    { value: "Scotland", label: "Scotland" },
    { value: "Scott", label: "Scott" },
    { value: "Shannon", label: "Shannon" },
    { value: "Shelby", label: "Shelby" },
    { value: "St. Charles", label: "St. Charles" },
    { value: "St. Clair", label: "St. Clair" },
    { value: "St. Francois", label: "St. Francois" },
    { value: "St. Louis City", label: "St. Louis City" },
    { value: "St. Louis County", label: "St. Louis County" },
    { value: "Ste. Genevieve", label: "Ste. Genevieve" },
    { value: "Stoddard", label: "Stoddard" },
    { value: "Stone", label: "Stone" },
    { value: "Sullivan", label: "Sullivan" },
    { value: "Taney", label: "Taney" },
    { value: "Texas", label: "Texas" },
    { value: "Vernon", label: "Vernon" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Worth", label: "Worth" },
    { value: "Wright", label: "Wright" },
  ],
  Montana: [
    { value: "Beaverhead", label: "Beaverhead" },
    { value: "Big Horn", label: "Big Horn" },
    { value: "Blaine", label: "Blaine" },
    { value: "Broadwater", label: "Broadwater" },
    { value: "Carbon", label: "Carbon" },
    { value: "Carter", label: "Carter" },
    { value: "Cascade", label: "Cascade" },
    { value: "Chouteau", label: "Chouteau" },
    { value: "Custer", label: "Custer" },
    { value: "Daniels", label: "Daniels" },
    { value: "Dawson", label: "Dawson" },
    { value: "Deer Lodge", label: "Deer Lodge" },
    { value: "Fallon", label: "Fallon" },
    { value: "Fergus", label: "Fergus" },
    { value: "Flathead", label: "Flathead" },
    { value: "Gallatin", label: "Gallatin" },
    { value: "Garfield", label: "Garfield" },
    { value: "Glacier", label: "Glacier" },
    { value: "Golden Valley", label: "Golden Valley" },
    { value: "Granite", label: "Granite" },
    { value: "Hill", label: "Hill" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Judith Basin", label: "Judith Basin" },
    { value: "Lake", label: "Lake" },
    { value: "Lewis and Clark", label: "Lewis and Clark" },
    { value: "Liberty", label: "Liberty" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Madison", label: "Madison" },
    { value: "McCone", label: "McCone" },
    { value: "Meagher", label: "Meagher" },
    { value: "Mineral", label: "Mineral" },
    { value: "Missoula", label: "Missoula" },
    { value: "Musselshell", label: "Musselshell" },
    { value: "Park", label: "Park" },
    { value: "Petroleum", label: "Petroleum" },
    { value: "Phillips", label: "Phillips" },
    { value: "Pondera", label: "Pondera" },
    { value: "Powder River", label: "Powder River" },
    { value: "Powell", label: "Powell" },
    { value: "Prairie", label: "Prairie" },
    { value: "Ravalli", label: "Ravalli" },
    { value: "Richland", label: "Richland" },
    { value: "Roosevelt", label: "Roosevelt" },
    { value: "Rosebud", label: "Rosebud" },
    { value: "Sanders", label: "Sanders" },
    { value: "Sheridan", label: "Sheridan" },
    { value: "Silver Bow", label: "Silver Bow" },
    { value: "Stillwater", label: "Stillwater" },
    { value: "Sweet Grass", label: "Sweet Grass" },
    { value: "Teton", label: "Teton" },
    { value: "Toole", label: "Toole" },
    { value: "Treasure", label: "Treasure" },
    { value: "Valley", label: "Valley" },
    { value: "Wheatland", label: "Wheatland" },
    { value: "Wibaux", label: "Wibaux" },
    { value: "Yellowstone", label: "Yellowstone" },
  ],
  Nebraska: [
    { value: "Adams", label: "Adams" },
    { value: "Antelope", label: "Antelope" },
    { value: "Arthur", label: "Arthur" },
    { value: "Banner", label: "Banner" },
    { value: "Blaine", label: "Blaine" },
    { value: "Boone", label: "Boone" },
    { value: "Box Butte", label: "Box Butte" },
    { value: "Boyd", label: "Boyd" },
    { value: "Brown", label: "Brown" },
    { value: "Buffalo", label: "Buffalo" },
    { value: "Burt", label: "Burt" },
    { value: "Butler", label: "Butler" },
    { value: "Cass", label: "Cass" },
    { value: "Cedar", label: "Cedar" },
    { value: "Chase", label: "Chase" },
    { value: "Cherry", label: "Cherry" },
    { value: "Cheyenne", label: "Cheyenne" },
    { value: "Clay", label: "Clay" },
    { value: "Colfax", label: "Colfax" },
    { value: "Cuming", label: "Cuming" },
    { value: "Custer", label: "Custer" },
    { value: "Dakota", label: "Dakota" },
    { value: "Dawes", label: "Dawes" },
    { value: "Dawson", label: "Dawson" },
    { value: "Deuel", label: "Deuel" },
    { value: "Dixon", label: "Dixon" },
    { value: "Dodge", label: "Dodge" },
    { value: "Douglas", label: "Douglas" },
    { value: "Dundy", label: "Dundy" },
    { value: "Fillmore", label: "Fillmore" },
    { value: "Franklin", label: "Franklin" },
    { value: "Frontier", label: "Frontier" },
    { value: "Furnas", label: "Furnas" },
    { value: "Gage", label: "Gage" },
    { value: "Garden", label: "Garden" },
    { value: "Garfield", label: "Garfield" },
    { value: "Gosper", label: "Gosper" },
    { value: "Grant", label: "Grant" },
    { value: "Greeley", label: "Greeley" },
    { value: "Hall", label: "Hall" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Harlan", label: "Harlan" },
    { value: "Hayes", label: "Hayes" },
    { value: "Hitchcock", label: "Hitchcock" },
    { value: "Holt", label: "Holt" },
    { value: "Hooker", label: "Hooker" },
    { value: "Howard", label: "Howard" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnson", label: "Johnson" },
    { value: "Kearney", label: "Kearney" },
    { value: "Keith", label: "Keith" },
    { value: "Keya Paha", label: "Keya Paha" },
    { value: "Kimball", label: "Kimball" },
    { value: "Knox", label: "Knox" },
    { value: "Lancaster", label: "Lancaster" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Logan", label: "Logan" },
    { value: "Loup", label: "Loup" },
    { value: "Madison", label: "Madison" },
    { value: "McPherson", label: "McPherson" },
    { value: "Merrick", label: "Merrick" },
    { value: "Morrill", label: "Morrill" },
    { value: "Nance", label: "Nance" },
    { value: "Nemaha", label: "Nemaha" },
    { value: "Nuckolls", label: "Nuckolls" },
    { value: "Otoe", label: "Otoe" },
    { value: "Pawnee", label: "Pawnee" },
    { value: "Perkins", label: "Perkins" },
    { value: "Phelps", label: "Phelps" },
    { value: "Pierce", label: "Pierce" },
    { value: "Platte", label: "Platte" },
    { value: "Polk", label: "Polk" },
    { value: "Red Willow", label: "Red Willow" },
    { value: "Richardson", label: "Richardson" },
    { value: "Rock", label: "Rock" },
    { value: "Saline", label: "Saline" },
    { value: "Sarpy", label: "Sarpy" },
    { value: "Saunders", label: "Saunders" },
    { value: "Scotts Bluff", label: "Scotts Bluff" },
    { value: "Seward", label: "Seward" },
    { value: "Sheridan", label: "Sheridan" },
    { value: "Sherman", label: "Sherman" },
    { value: "Sioux", label: "Sioux" },
    { value: "Stanton", label: "Stanton" },
    { value: "Thayer", label: "Thayer" },
    { value: "Thomas", label: "Thomas" },
    { value: "Thurston", label: "Thurston" },
    { value: "Valley", label: "Valley" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Wheeler", label: "Wheeler" },
    { value: "York", label: "York" },
  ],
  Nevada: [
    { value: "Carson City", label: "Carson City" },
    { value: "Churchill", label: "Churchill" },
    { value: "Clark", label: "Clark" },
    { value: "Douglas", label: "Douglas" },
    { value: "Elko", label: "Elko" },
    { value: "Esmeralda", label: "Esmeralda" },
    { value: "Eureka", label: "Eureka" },
    { value: "Humboldt", label: "Humboldt" },
    { value: "Lander", label: "Lander" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Lyon", label: "Lyon" },
    { value: "Mineral", label: "Mineral" },
    { value: "Nye", label: "Nye" },
    { value: "Pershing", label: "Pershing" },
    { value: "Storey", label: "Storey" },
    { value: "Washoe", label: "Washoe" },
    { value: "White Pine", label: "White Pine" },
  ],
  "New Hampshire": [
    { value: "Belknap", label: "Belknap" },
    { value: "Carroll", label: "Carroll" },
    { value: "Cheshire", label: "Cheshire" },
    { value: "Coos", label: "Coos" },
    { value: "Grafton", label: "Grafton" },
    { value: "Hillsborough", label: "Hillsborough" },
    { value: "Merrimack", label: "Merrimack" },
    { value: "Rockingham", label: "Rockingham" },
    { value: "Strafford", label: "Strafford" },
    { value: "Sullivan", label: "Sullivan" },
  ],
  "New Jersey": [
    { value: "Atlantic", label: "Atlantic" },
    { value: "Bergen", label: "Bergen" },
    { value: "Burlington", label: "Burlington" },
    { value: "Camden", label: "Camden" },
    { value: "Cape May", label: "Cape May" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Essex", label: "Essex" },
    { value: "Gloucester", label: "Gloucester" },
    { value: "Hudson", label: "Hudson" },
    { value: "Hunterdon", label: "Hunterdon" },
    { value: "Mercer", label: "Mercer" },
    { value: "Middlesex", label: "Middlesex" },
    { value: "Monmouth", label: "Monmouth" },
    { value: "Morris", label: "Morris" },
    { value: "Ocean", label: "Ocean" },
    { value: "Passaic", label: "Passaic" },
    { value: "Salem", label: "Salem" },
    { value: "Somerset", label: "Somerset" },
    { value: "Sussex", label: "Sussex" },
    { value: "Union", label: "Union" },
    { value: "Warren", label: "Warren" },
  ],
  "New Mexico": [
    { value: "Bernalillo", label: "Bernalillo" },
    { value: "Catron", label: "Catron" },
    { value: "Chaves", label: "Chaves" },
    { value: "Cibola", label: "Cibola" },
    { value: "Colfax", label: "Colfax" },
    { value: "Curry", label: "Curry" },
    { value: "De Baca", label: "De Baca" },
    { value: "Dona Ana", label: "Dona Ana" },
    { value: "Eddy", label: "Eddy" },
    { value: "Grant", label: "Grant" },
    { value: "Guadalupe", label: "Guadalupe" },
    { value: "Harding", label: "Harding" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Lea", label: "Lea" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Los Alamos", label: "Los Alamos" },
    { value: "Luna", label: "Luna" },
    { value: "McKinley", label: "McKinley" },
    { value: "Mora", label: "Mora" },
    { value: "Otero", label: "Otero" },
    { value: "Quay", label: "Quay" },
    { value: "Rio Arriba", label: "Rio Arriba" },
    { value: "Roosevelt", label: "Roosevelt" },
    { value: "San Juan", label: "San Juan" },
    { value: "San Miguel", label: "San Miguel" },
    { value: "Sandoval", label: "Sandoval" },
    { value: "Santa Fe", label: "Santa Fe" },
    { value: "Sierra", label: "Sierra" },
    { value: "Socorro", label: "Socorro" },
    { value: "Taos", label: "Taos" },
    { value: "Torrance", label: "Torrance" },
    { value: "Union", label: "Union" },
    { value: "Valencia", label: "Valencia" },
  ],
  "New York": [
    { value: "Albany", label: "Albany" },
    { value: "Allegany", label: "Allegany" },
    { value: "Bronx", label: "Bronx" },
    { value: "Broome", label: "Broome" },
    { value: "Cattaraugus", label: "Cattaraugus" },
    { value: "Cayuga", label: "Cayuga" },
    { value: "Chautauqua", label: "Chautauqua" },
    { value: "Chemung", label: "Chemung" },
    { value: "Chenango", label: "Chenango" },
    { value: "Clinton", label: "Clinton" },
    { value: "Columbia", label: "Columbia" },
    { value: "Cortland", label: "Cortland" },
    { value: "Delaware", label: "Delaware" },
    { value: "Dutchess", label: "Dutchess" },
    { value: "Erie", label: "Erie" },
    { value: "Essex", label: "Essex" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Genesee", label: "Genesee" },
    { value: "Greene", label: "Greene" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Herkimer", label: "Herkimer" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Kings", label: "Kings" },
    { value: "Lewis", label: "Lewis" },
    { value: "Livingston", label: "Livingston" },
    { value: "Madison", label: "Madison" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Nassau", label: "Nassau" },
    { value: "New York", label: "New York" },
    { value: "Niagara", label: "Niagara" },
    { value: "Oneida", label: "Oneida" },
    { value: "Onondaga", label: "Onondaga" },
    { value: "Ontario", label: "Ontario" },
    { value: "Orange", label: "Orange" },
    { value: "Orleans", label: "Orleans" },
    { value: "Oswego", label: "Oswego" },
    { value: "Otsego", label: "Otsego" },
    { value: "Putnam", label: "Putnam" },
    { value: "Queens", label: "Queens" },
    { value: "Rensselaer", label: "Rensselaer" },
    { value: "Richmond", label: "Richmond" },
    { value: "Rockland", label: "Rockland" },
    { value: "Saratoga", label: "Saratoga" },
    { value: "Schenectady", label: "Schenectady" },
    { value: "Schoharie", label: "Schoharie" },
    { value: "Schuyler", label: "Schuyler" },
    { value: "Seneca", label: "Seneca" },
    { value: "St. Lawrence", label: "St. Lawrence" },
    { value: "Steuben", label: "Steuben" },
    { value: "Suffolk", label: "Suffolk" },
    { value: "Sullivan", label: "Sullivan" },
    { value: "Tioga", label: "Tioga" },
    { value: "Tompkins", label: "Tompkins" },
    { value: "Ulster", label: "Ulster" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Westchester", label: "Westchester" },
    { value: "Wyoming", label: "Wyoming" },
    { value: "Yates", label: "Yates" },
  ],
  "North Carolina": [
    { value: "Alamance", label: "Alamance" },
    { value: "Alexander", label: "Alexander" },
    { value: "Alleghany", label: "Alleghany" },
    { value: "Anson", label: "Anson" },
    { value: "Ashe", label: "Ashe" },
    { value: "Avery", label: "Avery" },
    { value: "Beaufort", label: "Beaufort" },
    { value: "Bertie", label: "Bertie" },
    { value: "Bladen", label: "Bladen" },
    { value: "Brunswick", label: "Brunswick" },
    { value: "Buncombe", label: "Buncombe" },
    { value: "Burke", label: "Burke" },
    { value: "Cabarrus", label: "Cabarrus" },
    { value: "Caldwell", label: "Caldwell" },
    { value: "Camden", label: "Camden" },
    { value: "Carteret", label: "Carteret" },
    { value: "Caswell", label: "Caswell" },
    { value: "Catawba", label: "Catawba" },
    { value: "Chatham", label: "Chatham" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Chowan", label: "Chowan" },
    { value: "Clay", label: "Clay" },
    { value: "Cleveland", label: "Cleveland" },
    { value: "Columbus", label: "Columbus" },
    { value: "Craven", label: "Craven" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Currituck", label: "Currituck" },
    { value: "Dare", label: "Dare" },
    { value: "Davidson", label: "Davidson" },
    { value: "Davie", label: "Davie" },
    { value: "Duplin", label: "Duplin" },
    { value: "Durham", label: "Durham" },
    { value: "Edgecombe", label: "Edgecombe" },
    { value: "Forsyth", label: "Forsyth" },
    { value: "Franklin", label: "Franklin" },
    { value: "Gaston", label: "Gaston" },
    { value: "Gates", label: "Gates" },
    { value: "Graham", label: "Graham" },
    { value: "Granville", label: "Granville" },
    { value: "Greene", label: "Greene" },
    { value: "Guilford", label: "Guilford" },
    { value: "Halifax", label: "Halifax" },
    { value: "Harnett", label: "Harnett" },
    { value: "Haywood", label: "Haywood" },
    { value: "Henderson", label: "Henderson" },
    { value: "Hertford", label: "Hertford" },
    { value: "Hoke", label: "Hoke" },
    { value: "Hyde", label: "Hyde" },
    { value: "Iredell", label: "Iredell" },
    { value: "Jackson", label: "Jackson" },
    { value: "Johnston", label: "Johnston" },
    { value: "Jones", label: "Jones" },
    { value: "Lee", label: "Lee" },
    { value: "Lenoir", label: "Lenoir" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Macon", label: "Macon" },
    { value: "Madison", label: "Madison" },
    { value: "Martin", label: "Martin" },
    { value: "McDowell", label: "McDowell" },
    { value: "Mecklenburg", label: "Mecklenburg" },
    { value: "Mitchell", label: "Mitchell" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Moore", label: "Moore" },
    { value: "Nash", label: "Nash" },
    { value: "New Hanover", label: "New Hanover" },
    { value: "Northampton", label: "Northampton" },
    { value: "Onslow", label: "Onslow" },
    { value: "Orange", label: "Orange" },
    { value: "Pamlico", label: "Pamlico" },
    { value: "Pasquotank", label: "Pasquotank" },
    { value: "Pender", label: "Pender" },
    { value: "Perquimans", label: "Perquimans" },
    { value: "Person", label: "Person" },
    { value: "Pitt", label: "Pitt" },
    { value: "Polk", label: "Polk" },
    { value: "Randolph", label: "Randolph" },
    { value: "Richmond", label: "Richmond" },
    { value: "Robeson", label: "Robeson" },
    { value: "Rockingham", label: "Rockingham" },
    { value: "Rowan", label: "Rowan" },
    { value: "Rutherford", label: "Rutherford" },
    { value: "Sampson", label: "Sampson" },
    { value: "Scotland", label: "Scotland" },
    { value: "Stanly", label: "Stanly" },
    { value: "Stokes", label: "Stokes" },
    { value: "Surry", label: "Surry" },
    { value: "Swain", label: "Swain" },
    { value: "Transylvania", label: "Transylvania" },
    { value: "Tyrrell", label: "Tyrrell" },
    { value: "Union", label: "Union" },
    { value: "Vance", label: "Vance" },
    { value: "Wake", label: "Wake" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Watauga", label: "Watauga" },
    { value: "Wayne", label: "Wayne" },
    { value: "Wilkes", label: "Wilkes" },
    { value: "Wilson", label: "Wilson" },
    { value: "Yadkin", label: "Yadkin" },
    { value: "Yancey", label: "Yancey" },
  ],
  "North Dakota": [
    { value: "Adams", label: "Adams" },
    { value: "Barnes", label: "Barnes" },
    { value: "Benson", label: "Benson" },
    { value: "Billings", label: "Billings" },
    { value: "Bottineau", label: "Bottineau" },
    { value: "Bowman", label: "Bowman" },
    { value: "Burke", label: "Burke" },
    { value: "Burleigh", label: "Burleigh" },
    { value: "Cass", label: "Cass" },
    { value: "Cavalier", label: "Cavalier" },
    { value: "Dickey", label: "Dickey" },
    { value: "Divide", label: "Divide" },
    { value: "Dunn", label: "Dunn" },
    { value: "Eddy", label: "Eddy" },
    { value: "Emmons", label: "Emmons" },
    { value: "Foster", label: "Foster" },
    { value: "Golden Valley", label: "Golden Valley" },
    { value: "Grand Forks", label: "Grand Forks" },
    { value: "Grant", label: "Grant" },
    { value: "Griggs", label: "Griggs" },
    { value: "Hettinger", label: "Hettinger" },
    { value: "Kidder", label: "Kidder" },
    { value: "LaMoure", label: "LaMoure" },
    { value: "Logan", label: "Logan" },
    { value: "McHenry", label: "McHenry" },
    { value: "McIntosh", label: "McIntosh" },
    { value: "McKenzie", label: "McKenzie" },
    { value: "McLean", label: "McLean" },
    { value: "Mercer", label: "Mercer" },
    { value: "Morton", label: "Morton" },
    { value: "Mountrail", label: "Mountrail" },
    { value: "Nelson", label: "Nelson" },
    { value: "Oliver", label: "Oliver" },
    { value: "Pembina", label: "Pembina" },
    { value: "Pierce", label: "Pierce" },
    { value: "Ramsey", label: "Ramsey" },
    { value: "Ransom", label: "Ransom" },
    { value: "Renville", label: "Renville" },
    { value: "Richland", label: "Richland" },
    { value: "Rolette", label: "Rolette" },
    { value: "Sargent", label: "Sargent" },
    { value: "Sheridan", label: "Sheridan" },
    { value: "Sioux", label: "Sioux" },
    { value: "Slope", label: "Slope" },
    { value: "Stark", label: "Stark" },
    { value: "Steele", label: "Steele" },
    { value: "Stutsman", label: "Stutsman" },
    { value: "Towner", label: "Towner" },
    { value: "Traill", label: "Traill" },
    { value: "Walsh", label: "Walsh" },
    { value: "Ward", label: "Ward" },
    { value: "Wells", label: "Wells" },
    { value: "Williams", label: "Williams" },
  ],
  Ohio: [
    { value: "Adams", label: "Adams" },
    { value: "Allen", label: "Allen" },
    { value: "Ashland", label: "Ashland" },
    { value: "Ashtabula", label: "Ashtabula" },
    { value: "Athens", label: "Athens" },
    { value: "Auglaize", label: "Auglaize" },
    { value: "Belmont", label: "Belmont" },
    { value: "Brown", label: "Brown" },
    { value: "Butler", label: "Butler" },
    { value: "Carroll", label: "Carroll" },
    { value: "Champaign", label: "Champaign" },
    { value: "Clark", label: "Clark" },
    { value: "Clermont", label: "Clermont" },
    { value: "Clinton", label: "Clinton" },
    { value: "Columbiana", label: "Columbiana" },
    { value: "Coshocton", label: "Coshocton" },
    { value: "Crawford", label: "Crawford" },
    { value: "Cuyahoga", label: "Cuyahoga" },
    { value: "Darke", label: "Darke" },
    { value: "Defiance", label: "Defiance" },
    { value: "Delaware", label: "Delaware" },
    { value: "Erie", label: "Erie" },
    { value: "Fairfield", label: "Fairfield" },
    { value: "Fayette", label: "Fayette" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Gallia", label: "Gallia" },
    { value: "Geauga", label: "Geauga" },
    { value: "Greene", label: "Greene" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardin", label: "Hardin" },
    { value: "Harrison", label: "Harrison" },
    { value: "Henry", label: "Henry" },
    { value: "Highland", label: "Highland" },
    { value: "Hocking", label: "Hocking" },
    { value: "Holmes", label: "Holmes" },
    { value: "Huron", label: "Huron" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Knox", label: "Knox" },
    { value: "Lake", label: "Lake" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Licking", label: "Licking" },
    { value: "Logan", label: "Logan" },
    { value: "Lorain", label: "Lorain" },
    { value: "Lucas", label: "Lucas" },
    { value: "Madison", label: "Madison" },
    { value: "Mahoning", label: "Mahoning" },
    { value: "Marion", label: "Marion" },
    { value: "Medina", label: "Medina" },
    { value: "Meigs", label: "Meigs" },
    { value: "Mercer", label: "Mercer" },
    { value: "Miami", label: "Miami" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Morgan", label: "Morgan" },
    { value: "Morrow", label: "Morrow" },
    { value: "Muskingum", label: "Muskingum" },
    { value: "Noble", label: "Noble" },
    { value: "Ottawa", label: "Ottawa" },
    { value: "Paulding", label: "Paulding" },
    { value: "Perry", label: "Perry" },
    { value: "Pickaway", label: "Pickaway" },
    { value: "Pike", label: "Pike" },
    { value: "Portage", label: "Portage" },
    { value: "Preble", label: "Preble" },
    { value: "Putnam", label: "Putnam" },
    { value: "Richland", label: "Richland" },
    { value: "Ross", label: "Ross" },
    { value: "Sandusky", label: "Sandusky" },
    { value: "Scioto", label: "Scioto" },
    { value: "Seneca", label: "Seneca" },
    { value: "Shelby", label: "Shelby" },
    { value: "Stark", label: "Stark" },
    { value: "Summit", label: "Summit" },
    { value: "Trumbull", label: "Trumbull" },
    { value: "Tuscarawas", label: "Tuscarawas" },
    { value: "Union", label: "Union" },
    { value: "Van Wert", label: "Van Wert" },
    { value: "Vinton", label: "Vinton" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Williams", label: "Williams" },
    { value: "Wood", label: "Wood" },
    { value: "Wyandot", label: "Wyandot" },
  ],
  Oklahoma: [
    { value: "Adair", label: "Adair" },
    { value: "Alfalfa", label: "Alfalfa" },
    { value: "Atoka", label: "Atoka" },
    { value: "Beaver", label: "Beaver" },
    { value: "Beckham", label: "Beckham" },
    { value: "Blaine", label: "Blaine" },
    { value: "Bryan", label: "Bryan" },
    { value: "Caddo", label: "Caddo" },
    { value: "Canadian", label: "Canadian" },
    { value: "Carter", label: "Carter" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Choctaw", label: "Choctaw" },
    { value: "Cimarron", label: "Cimarron" },
    { value: "Cleveland", label: "Cleveland" },
    { value: "Coal", label: "Coal" },
    { value: "Comanche", label: "Comanche" },
    { value: "Cotton", label: "Cotton" },
    { value: "Craig", label: "Craig" },
    { value: "Creek", label: "Creek" },
    { value: "Custer", label: "Custer" },
    { value: "Delaware", label: "Delaware" },
    { value: "Dewey", label: "Dewey" },
    { value: "Ellis", label: "Ellis" },
    { value: "Garfield", label: "Garfield" },
    { value: "Garvin", label: "Garvin" },
    { value: "Grady", label: "Grady" },
    { value: "Grant", label: "Grant" },
    { value: "Greer", label: "Greer" },
    { value: "Harmon", label: "Harmon" },
    { value: "Harper", label: "Harper" },
    { value: "Haskell", label: "Haskell" },
    { value: "Hughes", label: "Hughes" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnston", label: "Johnston" },
    { value: "Kay", label: "Kay" },
    { value: "Kingfisher", label: "Kingfisher" },
    { value: "Kiowa", label: "Kiowa" },
    { value: "Latimer", label: "Latimer" },
    { value: "Le Flore", label: "Le Flore" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Logan", label: "Logan" },
    { value: "Love", label: "Love" },
    { value: "Major", label: "Major" },
    { value: "Marshall", label: "Marshall" },
    { value: "Mayes", label: "Mayes" },
    { value: "McClain", label: "McClain" },
    { value: "McCurtain", label: "McCurtain" },
    { value: "McIntosh", label: "McIntosh" },
    { value: "Murray", label: "Murray" },
    { value: "Muskogee", label: "Muskogee" },
    { value: "Noble", label: "Noble" },
    { value: "Nowata", label: "Nowata" },
    { value: "Okfuskee", label: "Okfuskee" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Okmulgee", label: "Okmulgee" },
    { value: "Osage", label: "Osage" },
    { value: "Ottawa", label: "Ottawa" },
    { value: "Pawnee", label: "Pawnee" },
    { value: "Payne", label: "Payne" },
    { value: "Pittsburg", label: "Pittsburg" },
    { value: "Pontotoc", label: "Pontotoc" },
    { value: "Pottawatomie", label: "Pottawatomie" },
    { value: "Pushmataha", label: "Pushmataha" },
    { value: "Roger Mills", label: "Roger Mills" },
    { value: "Rogers", label: "Rogers" },
    { value: "Seminole", label: "Seminole" },
    { value: "Sequoyah", label: "Sequoyah" },
    { value: "Stephens", label: "Stephens" },
    { value: "Texas", label: "Texas" },
    { value: "Tillman", label: "Tillman" },
    { value: "Tulsa", label: "Tulsa" },
    { value: "Wagoner", label: "Wagoner" },
    { value: "Washington", label: "Washington" },
    { value: "Washita", label: "Washita" },
    { value: "Woods", label: "Woods" },
    { value: "Woodward", label: "Woodward" },
  ],
  Oregon: [
    { value: "Baker", label: "Baker" },
    { value: "Benton", label: "Benton" },
    { value: "Clackamas", label: "Clackamas" },
    { value: "Clatsop", label: "Clatsop" },
    { value: "Columbia", label: "Columbia" },
    { value: "Coos", label: "Coos" },
    { value: "Crook", label: "Crook" },
    { value: "Curry", label: "Curry" },
    { value: "Deschutes", label: "Deschutes" },
    { value: "Douglas", label: "Douglas" },
    { value: "Gilliam", label: "Gilliam" },
    { value: "Grant", label: "Grant" },
    { value: "Harney", label: "Harney" },
    { value: "Hood River", label: "Hood River" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Josephine", label: "Josephine" },
    { value: "Klamath", label: "Klamath" },
    { value: "Lake", label: "Lake" },
    { value: "Lane", label: "Lane" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Linn", label: "Linn" },
    { value: "Malheur", label: "Malheur" },
    { value: "Marion", label: "Marion" },
    { value: "Morrow", label: "Morrow" },
    { value: "Multnomah", label: "Multnomah" },
    { value: "Polk", label: "Polk" },
    { value: "Sherman", label: "Sherman" },
    { value: "Tillamook", label: "Tillamook" },
    { value: "Umatilla", label: "Umatilla" },
    { value: "Union", label: "Union" },
    { value: "Wallowa", label: "Wallowa" },
    { value: "Wasco", label: "Wasco" },
    { value: "Washington", label: "Washington" },
    { value: "Wheeler", label: "Wheeler" },
    { value: "Yamhill", label: "Yamhill" },
  ],
  Pennsylvania: [
    { value: "Adams", label: "Adams" },
    { value: "Allegheny", label: "Allegheny" },
    { value: "Armstrong", label: "Armstrong" },
    { value: "Beaver", label: "Beaver" },
    { value: "Bedford", label: "Bedford" },
    { value: "Berks", label: "Berks" },
    { value: "Blair", label: "Blair" },
    { value: "Bradford", label: "Bradford" },
    { value: "Bucks", label: "Bucks" },
    { value: "Butler", label: "Butler" },
    { value: "Cambria", label: "Cambria" },
    { value: "Cameron", label: "Cameron" },
    { value: "Carbon", label: "Carbon" },
    { value: "Centre", label: "Centre" },
    { value: "Chester", label: "Chester" },
    { value: "Clarion", label: "Clarion" },
    { value: "Clearfield", label: "Clearfield" },
    { value: "Clinton", label: "Clinton" },
    { value: "Columbia", label: "Columbia" },
    { value: "Crawford", label: "Crawford" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Dauphin", label: "Dauphin" },
    { value: "Delaware", label: "Delaware" },
    { value: "Elk", label: "Elk" },
    { value: "Erie", label: "Erie" },
    { value: "Fayette", label: "Fayette" },
    { value: "Forest", label: "Forest" },
    { value: "Franklin", label: "Franklin" },
    { value: "Fulton", label: "Fulton" },
    { value: "Greene", label: "Greene" },
    { value: "Huntingdon", label: "Huntingdon" },
    { value: "Indiana", label: "Indiana" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Juniata", label: "Juniata" },
    { value: "Lackawanna", label: "Lackawanna" },
    { value: "Lancaster", label: "Lancaster" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lehigh", label: "Lehigh" },
    { value: "Luzerne", label: "Luzerne" },
    { value: "Lycoming", label: "Lycoming" },
    { value: "McKean", label: "McKean" },
    { value: "Mercer", label: "Mercer" },
    { value: "Mifflin", label: "Mifflin" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Montour", label: "Montour" },
    { value: "Northampton", label: "Northampton" },
    { value: "Northumberland", label: "Northumberland" },
    { value: "Perry", label: "Perry" },
    { value: "Philadelphia", label: "Philadelphia" },
    { value: "Pike", label: "Pike" },
    { value: "Potter", label: "Potter" },
    { value: "Schuylkill", label: "Schuylkill" },
    { value: "Snyder", label: "Snyder" },
    { value: "Somerset", label: "Somerset" },
    { value: "Sullivan", label: "Sullivan" },
    { value: "Susquehanna", label: "Susquehanna" },
    { value: "Tioga", label: "Tioga" },
    { value: "Union", label: "Union" },
    { value: "Venango", label: "Venango" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Westmoreland", label: "Westmoreland" },
    { value: "Wyoming", label: "Wyoming" },
    { value: "York", label: "York" },
  ],
  "Rhode Island": [
    { value: "Bristol", label: "Bristol" },
    { value: "Kent", label: "Kent" },
    { value: "Newport", label: "Newport" },
    { value: "Providence", label: "Providence" },
    { value: "Washington", label: "Washington" },
  ],
  "South Carolina": [
    { value: "Abbeville", label: "Abbeville" },
    { value: "Aiken", label: "Aiken" },
    { value: "Allendale", label: "Allendale" },
    { value: "Anderson", label: "Anderson" },
    { value: "Bamberg", label: "Bamberg" },
    { value: "Barnwell", label: "Barnwell" },
    { value: "Beaufort", label: "Beaufort" },
    { value: "Berkeley", label: "Berkeley" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Charleston", label: "Charleston" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Chester", label: "Chester" },
    { value: "Chesterfield", label: "Chesterfield" },
    { value: "Clarendon", label: "Clarendon" },
    { value: "Colleton", label: "Colleton" },
    { value: "Darlington", label: "Darlington" },
    { value: "Dillon", label: "Dillon" },
    { value: "Dorchester", label: "Dorchester" },
    { value: "Edgefield", label: "Edgefield" },
    { value: "Fairfield", label: "Fairfield" },
    { value: "Florence", label: "Florence" },
    { value: "Georgetown", label: "Georgetown" },
    { value: "Greenville", label: "Greenville" },
    { value: "Greenwood", label: "Greenwood" },
    { value: "Hampton", label: "Hampton" },
    { value: "Horry", label: "Horry" },
    { value: "Jasper", label: "Jasper" },
    { value: "Kershaw", label: "Kershaw" },
    { value: "Lancaster", label: "Lancaster" },
    { value: "Laurens", label: "Laurens" },
    { value: "Lee", label: "Lee" },
    { value: "Lexington", label: "Lexington" },
    { value: "Marion", label: "Marion" },
    { value: "Marlboro", label: "Marlboro" },
    { value: "McCormick", label: "McCormick" },
    { value: "Newberry", label: "Newberry" },
    { value: "Oconee", label: "Oconee" },
    { value: "Orangeburg", label: "Orangeburg" },
    { value: "Pickens", label: "Pickens" },
    { value: "Richland", label: "Richland" },
    { value: "Saluda", label: "Saluda" },
    { value: "Spartanburg", label: "Spartanburg" },
    { value: "Sumter", label: "Sumter" },
    { value: "Union", label: "Union" },
    { value: "Williamsburg", label: "Williamsburg" },
    { value: "York", label: "York" },
  ],
  "South Dakota": [
    { value: "Aurora", label: "Aurora" },
    { value: "Beadle", label: "Beadle" },
    { value: "Bennett", label: "Bennett" },
    { value: "Bon Homme", label: "Bon Homme" },
    { value: "Brookings", label: "Brookings" },
    { value: "Brown", label: "Brown" },
    { value: "Brule", label: "Brule" },
    { value: "Buffalo", label: "Buffalo" },
    { value: "Butte", label: "Butte" },
    { value: "Campbell", label: "Campbell" },
    { value: "Charles Mix", label: "Charles Mix" },
    { value: "Clark", label: "Clark" },
    { value: "Clay", label: "Clay" },
    { value: "Codington", label: "Codington" },
    { value: "Corson", label: "Corson" },
    { value: "Custer", label: "Custer" },
    { value: "Davison", label: "Davison" },
    { value: "Day", label: "Day" },
    { value: "Deuel", label: "Deuel" },
    { value: "Dewey", label: "Dewey" },
    { value: "Douglas", label: "Douglas" },
    { value: "Edmunds", label: "Edmunds" },
    { value: "Fall River", label: "Fall River" },
    { value: "Faulk", label: "Faulk" },
    { value: "Grant", label: "Grant" },
    { value: "Gregory", label: "Gregory" },
    { value: "Haakon", label: "Haakon" },
    { value: "Hamlin", label: "Hamlin" },
    { value: "Hand", label: "Hand" },
    { value: "Hanson", label: "Hanson" },
    { value: "Harding", label: "Harding" },
    { value: "Hughes", label: "Hughes" },
    { value: "Hutchinson", label: "Hutchinson" },
    { value: "Hyde", label: "Hyde" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jerauld", label: "Jerauld" },
    { value: "Jones", label: "Jones" },
    { value: "Kingsbury", label: "Kingsbury" },
    { value: "Lake", label: "Lake" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Lyman", label: "Lyman" },
    { value: "Marshall", label: "Marshall" },
    { value: "McCook", label: "McCook" },
    { value: "McPherson", label: "McPherson" },
    { value: "Meade", label: "Meade" },
    { value: "Mellette", label: "Mellette" },
    { value: "Miner", label: "Miner" },
    { value: "Minnehaha", label: "Minnehaha" },
    { value: "Moody", label: "Moody" },
    { value: "Oglala Lakota", label: "Oglala Lakota" },
    { value: "Pennington", label: "Pennington" },
    { value: "Perkins", label: "Perkins" },
    { value: "Potter", label: "Potter" },
    { value: "Roberts", label: "Roberts" },
    { value: "Sanborn", label: "Sanborn" },
    { value: "Spink", label: "Spink" },
    { value: "Stanley", label: "Stanley" },
    { value: "Sully", label: "Sully" },
    { value: "Todd", label: "Todd" },
    { value: "Tripp", label: "Tripp" },
    { value: "Turner", label: "Turner" },
    { value: "Union", label: "Union" },
    { value: "Walworth", label: "Walworth" },
    { value: "Yankton", label: "Yankton" },
    { value: "Ziebach", label: "Ziebach" },
  ],
  Tennessee: [
    { value: "Anderson", label: "Anderson" },
    { value: "Bedford", label: "Bedford" },
    { value: "Benton", label: "Benton" },
    { value: "Bledsoe", label: "Bledsoe" },
    { value: "Blount", label: "Blount" },
    { value: "Bradley", label: "Bradley" },
    { value: "Campbell", label: "Campbell" },
    { value: "Cannon", label: "Cannon" },
    { value: "Carroll", label: "Carroll" },
    { value: "Carter", label: "Carter" },
    { value: "Cheatham", label: "Cheatham" },
    { value: "Chester", label: "Chester" },
    { value: "Claiborne", label: "Claiborne" },
    { value: "Clay", label: "Clay" },
    { value: "Cocke", label: "Cocke" },
    { value: "Coffee", label: "Coffee" },
    { value: "Crockett", label: "Crockett" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Davidson", label: "Davidson" },
    { value: "DeKalb", label: "DeKalb" },
    { value: "Decatur", label: "Decatur" },
    { value: "Dickson", label: "Dickson" },
    { value: "Dyer", label: "Dyer" },
    { value: "Fayette", label: "Fayette" },
    { value: "Fentress", label: "Fentress" },
    { value: "Franklin", label: "Franklin" },
    { value: "Gibson", label: "Gibson" },
    { value: "Giles", label: "Giles" },
    { value: "Grainger", label: "Grainger" },
    { value: "Greene", label: "Greene" },
    { value: "Grundy", label: "Grundy" },
    { value: "Hamblen", label: "Hamblen" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardeman", label: "Hardeman" },
    { value: "Hardin", label: "Hardin" },
    { value: "Hawkins", label: "Hawkins" },
    { value: "Haywood", label: "Haywood" },
    { value: "Henderson", label: "Henderson" },
    { value: "Henry", label: "Henry" },
    { value: "Hickman", label: "Hickman" },
    { value: "Houston", label: "Houston" },
    { value: "Humphreys", label: "Humphreys" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Johnson", label: "Johnson" },
    { value: "Knox", label: "Knox" },
    { value: "Lake", label: "Lake" },
    { value: "Lauderdale", label: "Lauderdale" },
    { value: "Lawrence", label: "Lawrence" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Loudon", label: "Loudon" },
    { value: "Macon", label: "Macon" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Maury", label: "Maury" },
    { value: "McMinn", label: "McMinn" },
    { value: "McNairy", label: "McNairy" },
    { value: "Meigs", label: "Meigs" },
    { value: "Monroe", label: "Monroe" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Moore", label: "Moore" },
    { value: "Morgan", label: "Morgan" },
    { value: "Obion", label: "Obion" },
    { value: "Overton", label: "Overton" },
    { value: "Perry", label: "Perry" },
    { value: "Pickett", label: "Pickett" },
    { value: "Polk", label: "Polk" },
    { value: "Putnam", label: "Putnam" },
    { value: "Rhea", label: "Rhea" },
    { value: "Roane", label: "Roane" },
    { value: "Robertson", label: "Robertson" },
    { value: "Rutherford", label: "Rutherford" },
    { value: "Scott", label: "Scott" },
    { value: "Sequatchie", label: "Sequatchie" },
    { value: "Sevier", label: "Sevier" },
    { value: "Shelby", label: "Shelby" },
    { value: "Smith", label: "Smith" },
    { value: "Stewart", label: "Stewart" },
    { value: "Sullivan", label: "Sullivan" },
    { value: "Sumner", label: "Sumner" },
    { value: "Tipton", label: "Tipton" },
    { value: "Trousdale", label: "Trousdale" },
    { value: "Unicoi", label: "Unicoi" },
    { value: "Union", label: "Union" },
    { value: "Van Buren", label: "Van Buren" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Weakley", label: "Weakley" },
    { value: "White", label: "White" },
    { value: "Williamson", label: "Williamson" },
    { value: "Wilson", label: "Wilson" },
  ],
  Texas: [
    { value: "Anderson", label: "Anderson" },
    { value: "Andrews", label: "Andrews" },
    { value: "Angelina", label: "Angelina" },
    { value: "Aransas", label: "Aransas" },
    { value: "Archer", label: "Archer" },
    { value: "Armstrong", label: "Armstrong" },
    { value: "Atascosa", label: "Atascosa" },
    { value: "Austin", label: "Austin" },
    { value: "Bailey", label: "Bailey" },
    { value: "Bandera", label: "Bandera" },
    { value: "Bastrop", label: "Bastrop" },
    { value: "Baylor", label: "Baylor" },
    { value: "Bee", label: "Bee" },
    { value: "Bell", label: "Bell" },
    { value: "Bexar", label: "Bexar" },
    { value: "Blanco", label: "Blanco" },
    { value: "Borden", label: "Borden" },
    { value: "Bosque", label: "Bosque" },
    { value: "Bowie", label: "Bowie" },
    { value: "Brazoria", label: "Brazoria" },
    { value: "Brazos", label: "Brazos" },
    { value: "Brewster", label: "Brewster" },
    { value: "Briscoe", label: "Briscoe" },
    { value: "Brooks", label: "Brooks" },
    { value: "Brown", label: "Brown" },
    { value: "Burleson", label: "Burleson" },
    { value: "Burnet", label: "Burnet" },
    { value: "Caldwell", label: "Caldwell" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Callahan", label: "Callahan" },
    { value: "Cameron", label: "Cameron" },
    { value: "Camp", label: "Camp" },
    { value: "Carson", label: "Carson" },
    { value: "Cass", label: "Cass" },
    { value: "Castro", label: "Castro" },
    { value: "Chambers", label: "Chambers" },
    { value: "Cherokee", label: "Cherokee" },
    { value: "Childress", label: "Childress" },
    { value: "Clay", label: "Clay" },
    { value: "Cochran", label: "Cochran" },
    { value: "Coke", label: "Coke" },
    { value: "Coleman", label: "Coleman" },
    { value: "Collin", label: "Collin" },
    { value: "Collingsworth", label: "Collingsworth" },
    { value: "Colorado", label: "Colorado" },
    { value: "Comal", label: "Comal" },
    { value: "Comanche", label: "Comanche" },
    { value: "Concho", label: "Concho" },
    { value: "Cooke", label: "Cooke" },
    { value: "Coryell", label: "Coryell" },
    { value: "Cottle", label: "Cottle" },
    { value: "Crane", label: "Crane" },
    { value: "Crockett", label: "Crockett" },
    { value: "Crosby", label: "Crosby" },
    { value: "Culberson", label: "Culberson" },
    { value: "Dallam", label: "Dallam" },
    { value: "Dallas", label: "Dallas" },
    { value: "Dawson", label: "Dawson" },
    { value: "DeWitt", label: "DeWitt" },
    { value: "Deaf Smith", label: "Deaf Smith" },
    { value: "Delta", label: "Delta" },
    { value: "Denton", label: "Denton" },
    { value: "Dickens", label: "Dickens" },
    { value: "Dimmit", label: "Dimmit" },
    { value: "Donley", label: "Donley" },
    { value: "Duval", label: "Duval" },
    { value: "Eastland", label: "Eastland" },
    { value: "Ector", label: "Ector" },
    { value: "Edwards", label: "Edwards" },
    { value: "El Paso", label: "El Paso" },
    { value: "Ellis", label: "Ellis" },
    { value: "Erath", label: "Erath" },
    { value: "Falls", label: "Falls" },
    { value: "Fannin", label: "Fannin" },
    { value: "Fayette", label: "Fayette" },
    { value: "Fisher", label: "Fisher" },
    { value: "Floyd", label: "Floyd" },
    { value: "Foard", label: "Foard" },
    { value: "Fort Bend", label: "Fort Bend" },
    { value: "Franklin", label: "Franklin" },
    { value: "Freestone", label: "Freestone" },
    { value: "Frio", label: "Frio" },
    { value: "Gaines", label: "Gaines" },
    { value: "Galveston", label: "Galveston" },
    { value: "Garza", label: "Garza" },
    { value: "Gillespie", label: "Gillespie" },
    { value: "Glasscock", label: "Glasscock" },
    { value: "Goliad", label: "Goliad" },
    { value: "Gonzales", label: "Gonzales" },
    { value: "Gray", label: "Gray" },
    { value: "Grayson", label: "Grayson" },
    { value: "Gregg", label: "Gregg" },
    { value: "Grimes", label: "Grimes" },
    { value: "Guadalupe", label: "Guadalupe" },
    { value: "Hale", label: "Hale" },
    { value: "Hall", label: "Hall" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "Hansford", label: "Hansford" },
    { value: "Hardeman", label: "Hardeman" },
    { value: "Hardin", label: "Hardin" },
    { value: "Harris", label: "Harris" },
    { value: "Harrison", label: "Harrison" },
    { value: "Hartley", label: "Hartley" },
    { value: "Haskell", label: "Haskell" },
    { value: "Hays", label: "Hays" },
    { value: "Hemphill", label: "Hemphill" },
    { value: "Henderson", label: "Henderson" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Hill", label: "Hill" },
    { value: "Hockley", label: "Hockley" },
    { value: "Hood", label: "Hood" },
    { value: "Hopkins", label: "Hopkins" },
    { value: "Houston", label: "Houston" },
    { value: "Howard", label: "Howard" },
    { value: "Hudspeth", label: "Hudspeth" },
    { value: "Hunt", label: "Hunt" },
    { value: "Hutchinson", label: "Hutchinson" },
    { value: "Irion", label: "Irion" },
    { value: "Jack", label: "Jack" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jasper", label: "Jasper" },
    { value: "Jeff Davis", label: "Jeff Davis" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Jim Hogg", label: "Jim Hogg" },
    { value: "Jim Wells", label: "Jim Wells" },
    { value: "Johnson", label: "Johnson" },
    { value: "Jones", label: "Jones" },
    { value: "Karnes", label: "Karnes" },
    { value: "Kaufman", label: "Kaufman" },
    { value: "Kendall", label: "Kendall" },
    { value: "Kenedy", label: "Kenedy" },
    { value: "Kent", label: "Kent" },
    { value: "Kerr", label: "Kerr" },
    { value: "Kimble", label: "Kimble" },
    { value: "King", label: "King" },
    { value: "Kinney", label: "Kinney" },
    { value: "Kleberg", label: "Kleberg" },
    { value: "Knox", label: "Knox" },
    { value: "La Salle", label: "La Salle" },
    { value: "Lamar", label: "Lamar" },
    { value: "Lamb", label: "Lamb" },
    { value: "Lampasas", label: "Lampasas" },
    { value: "Lavaca", label: "Lavaca" },
    { value: "Lee", label: "Lee" },
    { value: "Leon", label: "Leon" },
    { value: "Liberty", label: "Liberty" },
    { value: "Limestone", label: "Limestone" },
    { value: "Lipscomb", label: "Lipscomb" },
    { value: "Live Oak", label: "Live Oak" },
    { value: "Llano", label: "Llano" },
    { value: "Loving", label: "Loving" },
    { value: "Lubbock", label: "Lubbock" },
    { value: "Lynn", label: "Lynn" },
    { value: "Madison", label: "Madison" },
    { value: "Marion", label: "Marion" },
    { value: "Martin", label: "Martin" },
    { value: "Mason", label: "Mason" },
    { value: "Matagorda", label: "Matagorda" },
    { value: "Maverick", label: "Maverick" },
    { value: "McCulloch", label: "McCulloch" },
    { value: "McLennan", label: "McLennan" },
    { value: "McMullen", label: "McMullen" },
    { value: "Medina", label: "Medina" },
    { value: "Menard", label: "Menard" },
    { value: "Midland", label: "Midland" },
    { value: "Milam", label: "Milam" },
    { value: "Mills", label: "Mills" },
    { value: "Mitchell", label: "Mitchell" },
    { value: "Montague", label: "Montague" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Moore", label: "Moore" },
    { value: "Morris", label: "Morris" },
    { value: "Motley", label: "Motley" },
    { value: "Nacogdoches", label: "Nacogdoches" },
    { value: "Navarro", label: "Navarro" },
    { value: "Newton", label: "Newton" },
    { value: "Nolan", label: "Nolan" },
    { value: "Nueces", label: "Nueces" },
    { value: "Ochiltree", label: "Ochiltree" },
    { value: "Oldham", label: "Oldham" },
    { value: "Orange", label: "Orange" },
    { value: "Palo Pinto", label: "Palo Pinto" },
    { value: "Panola", label: "Panola" },
    { value: "Parker", label: "Parker" },
    { value: "Parmer", label: "Parmer" },
    { value: "Pecos", label: "Pecos" },
    { value: "Polk", label: "Polk" },
    { value: "Potter", label: "Potter" },
    { value: "Presidio", label: "Presidio" },
    { value: "Rains", label: "Rains" },
    { value: "Randall", label: "Randall" },
    { value: "Reagan", label: "Reagan" },
    { value: "Real", label: "Real" },
    { value: "Red River", label: "Red River" },
    { value: "Reeves", label: "Reeves" },
    { value: "Refugio", label: "Refugio" },
    { value: "Roberts", label: "Roberts" },
    { value: "Robertson", label: "Robertson" },
    { value: "Rockwall", label: "Rockwall" },
    { value: "Runnels", label: "Runnels" },
    { value: "Rusk", label: "Rusk" },
    { value: "Sabine", label: "Sabine" },
    { value: "San Augustine", label: "San Augustine" },
    { value: "San Jacinto", label: "San Jacinto" },
    { value: "San Patricio", label: "San Patricio" },
    { value: "San Saba", label: "San Saba" },
    { value: "Schleicher", label: "Schleicher" },
    { value: "Scurry", label: "Scurry" },
    { value: "Shackelford", label: "Shackelford" },
    { value: "Shelby", label: "Shelby" },
    { value: "Sherman", label: "Sherman" },
    { value: "Smith", label: "Smith" },
    { value: "Somervell", label: "Somervell" },
    { value: "Starr", label: "Starr" },
    { value: "Stephens", label: "Stephens" },
    { value: "Sterling", label: "Sterling" },
    { value: "Stonewall", label: "Stonewall" },
    { value: "Sutton", label: "Sutton" },
    { value: "Swisher", label: "Swisher" },
    { value: "Tarrant", label: "Tarrant" },
    { value: "Taylor", label: "Taylor" },
    { value: "Terrell", label: "Terrell" },
    { value: "Terry", label: "Terry" },
    { value: "Throckmorton", label: "Throckmorton" },
    { value: "Titus", label: "Titus" },
    { value: "Tom Green", label: "Tom Green" },
    { value: "Travis", label: "Travis" },
    { value: "Trinity", label: "Trinity" },
    { value: "Tyler", label: "Tyler" },
    { value: "Upshur", label: "Upshur" },
    { value: "Upton", label: "Upton" },
    { value: "Uvalde", label: "Uvalde" },
    { value: "Val Verde", label: "Val Verde" },
    { value: "Van Zandt", label: "Van Zandt" },
    { value: "Victoria", label: "Victoria" },
    { value: "Walker", label: "Walker" },
    { value: "Waller", label: "Waller" },
    { value: "Ward", label: "Ward" },
    { value: "Washington", label: "Washington" },
    { value: "Webb", label: "Webb" },
    { value: "Wharton", label: "Wharton" },
    { value: "Wheeler", label: "Wheeler" },
    { value: "Wichita", label: "Wichita" },
    { value: "Wilbarger", label: "Wilbarger" },
    { value: "Willacy", label: "Willacy" },
    { value: "Williamson", label: "Williamson" },
    { value: "Wilson", label: "Wilson" },
    { value: "Winkler", label: "Winkler" },
    { value: "Wise", label: "Wise" },
    { value: "Wood", label: "Wood" },
    { value: "Yoakum", label: "Yoakum" },
    { value: "Young", label: "Young" },
    { value: "Zapata", label: "Zapata" },
    { value: "Zavala", label: "Zavala" },
  ],
  Utah: [
    { value: "Beaver", label: "Beaver" },
    { value: "Box Elder", label: "Box Elder" },
    { value: "Cache", label: "Cache" },
    { value: "Carbon", label: "Carbon" },
    { value: "Daggett", label: "Daggett" },
    { value: "Davis", label: "Davis" },
    { value: "Duchesne", label: "Duchesne" },
    { value: "Emery", label: "Emery" },
    { value: "Garfield", label: "Garfield" },
    { value: "Grand", label: "Grand" },
    { value: "Iron", label: "Iron" },
    { value: "Juab", label: "Juab" },
    { value: "Kane", label: "Kane" },
    { value: "Millard", label: "Millard" },
    { value: "Morgan", label: "Morgan" },
    { value: "Piute", label: "Piute" },
    { value: "Rich", label: "Rich" },
    { value: "Salt Lake", label: "Salt Lake" },
    { value: "San Juan", label: "San Juan" },
    { value: "Sanpete", label: "Sanpete" },
    { value: "Sevier", label: "Sevier" },
    { value: "Summit", label: "Summit" },
    { value: "Tooele", label: "Tooele" },
    { value: "Uintah", label: "Uintah" },
    { value: "Utah", label: "Utah" },
    { value: "Wasatch", label: "Wasatch" },
    { value: "Washington", label: "Washington" },
    { value: "Wayne", label: "Wayne" },
    { value: "Weber", label: "Weber" },
  ],
  Vermont: [
    { value: "Addison", label: "Addison" },
    { value: "Bennington", label: "Bennington" },
    { value: "Caledonia", label: "Caledonia" },
    { value: "Chittenden", label: "Chittenden" },
    { value: "Essex", label: "Essex" },
    { value: "Franklin", label: "Franklin" },
    { value: "Grand Isle", label: "Grand Isle" },
    { value: "Lamoille", label: "Lamoille" },
    { value: "Orange", label: "Orange" },
    { value: "Orleans", label: "Orleans" },
    { value: "Rutland", label: "Rutland" },
    { value: "Washington", label: "Washington" },
    { value: "Windham", label: "Windham" },
    { value: "Windsor", label: "Windsor" },
  ],
  Virginia: [
    { value: "Accomack", label: "Accomack" },
    { value: "Albemarle", label: "Albemarle" },
    { value: "Alexandria", label: "Alexandria" },
    { value: "Alleghany", label: "Alleghany" },
    { value: "Amelia", label: "Amelia" },
    { value: "Amherst", label: "Amherst" },
    { value: "Appomattox", label: "Appomattox" },
    { value: "Arlington", label: "Arlington" },
    { value: "Augusta", label: "Augusta" },
    { value: "Bath", label: "Bath" },
    { value: "Bedford County", label: "Bedford County" },
    { value: "Bland", label: "Bland" },
    { value: "Botetourt", label: "Botetourt" },
    { value: "Bristol", label: "Bristol" },
    { value: "Brunswick", label: "Brunswick" },
    { value: "Buchanan", label: "Buchanan" },
    { value: "Buckingham", label: "Buckingham" },
    { value: "Buena Vista", label: "Buena Vista" },
    { value: "Campbell", label: "Campbell" },
    { value: "Caroline", label: "Caroline" },
    { value: "Carroll", label: "Carroll" },
    { value: "Charles City", label: "Charles City" },
    { value: "Charlotte", label: "Charlotte" },
    { value: "Charlottesville", label: "Charlottesville" },
    { value: "Chesapeake", label: "Chesapeake" },
    { value: "Chesterfield", label: "Chesterfield" },
    { value: "Clarke", label: "Clarke" },
    { value: "Colonial Heights", label: "Colonial Heights" },
    { value: "Covington", label: "Covington" },
    { value: "Craig", label: "Craig" },
    { value: "Culpeper", label: "Culpeper" },
    { value: "Cumberland", label: "Cumberland" },
    { value: "Danville", label: "Danville" },
    { value: "Dickenson", label: "Dickenson" },
    { value: "Dinwiddie", label: "Dinwiddie" },
    { value: "Emporia", label: "Emporia" },
    { value: "Essex", label: "Essex" },
    { value: "Fairfax City", label: "Fairfax City" },
    { value: "Fairfax County", label: "Fairfax County" },
    { value: "Falls Church", label: "Falls Church" },
    { value: "Fauquier", label: "Fauquier" },
    { value: "Floyd", label: "Floyd" },
    { value: "Fluvanna", label: "Fluvanna" },
    { value: "Franklin City", label: "Franklin City" },
    { value: "Franklin County", label: "Franklin County" },
    { value: "Frederick", label: "Frederick" },
    { value: "Fredericksburg", label: "Fredericksburg" },
    { value: "Galax", label: "Galax" },
    { value: "Giles", label: "Giles" },
    { value: "Gloucester", label: "Gloucester" },
    { value: "Goochland", label: "Goochland" },
    { value: "Grayson", label: "Grayson" },
    { value: "Greene", label: "Greene" },
    { value: "Greensville", label: "Greensville" },
    { value: "Halifax", label: "Halifax" },
    { value: "Hampton", label: "Hampton" },
    { value: "Hanover", label: "Hanover" },
    { value: "Harrisonburg", label: "Harrisonburg" },
    { value: "Henrico", label: "Henrico" },
    { value: "Henry", label: "Henry" },
    { value: "Highland", label: "Highland" },
    { value: "Hopewell", label: "Hopewell" },
    { value: "Isle of Wight", label: "Isle of Wight" },
    { value: "James City", label: "James City" },
    { value: "King George", label: "King George" },
    { value: "King William", label: "King William" },
    { value: "King and Queen", label: "King and Queen" },
    { value: "Lancaster", label: "Lancaster" },
    { value: "Lee", label: "Lee" },
    { value: "Lexington", label: "Lexington" },
    { value: "Loudoun", label: "Loudoun" },
    { value: "Louisa", label: "Louisa" },
    { value: "Lunenburg", label: "Lunenburg" },
    { value: "Lynchburg", label: "Lynchburg" },
    { value: "Madison", label: "Madison" },
    { value: "Manassas", label: "Manassas" },
    { value: "Manassas Park", label: "Manassas Park" },
    { value: "Martinsville", label: "Martinsville" },
    { value: "Mathews", label: "Mathews" },
    { value: "Mecklenburg", label: "Mecklenburg" },
    { value: "Middlesex", label: "Middlesex" },
    { value: "Montgomery", label: "Montgomery" },
    { value: "Nelson", label: "Nelson" },
    { value: "New Kent", label: "New Kent" },
    { value: "Newport News", label: "Newport News" },
    { value: "Norfolk", label: "Norfolk" },
    { value: "Northampton", label: "Northampton" },
    { value: "Northumberland", label: "Northumberland" },
    { value: "Norton", label: "Norton" },
    { value: "Nottoway", label: "Nottoway" },
    { value: "Orange", label: "Orange" },
    { value: "Page", label: "Page" },
    { value: "Patrick", label: "Patrick" },
    { value: "Petersburg", label: "Petersburg" },
    { value: "Pittsylvania", label: "Pittsylvania" },
    { value: "Poquoson", label: "Poquoson" },
    { value: "Portsmouth", label: "Portsmouth" },
    { value: "Powhatan", label: "Powhatan" },
    { value: "Prince Edward", label: "Prince Edward" },
    { value: "Prince George", label: "Prince George" },
    { value: "Prince William", label: "Prince William" },
    { value: "Pulaski", label: "Pulaski" },
    { value: "Radford", label: "Radford" },
    { value: "Rappahannock", label: "Rappahannock" },
    { value: "Richmond City", label: "Richmond City" },
    { value: "Richmond County", label: "Richmond County" },
    { value: "Roanoke City", label: "Roanoke City" },
    { value: "Roanoke County", label: "Roanoke County" },
    { value: "Rockbridge", label: "Rockbridge" },
    { value: "Rockingham", label: "Rockingham" },
    { value: "Russell", label: "Russell" },
    { value: "Salem", label: "Salem" },
    { value: "Scott", label: "Scott" },
    { value: "Shenandoah", label: "Shenandoah" },
    { value: "Smyth", label: "Smyth" },
    { value: "Southampton", label: "Southampton" },
    { value: "Spotsylvania", label: "Spotsylvania" },
    { value: "Stafford", label: "Stafford" },
    { value: "Staunton", label: "Staunton" },
    { value: "Suffolk", label: "Suffolk" },
    { value: "Surry", label: "Surry" },
    { value: "Sussex", label: "Sussex" },
    { value: "Tazewell", label: "Tazewell" },
    { value: "Virginia Beach", label: "Virginia Beach" },
    { value: "Warren", label: "Warren" },
    { value: "Washington", label: "Washington" },
    { value: "Waynesboro", label: "Waynesboro" },
    { value: "Westmoreland", label: "Westmoreland" },
    { value: "Williamsburg", label: "Williamsburg" },
    { value: "Winchester", label: "Winchester" },
    { value: "Wise", label: "Wise" },
    { value: "Wythe", label: "Wythe" },
    { value: "York", label: "York" },
  ],
  Washington: [
    { value: "Adams", label: "Adams" },
    { value: "Asotin", label: "Asotin" },
    { value: "Benton", label: "Benton" },
    { value: "Chelan", label: "Chelan" },
    { value: "Clallam", label: "Clallam" },
    { value: "Clark", label: "Clark" },
    { value: "Columbia", label: "Columbia" },
    { value: "Cowlitz", label: "Cowlitz" },
    { value: "Douglas", label: "Douglas" },
    { value: "Ferry", label: "Ferry" },
    { value: "Franklin", label: "Franklin" },
    { value: "Garfield", label: "Garfield" },
    { value: "Grant", label: "Grant" },
    { value: "Grays Harbor", label: "Grays Harbor" },
    { value: "Island", label: "Island" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "King", label: "King" },
    { value: "Kitsap", label: "Kitsap" },
    { value: "Kittitas", label: "Kittitas" },
    { value: "Klickitat", label: "Klickitat" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Mason", label: "Mason" },
    { value: "Okanogan", label: "Okanogan" },
    { value: "Pacific", label: "Pacific" },
    { value: "Pend Oreille", label: "Pend Oreille" },
    { value: "Pierce", label: "Pierce" },
    { value: "San Juan", label: "San Juan" },
    { value: "Skagit", label: "Skagit" },
    { value: "Skamania", label: "Skamania" },
    { value: "Snohomish", label: "Snohomish" },
    { value: "Spokane", label: "Spokane" },
    { value: "Stevens", label: "Stevens" },
    { value: "Thurston", label: "Thurston" },
    { value: "Wahkiakum", label: "Wahkiakum" },
    { value: "Walla Walla", label: "Walla Walla" },
    { value: "Whatcom", label: "Whatcom" },
    { value: "Whitman", label: "Whitman" },
    { value: "Yakima", label: "Yakima" },
  ],
  "West Virginia": [
    { value: "Barbour", label: "Barbour" },
    { value: "Berkeley", label: "Berkeley" },
    { value: "Boone", label: "Boone" },
    { value: "Braxton", label: "Braxton" },
    { value: "Brooke", label: "Brooke" },
    { value: "Cabell", label: "Cabell" },
    { value: "Calhoun", label: "Calhoun" },
    { value: "Clay", label: "Clay" },
    { value: "Doddridge", label: "Doddridge" },
    { value: "Fayette", label: "Fayette" },
    { value: "Gilmer", label: "Gilmer" },
    { value: "Grant", label: "Grant" },
    { value: "Greenbrier", label: "Greenbrier" },
    { value: "Hampshire", label: "Hampshire" },
    { value: "Hancock", label: "Hancock" },
    { value: "Hardy", label: "Hardy" },
    { value: "Harrison", label: "Harrison" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Kanawha", label: "Kanawha" },
    { value: "Lewis", label: "Lewis" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Logan", label: "Logan" },
    { value: "Marion", label: "Marion" },
    { value: "Marshall", label: "Marshall" },
    { value: "Mason", label: "Mason" },
    { value: "McDowell", label: "McDowell" },
    { value: "Mercer", label: "Mercer" },
    { value: "Mineral", label: "Mineral" },
    { value: "Mingo", label: "Mingo" },
    { value: "Monongalia", label: "Monongalia" },
    { value: "Monroe", label: "Monroe" },
    { value: "Morgan", label: "Morgan" },
    { value: "Nicholas", label: "Nicholas" },
    { value: "Ohio", label: "Ohio" },
    { value: "Pendleton", label: "Pendleton" },
    { value: "Pleasants", label: "Pleasants" },
    { value: "Pocahontas", label: "Pocahontas" },
    { value: "Preston", label: "Preston" },
    { value: "Putnam", label: "Putnam" },
    { value: "Raleigh", label: "Raleigh" },
    { value: "Randolph", label: "Randolph" },
    { value: "Ritchie", label: "Ritchie" },
    { value: "Roane", label: "Roane" },
    { value: "Summers", label: "Summers" },
    { value: "Taylor", label: "Taylor" },
    { value: "Tucker", label: "Tucker" },
    { value: "Tyler", label: "Tyler" },
    { value: "Upshur", label: "Upshur" },
    { value: "Wayne", label: "Wayne" },
    { value: "Webster", label: "Webster" },
    { value: "Wetzel", label: "Wetzel" },
    { value: "Wirt", label: "Wirt" },
    { value: "Wood", label: "Wood" },
    { value: "Wyoming", label: "Wyoming" },
  ],
  Wisconsin: [
    { value: "Adams", label: "Adams" },
    { value: "Ashland", label: "Ashland" },
    { value: "Barron", label: "Barron" },
    { value: "Bayfield", label: "Bayfield" },
    { value: "Brown", label: "Brown" },
    { value: "Buffalo", label: "Buffalo" },
    { value: "Burnett", label: "Burnett" },
    { value: "Calumet", label: "Calumet" },
    { value: "Chippewa", label: "Chippewa" },
    { value: "Clark", label: "Clark" },
    { value: "Columbia", label: "Columbia" },
    { value: "Crawford", label: "Crawford" },
    { value: "Dane", label: "Dane" },
    { value: "Dodge", label: "Dodge" },
    { value: "Door", label: "Door" },
    { value: "Douglas", label: "Douglas" },
    { value: "Dunn", label: "Dunn" },
    { value: "Eau Claire", label: "Eau Claire" },
    { value: "Florence", label: "Florence" },
    { value: "Fond du Lac", label: "Fond du Lac" },
    { value: "Forest", label: "Forest" },
    { value: "Grant", label: "Grant" },
    { value: "Green", label: "Green" },
    { value: "Green Lake", label: "Green Lake" },
    { value: "Iowa", label: "Iowa" },
    { value: "Iron", label: "Iron" },
    { value: "Jackson", label: "Jackson" },
    { value: "Jefferson", label: "Jefferson" },
    { value: "Juneau", label: "Juneau" },
    { value: "Kenosha", label: "Kenosha" },
    { value: "Kewaunee", label: "Kewaunee" },
    { value: "La Crosse", label: "La Crosse" },
    { value: "Lafayette", label: "Lafayette" },
    { value: "Langlade", label: "Langlade" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Manitowoc", label: "Manitowoc" },
    { value: "Marathon", label: "Marathon" },
    { value: "Marinette", label: "Marinette" },
    { value: "Marquette", label: "Marquette" },
    { value: "Menominee", label: "Menominee" },
    { value: "Milwaukee", label: "Milwaukee" },
    { value: "Monroe", label: "Monroe" },
    { value: "Oconto", label: "Oconto" },
    { value: "Oneida", label: "Oneida" },
    { value: "Outagamie", label: "Outagamie" },
    { value: "Ozaukee", label: "Ozaukee" },
    { value: "Pepin", label: "Pepin" },
    { value: "Pierce", label: "Pierce" },
    { value: "Polk", label: "Polk" },
    { value: "Portage", label: "Portage" },
    { value: "Price", label: "Price" },
    { value: "Racine", label: "Racine" },
    { value: "Richland", label: "Richland" },
    { value: "Rock", label: "Rock" },
    { value: "Rusk", label: "Rusk" },
    { value: "Sauk", label: "Sauk" },
    { value: "Sawyer", label: "Sawyer" },
    { value: "Shawano", label: "Shawano" },
    { value: "Sheboygan", label: "Sheboygan" },
    { value: "St. Croix", label: "St. Croix" },
    { value: "Taylor", label: "Taylor" },
    { value: "Trempealeau", label: "Trempealeau" },
    { value: "Vernon", label: "Vernon" },
    { value: "Vilas", label: "Vilas" },
    { value: "Walworth", label: "Walworth" },
    { value: "Washburn", label: "Washburn" },
    { value: "Washington", label: "Washington" },
    { value: "Waukesha", label: "Waukesha" },
    { value: "Waupaca", label: "Waupaca" },
    { value: "Waushara", label: "Waushara" },
    { value: "Winnebago", label: "Winnebago" },
    { value: "Wood", label: "Wood" },
  ],
  Wyoming: [
    { value: "Albany", label: "Albany" },
    { value: "Big Horn", label: "Big Horn" },
    { value: "Campbell", label: "Campbell" },
    { value: "Carbon", label: "Carbon" },
    { value: "Converse", label: "Converse" },
    { value: "Crook", label: "Crook" },
    { value: "Fremont", label: "Fremont" },
    { value: "Goshen", label: "Goshen" },
    { value: "Hot Springs", label: "Hot Springs" },
    { value: "Johnson", label: "Johnson" },
    { value: "Laramie", label: "Laramie" },
    { value: "Lincoln", label: "Lincoln" },
    { value: "Natrona", label: "Natrona" },
    { value: "Niobrara", label: "Niobrara" },
    { value: "Park", label: "Park" },
    { value: "Platte", label: "Platte" },
    { value: "Sheridan", label: "Sheridan" },
    { value: "Sublette", label: "Sublette" },
    { value: "Sweetwater", label: "Sweetwater" },
    { value: "Teton", label: "Teton" },
    { value: "Uinta", label: "Uinta" },
    { value: "Washakie", label: "Washakie" },
    { value: "Weston", label: "Weston" },
  ],
};
