import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { NumericFormat } from "react-number-format";

import "react-datepicker/dist/react-datepicker.css";

export default function InputField({
  label,
  color,
  type,
  money,
  percentage,
  required,
  ...props
}) {
  let className;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [value, setValue] = useState(field.value);
  const [randomId, setRandomId] = useState(Math.random());

  switch (color) {
    case "deep-sea":
      className = `peer p-4 w-full placeholder:text-transparent focus:pt-5 focus:pb-3
      border border-gray-200 rounded-lg text-sm
      [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:text-base
      autofill:pt-6 autofill:pb-2
      focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500`;
      break;
    case "congress-blue":
      className = `peer p-4 w-full placeholder:text-transparent focus:pt-5 focus:pb-3
      border border-gray-200 rounded-lg text-sm
      [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:text-base
      autofill:pt-6 autofill:pb-2
      focus:outline-none focus:ring-congress-blue-500 focus:border-congress-blue-500`;
      break;
    default:
      className = `peer p-4 w-full placeholder:text-transparent focus:pt-5 focus:pb-3
      border border-gray-200 rounded-lg text-sm
      [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:text-base
      autofill:pt-6 autofill:pb-2
      focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500`;
      break;
  }

  // if (meta.touched && meta.error) {
  //   className = money
  //     ? `appearance-none block w-full px-3 py-4 border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded  sm:text-sm bg-transparent ${
  //         field.value ? "bg-white" : "bg-transparent"
  //       }`
  //     : `block w-full px-3 py-4 pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded bg-transparent ${
  //         field.value ? "bg-white" : "bg-transparent"
  //       }`;
  // }

  // if (money || percentage) {
  //   className = className + " pl-7";
  // }

  let input;

  switch (type) {
    case "textarea":
      input = (
        <textarea
          className={
            (className || "") +
            " text-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
          }
          name={name}
          type={type}
          {...field}
          {...props}
          placeholder="name"
          id={randomId}
        />
      );
      break;
    case "datepicker":
      input = (
        <DatePicker
          wrapperClassName="w-full invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
          className={(className || "") + " text-black"}
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          placeholder="name"
          id={randomId}
          // customInput={<DatePickerCustomInput />}
        />
      );
      break;
    default:
      money
        ? (input = (
            <NumericFormat
              thousandSeparator={true}
              className={
                (className || "") +
                " text-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              }
              name={name}
              value={field.value}
              onValueChange={(val) => setFieldValue(field.name, val.floatValue)}
              placeholder="name"
              id={randomId}
            />
          ))
        : (input = (
            <input
              lang="en-150"
              className={
                (className || "") +
                " text-black invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              }
              name={name}
              type={type}
              value={
                money
                  ? field.value && field.value.toLocaleString()
                  : field.value
              }
              {...field}
              {...props}
              placeholder="name"
              id={randomId}
            />
          ));
      break;
  }
  return (
    <div className="relative">
      {input}
      <label
        htmlFor={randomId}
        className={`absolute top-4 left-2 ml-1 -translate-y-3 px-1 text-sm text-gray-400 duration-100
        ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base
        peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:text-sm
        peer-focus:px-1 ${
          required
            ? "after:content-['*'] after:text-red-500 after:pl-1"
            : "after:content-['(Optional)'] after:text-gray-300 after:pl-1"
        }`}
      >
        {label}
      </label>

      {money && (
        <div className="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      )}
      {!money && meta.touched && meta.error ? (
        <div className="absolute inset-y-0 right-0 bottom-5 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      ) : null}
      {meta.touched && meta.error ? (
        <p className="mt-1 text-sm text-red-600">{meta.error}</p>
      ) : null}
    </div>
  );
}
