import React from "react";

function NoSubscribedPlan() {

  return (
    <div className="flex flex-col px-8 w-full h-auto py-5">
      <p className="text-center text-xl">
        Not subscribed to a plan yet? It's free and won't charge your credit card for the 30-day trial to experience full features.
      </p>
      <a className="text-black text-center text-congress-blue-600 font-bold text-lg mt-3" href={"/home/subscribe"}>Let's get started</a>
    </div>
  );
}

export default NoSubscribedPlan;
