import normalize from "json-api-normalizer";

import { POST, DELETE } from "../utility/api";

export const RECEIVE_BANKERS = "RECEIVE_BANKERS";
export const REMOVE_BANKER = "REMOVE_BANKER";

export const receiveBankers = (bankers) => ({
  type: RECEIVE_BANKERS,
  payload: bankers,
});

export const removeBanker = (banker) => ({
  type: REMOVE_BANKER,
  payload: banker,
});

export const createBanker = (data, organizationBranchId, submit) => (_dispatch) => {
  return POST("/api/organization_branch/" + data.organizationBranchId + "/bankers/new", { banker: data, submit }).then(
    (res) => {
      return res && res.data && res.data.redirect;
    }
  );
};

export const createLenderBanker = (data, userId, submit) => (_dispatch) => {
  return POST("/api/lender/" + data.lenderId + "/bankers/new-create", { banker: data, submit }).then(
    (res) => {
      return res && res.data && res.data.redirect;
    }
  );
};

export const destroyBanker = (data) => (dispatch) => {
  return DELETE("/api/organization_branch/" + data.organizationBranchId + "/bankers/" + data.bankerId).then(
    (res) => {
      window.location.assign(res.data.redirect);
    }
  );
};
