import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import withGlobalProviders from "../../../../general/withGlobalProvider";
import ListItem from "./ListItem";
import EmptyState from "./EmptyState";

function Items({count}) {
  const { loaded, items } = useSelector((state) => state.loanRequests);
  const [recentItems, setRecentItems] = useState([])
  useEffect(() => {
    if (items && (typeof items === 'object') && 'new_registered_lenders' in items) {
      setRecentItems(items.loan_request.data);
    } else {
      setRecentItems(Object.entries(items || {}));
    }
  }, [items]);

  const removeLoanRequest = (id) => {
    setRecentItems(recentItems.filter(item => item[0] !== id));
    window.location.reload();
  }

  if (loaded && !items) return <EmptyState />;

  if (!items)
    return (
      <p className="text-sm font-medium text-gray-500">
        You haven’t made any searches yet. Start with
        <a className="pl-1 text-sm text-congress-blue-500 font-bold hover:border-congress-blue-500" href="/lenders/search/new">
          creating a new search.
        </a>
      </p>
    );

  return (
    <div className="flex space-y-6 flex-col">
      {recentItems?.slice(0, count != 0 ? count : recentItems?.length)?.map((item,index) => {
        return (
       'new_registered_lenders' in items === true ?  <ListItem  {...{ key: index, id: item.id, data:item }} removeItem={removeLoanRequest}/> : <ListItem {...{ key: item[1], id: item[1],data:item[1] }} removeItem={removeLoanRequest}/>
      )})}
      {recentItems?.length > 0 ?<></>: <EmptyState next={true} />}
    </div>
  );
}

export default withGlobalProviders(Items);
