import normalize from "json-api-normalizer";

import { POST, DELETE, GET } from "../utility/api";

export const RECEIVE_BROKERS = "RECEIVE_BROKERS";
export const REMOVE_BROKER = "REMOVE_BROKER";

export const receiveBrokers = (brokers) => ({
  type: RECEIVE_BROKERS,
  payload: brokers,
});

export const removeBroker = (broker) => ({
  type: REMOVE_BROKER,
  payload: broker,
});

export const createBroker = (data, organizationBranchId, submit) => (_dispatch) => {
  return POST("/api/organization_branch/" + organizationBranchId + "/brokers/new", { broker: data, submit }).then(
    (res) => {
      return res && res.data && res.data.redirect;
    }
  );
};

export const destroyBroker = (data) => (dispatch) => {
  return DELETE("/api/organization_branch/" + data.organizationBranchId + "/brokers/" + data.brokerId).then(
    (res) => {
      window.location.assign(res.data.redirect);
    }
  );
};

export const getBrokers = () => (dispatch) => {
  return GET("/api/brokers").then((res) => {
      return dispatch(receiveBrokers(res.data.data));
    }
  );
};