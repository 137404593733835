import React, { useState } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import PageBody from "../../../general/PageBody";
import SavedLenderItem from "./SavedLenderItem";
import { PrinterIcon } from "@heroicons/react/24/outline";
// import withGlobalProviders from "../../../../general/withGlobalProvider";


function Items({saved_loan_matches, loan_request}) {
  const [savedLendersPerOrganization, setSavedLendersPerOrganization] =
    useState(saved_loan_matches.data);
  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 ">
              Request of ${(loan_request/100).toLocaleString()} for Multifamily
            </h1>
          </div>
          <div className="flex justify-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
            <p className="text-3xl font-bold leading-tight text-gray-900 ">
              Lender
            </p>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-8">
            <div className="flex space-y-6 flex-col">
              {savedLendersPerOrganization.filter(item => item.attributes.saved == true).map((lender) => (
                <SavedLenderItem lender={lender.attributes} key={lender.id} />
              ))}
            </div>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default Items;
