import React, { useState, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { NumericFormat } from "react-number-format";

import "react-datepicker/dist/react-datepicker.css";

export const InputField = function ({
  label,
  color,
  type,
  money,
  percentage,
  required,
  ...props
}) {
  let className;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [value, setValue] = useState(field.value);

  switch (color) {
    case "deep-sea":
      className =
        "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500 sm:text-sm";
      break;
    case "congress-blue":
      className =
        "appearance-none block w-full px-3  border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-congress-blue-500 focus:border-congress-blue-500 sm:text-sm";
      break;
    default:
      className =
        "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500 sm:text-sm";
      break;
  }

  if (meta.touched && meta.error) {
    className = money
      ? "appearance-none block w-full px-3 py-2 border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md shadow-sm  sm:text-sm"
      : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
  }

  if (money || percentage) {
    className = className + " pl-7";
  }

  let input;

  switch (type) {
    case "textarea":
      input = (
        <textarea
          className={(className || "") + " text-black"}
          name={name}
          type={type}
          {...field}
          {...props}
        />
      );
      break;
    case "datepicker":
      input = (
        <DatePicker
          wrapperClassName="w-full"
          className={(className || "") + " text-black"}
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
      );
      break;
    default:
      money
        ? (input = (
            <NumericFormat
              thousandSeparator={true}
              placeholder={label}
              className={(className || "") + " text-black"}
              name={name}
              value={field.value}
              onValueChange={(val) => setFieldValue(field.name, val.floatValue)}
            />
          ))
        : (input = (
            <input
              lang="en-150"
              placeholder={label}
              className={(className || "") + " text-black"}
              name={name}
              type={type}
              value={
                money
                  ? field.value && field.value.toLocaleString()
                  : field.value
              }
              {...field}
              {...props}
            />
          ));
      break;
  }
  return (
    <div>
      <div className="relative">
        {money && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
        )}
        {percentage && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">%</span>
          </div>
        )}
        {input}
        {money && (
          <div className="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              USD
            </span>
          </div>
        )}
        {!money && meta.touched && meta.error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      ) : null}
    </div>
  );
};

export const MultiBox = function ({ options, label, name }) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name });

  const isSelected = (value) => {
    return selectedValues.find((el) => el === value) ? true : false;
  };

  function handleSelect(value) {
    if (!isSelected(value)) {
      const selectedValuesUpdated = [
        ...selectedValues,
        options.find((el) => el.value === value).value,
      ];
      setSelectedValues(selectedValuesUpdated);
      setFieldValue(field.name, selectedValuesUpdated);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  function handleDeselect(value) {
    const selectedValuesUpdated = selectedValues.filter((el) => el !== value);
    setSelectedValues(selectedValuesUpdated);
    setIsOpen(true);
  }
  function handleClick(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }
  const listboxRef = useRef(); // Initialize a useRef

  useEffect(() => {
    function handleOutsideClick(event) {
      // Check if clicked element is inside the Listbox
      if (listboxRef.current && !listboxRef.current.contains(event.target)) {
        setIsOpen(false); // Close the Listbox
      }
    }

    // Attach the listeners to the document
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      // Clean up the listeners
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [isOpen, setIsOpen]);
  return (
    <div className="flex items-center justify-center" ref={listboxRef}>
      <div className="w-full max-w-xs mx-auto">
        <Listbox
          as="div"
          className="space-y-1"
          value={selectedValues}
          onChange={(value) => handleSelect(value)}
          open={isOpen}
        >
          {() => (
            <>
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button
                    className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    open={isOpen}
                  >
                    <span className="block truncate" onClick={handleClick}>
                      {selectedValues.length < 1
                        ? `${label}`
                        : `${label} (${selectedValues.length})`}
                    </span>
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                      onClick={handleClick}
                    >
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  unmount={false}
                  show={isOpen}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                >
                  <Listbox.Options
                    static
                    className="max-h-60 rounded-md py-1 text-base leading-6 bg-white shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {options.map((option) => {
                      const selected = isSelected(option.value);
                      return (
                        <Listbox.Option key={option.value} value={option.value}>
                          {({ active }) => (
                            <div
                              className={`${
                                active
                                  ? "text-white bg-congress-blue-600"
                                  : "text-gray-900"
                              } cursor-default select-none relative pl-8 pr-4 hover:text-white hover:bg-congress-blue-600`}
                            >
                              <span
                                className={`${
                                  selected ? "font-semibold" : "font-normal"
                                } block truncate`}
                              >
                                {option.label}
                              </span>
                              {selected && (
                                <span
                                  className={`${
                                    active
                                      ? "text-white"
                                      : "text-congress-blue-600"
                                  } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                >
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};
export const SingleBox = function ({ options, label, name, ...props }) {
  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name });
  useEffect(() => {
    if (field.value === "") {
      setFieldValue(field.name, selectedValue);
    }
  }, []);
  function handleSelect(value) {
    setSelectedValue(value);
    setFieldValue(field.name, value);
  }
  return (
    <div className="flex items-center justify-center">
      <div className="w-full max-w-xs mx-auto">
        <Listbox
          as="div"
          className="space-y-1"
          value={selectedValue}
          onChange={(value) => handleSelect(value)}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <span className="block truncate">{selectedValue}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                >
                  <Listbox.Options
                    static
                    className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {options.map((option) => (
                      <Listbox.Option key={option.value} value={option.value}>
                        {({ selected, active }) => (
                          <div
                            className={`${
                              active
                                ? "text-white bg-congress-blue-600"
                                : "text-gray-900"
                            } cursor-default select-none relative pl-8 pr-4`}
                          >
                            <span
                              className={`${
                                selected ? "font-semibold" : "font-normal"
                              } block truncate `}
                            >
                              {option.label}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? "text-white" : "text-blue-600"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};
