import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Transition } from "@headlessui/react";
import BrokerItem from "./BrokerItem";
import { getBrokers } from "../../../../../actions/brokerActions";

function Brokers() {

  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.loanRequests);
  const brokers = items?.brokers?.data.slice(0, 3)

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
      className="mt-4 justify-between gap-6 grid grid-cols-6"
    >
      {
        brokers?.length > 0 && brokers.map((broker, index) => <BrokerItem key={index} {...broker}/>)
      }
    </Transition>
  );
}

export default Brokers;
