import React, { useState } from "react";

const SearchSelect = ({ options, onSelectionChange }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelectionChange(selectedValue); // Call the onSelectionChange callback with the selected value
  };

  return (
    <select
      value={selectedOption}
      onChange={handleSelectionChange}
      className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SearchSelect;