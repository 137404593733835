import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import {
  RECEIVE_LOAN_REQUEST_MATCH,
  RECEIVE_LOAN_REQUEST_MATCHES,
  SAVE_LOAN_REQUEST_MATCHES,
  SAVE_ALL_LOAN_REQUEST_MATCHES,
  DELETE_LOAN_REQUEST_MATCHES
} from "../actions/loanRequestMatchesActions";

const defaultState = {
  items: {},
  loaded: false,
  selectedItems: []
};

const loanRequestMatchesReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_LOAN_REQUEST_MATCH:
      return merge({}, state, { items: action.payload, loaded: true });
    case RECEIVE_LOAN_REQUEST_MATCHES:
      let newItems = cloneDeep(state.items);
      const newLoanRequestMatches = action.payload;

      return {
        ...state,
        items: { ...newItems, ...newLoanRequestMatches },
        loaded: true,
      };
    case SAVE_LOAN_REQUEST_MATCHES:
        return { ...state, selectedItems: [...state.selectedItems, action.payload] };
    case SAVE_ALL_LOAN_REQUEST_MATCHES:
      return { ...state, selectedItems: action.payload };
    case DELETE_LOAN_REQUEST_MATCHES:
      return { ...state, selectedItems: state.selectedItems.filter((item) => item != action.payload) }
    default:
      return state;
  }
};

export default loanRequestMatchesReducer;
