import React from "react";
import { useDispatch } from "react-redux";

import { Formik, Form } from "formik";

import withGlobalProviders from "../../../../general/withGlobalProvider";

import Select from "../../../../forms/SelectField";
import InputField from "../../../../forms/InputField";
import { createHandleSubmit } from "../../../../../utility/helpers";
import {
  createBanker,
  createLenderBanker,
} from "../../../../../actions/bankerActions";
import toast from "react-hot-toast";

import {
  initialValues as baseValues,
  validationSchema,
  stateOptions,
} from "./helpers";

function BankerForm({
  providedValues,
  organizationBranchId,
  lenderId,
  loanMatchId,
}) {
  const dispatch = useDispatch();
  const onSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      if (organizationBranchId) {
        return dispatch(createBanker({ ...values, organizationBranchId }));
      } else {
        return dispatch(
          createLenderBanker({
            ...values,
            organizationBranchId,
            lenderId,
            loanMatchId,
          })
        );
      }
    },
    handleSuccess: (redirect) => {
      if (redirect) {
        window.location.assign(redirect);
        toast.success("Banker created successfully.");
      } else {
        window.location.reload();
      }
    },
    handleErrors: (res) => {
      window.location.reload();
      toast.error(res.response.data.join(", "));
    },
  });

  const initialValues = providedValues || baseValues;

  return (
    <div
      as="div"
      key={"banker-parameters"}
      aria-labelledby="banker-parameters"
      className="bg-white shadow-lg sm:rounded-lg"
    >
      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        {({ values, setFieldValue, touched, errors, submitForm }) => {
          return (
            <Form>
              <dd className="">
                <div className="border-t border-gray-200 px-4 pt-5 sm:px-6 flex flex-col">
                  <div className="sm:col-span-full border-b pb-2 my-2 text-lg leading-5 font-medium text-gray-900">
                    Add Banker
                  </div>
                  <div className="flex px-6 pt-6">
                    <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                      Banker Details
                    </div>
                    <div className=" w-2/3 gap-8 grid grid-cols-6">
                      <div className="w-full col-span-6 sm:col-span-3">
                        <InputField
                          name="firstName"
                          type="text"
                          label="First Name"
                          color="congress-blue"
                          required={true}
                        />
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <InputField
                          name="lastName"
                          type="text"
                          label="Last Name"
                          color="congress-blue"
                          required={true}
                        />
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <InputField
                          name="email"
                          color="congress-blue"
                          type="text"
                          label="Email"
                          required={true}
                        />
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <InputField
                          name="phoneNumber"
                          color="congress-blue"
                          type="number"
                          label="Phone Number"
                          required={true}
                        />
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <InputField
                          name="city"
                          type="text"
                          label="City"
                          color="congress-blue"
                        />
                      </div>
                      <div className="w-full col-span-6 sm:col-span-3">
                        <Select
                          name="state"
                          options={stateOptions}
                          label={"State"}
                          isClearable={true}
                          required={true}
                          placeholder={
                            <div className=" text-gray-500 text-base">
                              State<span className=" text-red-400 ml-1">*</span>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex px-6 py-6 bg-gray-50">
                    <div className="w-1/3 sm:col-span-full my-2 text-lg leading-5 font-medium text-gray-900">
                      Notes
                    </div>
                    <div className="grid grid-cols-4 w-2/3 gap-4">
                      <div className="w-full col-span-4">
                        <InputField
                          name="notes"
                          color="congress-blue"
                          type="textarea"
                          label="Notes"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
              <div className="block  text-sm font-medium text-gray-500 text-end px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                <button
                  type="submit"
                  className="inline-flex items-center w-3/12 justify-center mr-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                >
                  Create Banker
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withGlobalProviders(BankerForm);
