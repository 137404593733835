import React from "react";

function EyeIcon({type, size, color}) {

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.35726 8.71467C1.31125 8.57639 1.31125 8.42694 1.35726 8.28867C2.28193 5.50667 4.90659 3.5 7.99993 3.5C11.0919 3.5 13.7153 5.50467 14.6419 8.28533C14.6886 8.42333 14.6886 8.57267 14.6419 8.71133C13.7179 11.4933 11.0933 13.5 7.99993 13.5C4.90793 13.5 2.28393 11.4953 1.35726 8.71467Z" stroke="#012758" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 8.5C10 9.03043 9.78929 9.53914 9.41421 9.91421C9.03914 10.2893 8.53043 10.5 8 10.5C7.46957 10.5 6.96086 10.2893 6.58579 9.91421C6.21071 9.53914 6 9.03043 6 8.5C6 7.96957 6.21071 7.46086 6.58579 7.08579C6.96086 6.71071 7.46957 6.5 8 6.5C8.53043 6.5 9.03914 6.71071 9.41421 7.08579C9.78929 7.46086 10 7.96957 10 8.5Z" stroke="#012758" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default EyeIcon;