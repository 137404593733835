import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PageBody from "../general/PageBody";
import { CheckIcon } from '@heroicons/react/20/solid';
import VectorIcon from "../../../assets/svgs/VectorIcon";
import BlockSearchIcon from "../../../assets/svgs/BlockSearchIcon";
import BarsUserIcon from "../../../assets/svgs/BarsUserIcon";
import BuildingIcon from "../../../assets/svgs/BuildingIcon";
import MindMapIcon from "../../../assets/svgs/MindMapIcon";
import PercentDiscountsIcon from "../../../assets/svgs/PercentDiscountsIcon";
import withGlobalProviders from "../../general/withGlobalProvider";

import {createHandleSubmit} from "../../../utility/helpers";
import toast from "react-hot-toast";
import {subscribe} from "../../../actions/subscribeActions";

function Index() {
  const user = useSelector((state) => state.session.currentUser);
  const dispatch = useDispatch();

  if (!user) return null;

  const { subscribeType, subscription } = user.attributes;
  const {
    first_name,
    last_name,
    office_phone,
    cellular_phone,
    company_name,
    title
  } = user.attributes.profile;

  const isActiveSubscription = subscribeType && [ 'active', 'trial_will_end' ].includes(subscription);

  const initialValues = {
    firstName: first_name || "",
    lastName: last_name,
    officePhone: office_phone,
    personalPhone: cellular_phone,
    companyName: company_name,
    title: title,
    subscription: {},
    profileState: user.attributes.profileState ? {
      value: user.attributes.profileState,
      label: user.attributes.profileState,
    } : null,
  };

  const onSubscribe = (e) => {
    const type = e.target.id.toString();
    dispatch(subscribe({type}))
      .then(({data: {url}}) => {
        if(url) {
          window.location.assign(url);
        }
      })
      .catch(() => {
        toast.error("There is a problem to subscribe.");
      })
  };

  return (
    <PageBody>
      <div className="flex flex-col">
        <div className="absolute -z-50 bg-gray-100 w-full" style={{height: "450px"}}></div>
        <div className="flex flex-col xl:px-32 md:px-20 px-10 py-40">
          <h1 className="w-full lg:ml-32 lg:w-1/2">
            <span className="mt-1 block text-4xl font-bold tracking-tight sm:text-3xl xl:text-4xl 3xl:text-5xl  4xl:text-6xl">
              <span className="block mb-3 text-black text-center lg:text-justify">
                Search our entire lender universe for FREE using our <span className="text-congress-blue-600">Lender Funnel</span> and <span className="text-congress-blue-600">Prospector tools & resources</span>.
              </span>
            </span>
          </h1>
          <div className="flex md:flex-row flex-col mt-20 md:mt-10 justify-between md:gap-x-8 gap-y-4">
            <div className="flex flex-col md:w-1/3 w-full border-2 bg-white border-slate-800 rounded-md h-auto py-5">
              <p className="text-black text-center font-bold text-2xl">Lender Spotlight</p>
              <p className="text-black text-center text-sm mt-3">Subscribe to our Lender Spotlight <br /> for added benefits</p>
              <div className="flex flex-row mt-5 items-center justify-center">
                <p className="text-lg text-black font-bold">$99</p>
                <p className="text-sm ml-2 align-bottom">per month</p>
              </div>
              <a className="text-black text-center text-congress-blue-600 font-bold text-md mt-3" href="#lender_spotlight">Learn more</a>
            </div>
            <div className="flex flex-col md:w-1/3 w-full border-2 bg-white border-slate-800 rounded-md h-auto py-5">
              <p className="text-black text-center font-bold text-2xl">Borrower Chat Rooms</p>
              <p className="text-black text-center text-sm mt-3">Connect, collaborate, grow share intel on <br /> lenders</p>
              <div className="flex flex-row mt-5 items-center justify-center">
                <p className="text-lg text-black font-bold">$34</p>
                <p className="text-sm ml-2 align-bottom">per month</p>
              </div>
              <a className="text-black text-center text-congress-blue-600 font-bold text-md mt-3" href="#borrower_chat">Learn more</a>
            </div>
            <div className="flex flex-col relative md:w-1/3 w-full border-2 bg-white border-slate-800 rounded-md h-auto">
              <div className="hidden xl:block absolute top-0 right-0 bg-green-400 text-white p-2 w-1/3 text-center text-md rounded-lg h-1/10">BEST CHOICE</div>
              <div className="mt-6 text-center">
                <p className="text-black text-center font-bold text-2xl">Bundle</p>
                <p className="text-black text-center text-sm mt-3">Choose to bundle and save BIG</p>
                <div className="flex flex-row mt-5 items-center justify-center">
                  <p className="text-lg text-black font-bold">$106.40</p>
                  <p className="line-through decoration-slate-200 text-md text-gray decoration-gray-300 ml-2">$133</p>
                  <p className="text-sm ml-2 align-bottom">per month</p>
                </div>
                <p className="text-black text-center text-sm">(Use Promo Code 20FOR200)</p>
                <a className="text-black text-center text-congress-blue-600 font-bold text-md mt-3 block" href="#bundles">Learn more</a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:px-64 md:px-20 px-10" id="bundles">
          <div className="h-7 h-12 w-36 w-1/2 text-center items-center p-1 rounded-md text-sm font-medium text-white bg-green-400">
            Limited Time Offer
          </div>
          <p className="text-4xl text-center 2xl:text-left text-black font-bold mt-2">Bundle both Lender Spotlight and Borrower Chat Room for big savings!</p>
          <div className="relative mt-10 border-2 w-4/5 2xl:w-3/5 border-green-400 rounded-md">
            <span
              className="absolute border-solid border-green-400 right-0"
              style={{
                borderWidth: "30px",
                borderColor: "rgb(52 211 153) rgb(52 211 153) transparent transparent"
              }}>
            </span>
            <PercentDiscountsIcon className="text-white absolute top-2 right-2 h-5 w-5" />
            <div className="p-8">
              <p className="text-2xl text-black font-bold">Save 20%</p>
              <p className="text-lg mt-4 w-full">Bundle Lender Spotlight with Community Chat AND share to social media using our prepopulated posts.</p>
              <div className="mt-6">
                <div className="flex items-center">
                  <p className="text-lg text-black font-bold">$106.40</p>
                  <p className="line-through decoration-slate-200 text-md text-gray ml-2">$133</p>
                  <p className="text-sm ml-2">per month</p>
                </div>
                <p className="text-black text-left text-sm">(Use Promo Code 20FOR200)</p>
                <p className="text-md text-gray w-full mt-2">Discount valid for 12-month subscription</p>
                <p className="text-md text-gray w-full">30-day free trial</p>
                <div className="flex flex-row w-full mt-2">
                  <VectorIcon />
                  <p className="text-md text-gray ml-2">Offer valid only for the first 200 early adopters</p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-xl text-black mt-10">The Ultimate Multifamily and CRE Power Bundle: Elevate, Connect, and Conquer</p>
        </div>
        <div className="flex flex-col bg-white xl:px-64 md:px-20 px-10 py-16 mt-16">
          <div className="flex flex-col w-full">
            <p className="text-4xl font-bold w-full mb-8">Subscribe now and unlock the ultimate bundle for success!</p>
            <p className="text-lg w-full">Unleash the full potential of everything LenderPrism offers with our all-in-one subscription package. By combining premium search results placement, access to an exclusive chat room, and a private community of lenders and brokers, this bundle is your passport to unparalleled success.</p>
            <p className="text-lg w-full mt-6">Bundle together Lender Spotlight, Community Access and Premium Placement. Save BIG when you share to social media using our prepopulated posts.</p>
          </div>
          <div className="flex flex-col bg-white w-full mt-12 border-2 border-congress-blue-600 border-solid rounded-md h-auto p-8">
            <div className="flex sm:flex-row flex-col items-center justify-between mb-8">
              <p className="text-2xl font-bold">Bundle</p>
              <div className="flex flex-row items-center">
                <div className='flex flex-col'>
                  <div className="flex items-center">
                    <p className="text-lg text-black font-bold">$106.40</p>
                    <p className="line-through decoration-slate-200 text-md text-gray ml-2">$133</p>
                    <p className="text-sm ml-2">per month</p>
                  </div>
                  <div className="text-black text-center text-sm">(Use Promo Code 20FOR200)</div>
                </div>
                {
                  isActiveSubscription && subscribeType === 'borrower_bundle' ?
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-gray-400"
                      disabled
                    >
                      Subscribed
                    </button> :
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                      id="borrower_bundle"
                      onClick={onSubscribe}>
                      Subscribe now
                    </button>
                }
              </div>
            </div>
            <hr />
            <div className="flex flex-col lg:flex-row mt-6">
              <div className="flex flex-col lg:w-1/2">
                <p className="text-md text-black mb-2">30-day free trial</p>
                <p className="text-md text-black mb-2">Cancel anytime. No long-term commitments for monthly plans.</p>
                <p className="text-md text-black mb-2">Search for active lenders in the Lender Funnel for free</p>
                <p className="text-md text-black mb-6">Use The Prospector to search for lenders banks and <br /> credit unions by geography for free</p>
              </div>
              <div className="flex flex-col lg:w-1/2 ml-6 lg:ml-12">
                <p className="text-md text-black mb-2">Get these templates for free:</p>
                <ul className="list-disc list-outside">
                  <li>Multifamily Debt & Equity Model</li>
                  <li>Multifamily Loan Submission Template / Executive Summary</li>
                  <li>Loan Submission Checklist</li>
                  <li>Personal Financial Statement (PFS) + Schedule of Real Estate Owned (SREO) Template</li>
                  <li>Bio for Multifamily Owner-Operators </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="py-16 bg-congress-blue-600 text-white" id="lender_spotlight">
          <div>
            <div className="xl:px-64 md:px-20 px-10">
              <p className="text-4xl font-bold mb-4">Lender Spotlight</p>
              <p className="text-xl mb-9 w-full">
                Unlock Exclusive Insights with a Subscription to Our Private Community!
              </p>
            </div>
            <div className="flex text-black md:flex-row rounded-md flex-col mt-10 justify-between md:gap-x-10 gap-y-4 xl:px-48 md:px-36 sm:px-32 px-30 md:mx-20 mx-10 py-10 bg-gray-100">
              <div className="flex flex-col md:w-1/4">
                <div className="w-16 h-16 p-5 bg-gray-300 mb-8">
                  <BlockSearchIcon />
                </div>
                <p className="text-md">Explore many more loan options for multifamily and CRE transactions</p>
              </div>
              <div className="flex flex-col md:w-1/4">
                <div className="w-16 h-16 p-5 bg-gray-300 mb-8">
                  <BarsUserIcon />
                </div>
                <p className="text-md">Stay one step ahead with insider knowledge from active lenders</p>
              </div>
              <div className="flex flex-col md:w-1/4">
                <div className="w-16 h-16 p-5 bg-gray-300 mb-8">
                  <BuildingIcon />
                </div>
                <p className="text-md">Position yourself as an informed and strategic player in the multifamily / commercial real estate market</p>
              </div>
              <div className="flex flex-col md:w-1/4">
                <div className="w-16 h-16 p-5 bg-gray-300 mb-8">
                  <MindMapIcon />
                </div>
                <p className="text-md">Expand your lender network with Lender Spotlight</p>
              </div>
            </div>
          </div>
          <div className="xl:px-64 md:px-20 px-10">
            <p className="text-2xl md:mt-30 mt-20">Here's how Lender Spotlight will help you to grow your business.</p>
            <div className="w-full mt-10">
              <p className="text-xlmb-6">Live Speaker Sessions</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Hear multifamily & CRE lenders discuss their loan programs, appetite and process.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Access live, exclusive presentations on their latest products and services.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Engage with active commercial real estate lenders.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Make new lender connections.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Save time with curated content coordinated exclusively for subscribers.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Access current information without the hassle of extensive research.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Maximize your productivity and focus on what matters most.</p>
            </div>
            <div className="w-full mt-12">
              <p className="text-xl mb-6">Diverse Lender Lineup</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Gain insights from a diverse panel of lenders, ensuring a comprehensive understanding of the current market.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Explore a variety of financing options and strategies for your specific needs.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Regularly updated lineup to keep you ahead of market trends.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Connect with a community of like-minded professionals.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Build relationships with potential lenders.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Expand your network and open doors to new opportunities.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-congress-blue-600 xl:px-64 md:px-20 px-10 py-12">
          <div className="flex flex-col w-full text-white">
            <p className="text-4xl font-bold w-full mb-8">Ready to grow your lender network?</p>
            <p className="text-lg w-full">Subscribe now and join an exclusive group of brokers, borrowers and lenders.</p>
          </div>
          <div className="flex flex-col bg-white w-full mt-12 rounded-md h-auto p-8">
            <div className="flex sm:flex-row flex-col justify-between items-center mb-8">
              <p className="text-2xl font-bold">Lender Spotlight</p>
              <span className="flex flex-row items-center">
                <span className="flex mr-4 items-center">
                  <p className="text-lg text-black font-bold">$99</p>
                  <p className="text-sm ml-2">per month</p>
                </span>
                {
                  isActiveSubscription && subscribeType === 'borrower_lender_spotlight' ?
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-gray-400"
                      disabled
                    >
                      Subscribed
                    </button> :
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                      id="borrower_lender_spotlight"
                      onClick={onSubscribe}>
                      Subscribe now
                    </button>
                }
              </span>
            </div>
            <hr />
            <div className="flex flex-col lg:flex-row mt-6">
              <div className="flex flex-col lg:w-1/2">
                <p className="text-md text-black mb-2">30-day free trial</p>
                <p className="text-md text-black mb-2">Cancel anytime. No long-term commitments for monthly plans.</p>
                <p className="text-md text-black mb-2">Search for active lenders in the Lender Funnel for free</p>
                <p className="text-md text-black mb-6">Use The Prospector to search for lenders banks and <br /> credit unions by geography for free</p>
              </div>
              <div className="flex flex-col lg:w-1/2 ml-6 lg:ml-12">
                <p className="text-md text-black mb-2">Get these templates for free:</p>
                <ul className="list-disc list-outside">
                  <li>Multifamily Debt & Equity Model</li>
                  <li>Multifamily Loan Submission Template / Executive Summary</li>
                  <li>Loan Submission Checklist</li>
                  <li>Personal Financial Statement (PFS) + Schedule of Real Estate Owned (SREO) Template</li>
                  <li>Bio for Multifamily Owner-Operators </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:mt-16 mt-6" id="borrower_chat">
          <div className="xl:px-64 md:px-20 px-10">
            <p className="text-3xl text-black font-bold mb-4">Borrower Chat</p>
            <p className="text-lg text-black mb-6 w-full">
              Connect, collaborate, grow share intel on lenders with a Subscription to Our Private Community Exclusively for <br /> Multifamily and CRE Owner-Operators
            </p>
            <p className="text-lg text-black mb-12 md:w-9/10 w-full">
              Welcome to the Ultimate Hub for Multifamily and CRE Owner-Operators – where collaboration meets success! <br /> Unlock the power of connection and knowledge-sharing with our subscription plan designed exclusively for you. Here's why this opportunity is a game-changer:
            </p>
          </div>
          <div className="flex flex-col lg:flex-row mt-10 py-10 xl:px-64 md:px-20 px-10">
            <div className="flex flex-col md:w-1/2 w-full">
              <div className="px-5">
                <p className="text-lg text-black font-bold mb-6">Dynamic Networking</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Connect with a community of like-minded owner-operators.</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Forge valuable relationships, exchange insights, and build a powerful professional network.</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Open doors to new strategic partnerships, lucrative collaborations and business opportunities.</p>
              </div>
              <div className="mt-8 px-5">
                <p className="text-lg text-black font-bold mb-6">Real-Time Collaboration</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Engage in live discussions and collaborate with fellow investors.</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Share best practices, strategies, and success stories.</p>
                <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Leverage collective knowledge for smarter decision-making.</p>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-5">
              <p className="text-lg text-black font-bold mb-6">Professional Growth & Collaborative Learning</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Stay at the forefront of the financing landscape.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Discover new funding opportunities and financing options.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Learn from the experiences of your peers and industry experts.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Share challenges and triumphs, creating a culture of collective growth.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Leverage the power of a community committed to mutual success.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Gain valuable market insights and intelligence.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Stay informed about the latest lending products and industry developments.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Position yourself as a strategic player, making informed decisions for maximum returns.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Gain a competitive edge in negotiations and deal structuring.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:px-64 md:px-20 px-10 py-12 mt-16">
          <div className="flex flex-col w-full">
            <p className="text-4xl font-bold w-full mb-8">Ready to take your business up another level?</p>
            <p className="text-lg w-full">Subscribe now and join an exclusive community of like-minded owner-operators.</p>
          </div>
          <div className="flex flex-col w-full mt-12 border-solid border-2 border-congress-blue-600 rounded-md h-auto p-8">
            <div className="flex sm:flex-row flex-col items-center justify-between itmes-center mb-8">
              <p className="text-2xl font-bold">Borrower Chat</p>
              <span className="flex flex-row items-center">
                <span className="flex mr-4 items-center">
                  <p className="text-lg text-black font-bold">$34</p>
                  <p className="text-sm ml-2">per month</p>
                </span>
                {
                  isActiveSubscription && subscribeType === 'borrower_chat' ?
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-gray-400"
                      disabled
                    >
                      Subscribed
                    </button> :
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                      id="borrower_chat"
                      onClick={onSubscribe}>
                      Subscribe now
                    </button>
                }
              </span>
            </div>
            <hr />
            <div className="flex flex-col lg:flex-row mt-6">
              <div className="flex flex-col lg:w-1/2">
                <p className="text-md text-black mb-2">30-day free trial</p>
                <p className="text-md text-black mb-2">Cancel anytime. No long-term commitments for monthly plans.</p>
                <p className="text-md text-black mb-2">Search for active lenders in the Lender Funnel for free</p>
                <p className="text-md text-black mb-6">Use The Prospector to search for lenders banks and <br /> credit unions by geography for free</p>
              </div>
              <div className="flex flex-col lg:w-1/2 ml-6 lg:ml-12">
                <p className="text-md text-black mb-2">Get these templates for free:</p>
                <ul className="list-disc list-outside">
                  <li>Multifamily Debt & Equity Model</li>
                  <li>Multifamily Loan Submission Template / Executive Summary</li>
                  <li>Loan Submission Checklist</li>
                  <li>Personal Financial Statement (PFS) + Schedule of Real Estate Owned (SREO) Template</li>
                  <li>Bio for Multifamily Owner-Operators </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
