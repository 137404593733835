import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import concat from "lodash/concat";
import without from "lodash/without";
import uniq from "lodash/uniq";
import union from "lodash/union";
import intersection from "lodash/intersection";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { CreditCardIcon, UsersIcon, ListBulletIcon, GlobeAmericasIcon } from "@heroicons/react/20/solid";

import List from "./List";
import Map from "./Map";
import {
  fetchSearch,
  fetchSearchResults,
} from "../../../../../actions/searchActions";

import SelectedList from "./SelectedList";
import { fetchUserSavedOrganizations } from "../../../../../actions/userSavedOrganizationActions";

const tabs = [
  { name: "List", icon: <ListBulletIcon className="w-4" /> },
  { name: "Map", icon: <GlobeAmericasIcon className="w-4" /> },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ShowSection({ searchId }) {
  const dispatch = useDispatch();
  const { loaded, items } = useSelector((state) => state.organizations);
  const userSavedOrganizations = useSelector(
    (state) => state.userSavedOrganizations.items
  );

  const [currentTab, setCurrentTab] = useState("Map");
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const search = useSelector((state) => state.searches.items[searchId]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      dispatch(fetchSearchResults(searchId));
      dispatch(fetchSearch(searchId));
      dispatch(fetchUserSavedOrganizations(searchId));
      setLoading(false);
    }, 5000);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setSelectedOrganizations((previousSelectedOrganizations) => {
        const savedOrganizations = items
          ? intersection(userSavedOrganizations, Object.keys(items))
          : [];
        return uniq(union(previousSelectedOrganizations, savedOrganizations));
      });
      setLoading(false);
    }, 5000);
  }, [userSavedOrganizations, items]);
  if (!loaded || !search || !userSavedOrganizations) return null;

  const { name, locationRange, postalCodeLongitude, postalCodeLatitude } =
    search.attributes;

  const updateSelectedOrganizations = (organizationId, remove = false) => {
    let actionMethod = remove ? without : concat;

    setSelectedOrganizations((previousSelectedOrganizations) =>
      uniq(actionMethod(previousSelectedOrganizations, organizationId))
    );
  };
  const showSectionDetails = () => {
    return (
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow-lg sm:rounded-lg sm:overflow-hidden">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-4 sm:px-6 flex flex-row justify-between">
              <div
                id="notes-title"
                className="text-lg leading-5 font-medium text-gray-900"
              >
                Search Results
              </div>
              <div>
                <div className="sm:hidden">
                  {/* <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label> */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-congress-blue-500 focus:border-congress-blue-500 border-gray-300 rounded-md"
                    defaultValue={currentTab}
                    onChange={(event) => setCurrentTab(event.target.value)}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name} value={tab.name}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="flex space-x-2  bg-gray-100 border rounded-lg border-gray-300 p-1" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <a
                        key={tab.name}
                        className={classNames(
                          tab.name === currentTab
                            ? "bg-white text-congress-blue-500 shadow"
                            : "text-gray-500 hover:text-gray-700",
                          " px-2.5 py-1.5 font-small text-xs rounded-md cursor-pointer flex justify-center items-center"
                        )}
                        aria-current={
                          tab.name === currentTab ? "page" : undefined
                        }
                        onClick={() => setCurrentTab(tab.name)}
                      >
                        {tab.icon}
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            {currentTab === "List" && (
              <List
                {...{
                  selectedOrganizations,
                  updateSelectedOrganizations,
                }}
              />
            )}
            {currentTab === "Map" && (
              <Map
                {...{
                  locationRange,
                  postalCodeLongitude,
                  postalCodeLatitude,
                  selectedOrganizations,
                  updateSelectedOrganizations,
                  userSavedOrganizations,
                }}
              />
            )}
            <dd className="">
              {selectedOrganizations.length === 0 && (
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6 flex flex-col">
                  <div className="flex items-center space-x-4">
                    Selected Lenders
                  </div>
                  {/* add p tag with grey color. */}
                  <p className="text-gray-500 text-sm my-4">
                    Select a lender to add to your list
                  </p>
                  <div className="w-full flex justify-end">
                    <button
                      className="inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-[#8093AC] text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm
                    hover:bg-[#8093AC] cursor-not-allowed"
                    >
                      Save Selected Lenders
                    </button>
                  </div>
                </div>
              )}
              <SelectedList
                items={selectedOrganizations}
                {...{
                  selectedOrganizations,
                  updateSelectedOrganizations,
                  setSelectedOrganizations,
                  searchId,
                  userSavedOrganizations,
                }}
              />
            </dd>
          </div>
        </div>
      </section>
    );
  };

  return <>{loading ? <Skeleton height={500} /> : showSectionDetails()}</>;
}
