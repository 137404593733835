import React from "react";

function DelIcon({type, size, color}) {

  return (
    <svg className={`${color}`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.82667 6.50035L9.596 12.5003M6.404 12.5003L6.17333 6.50035M12.8187 4.36035C13.0467 4.39501 13.2733 4.43168 13.5 4.47101M12.8187 4.36035L12.1067 13.6157C12.0776 13.9925 11.9074 14.3445 11.63 14.6012C11.3527 14.8579 10.9886 15.0005 10.6107 15.0003H5.38933C5.0114 15.0005 4.64735 14.8579 4.36999 14.6012C4.09262 14.3445 3.92239 13.9925 3.89333 13.6157L3.18133 4.36035M12.8187 4.36035C12.0492 4.24403 11.2758 4.15574 10.5 4.09568M3.18133 4.36035C2.95333 4.39435 2.72667 4.43101 2.5 4.47035M3.18133 4.36035C3.95076 4.24403 4.72416 4.15575 5.5 4.09568M10.5 4.09568V3.48501C10.5 2.69835 9.89333 2.04235 9.10667 2.01768C8.36908 1.99411 7.63092 1.99411 6.89333 2.01768C6.10667 2.04235 5.5 2.69901 5.5 3.48501V4.09568M10.5 4.09568C8.83581 3.96707 7.16419 3.96707 5.5 4.09568" stroke="#954F45" strokeOpacity="0.7" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default DelIcon;
