import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
function Navigation() {
  const [navActive, setNavActive] = useState('home');
  const [top, setTop] = useState(true);
  const location = useLocation();

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  const navHandler = (pathname) => {
    if(pathname.indexOf('broker') > -1) setNavActive('broker');
    else if(pathname.indexOf('borrower') > -1) setNavActive('borrower');
    else if(pathname.indexOf('lender') > -1) setNavActive('lender');
    else setNavActive('home');
  }

  useEffect(() => {
    navHandler(location.pathname);
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header>
      <Popover
        className={`fixed w-full bg-opacity-90 z-30 transition duration-300 ease-in-out bg-white`}
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-5 sm:px-6">
              <div className="flex items-center justify-between h-16 md:h-20">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="#" className="shrink-0 mr-4">
                    <span className="sr-only">LenderPrism</span>
                    <img
                      className="h-12 object-cover object-center sm:rounded-l-md"
                      width="155.86"
                      // src="https://lenderprism-public.s3.us-east-2.amazonaws.com/logo_draft.png"
                      src="https://lenderprism-public.s3.us-east-2.amazonaws.com/logo-dark-blue.png"
                      alt=""
                    />
                  </a>
                </div>
                <div className="-mr-2 -my-2 lg:hidden">
                  <Popover.Button className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden">
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group
                  as="nav"
                  className="hidden lg:flex space-x-10"
                ></Popover.Group>
                <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                  <a
                    href="/login"
                    className={`btn ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-congress-blue-600 shadow-sm text-congress-blue-600 font-medium rounded-full hover:font-bold hover:text-congress-blue-600 hover:bg-white`}
                  >
                    Log in
                  </a>
                  <a
                    href="/registration"
                    className={`btn ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-white font-medium rounded-full bg-congress-blue-600 hover:font-bold`}
                  >
                    Sign up
                  </a>
                </div>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute z-30 top-0 inset-x-0 transition transform origin-top-right lg:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-12 object-cover object-center sm:rounded-l-md"
                          width="155.86"
                          // src="https://lenderprism-public.s3.us-east-2.amazonaws.com/logo_draft.png"
                          src="https://lenderprism-public.s3.us-east-2.amazonaws.com/logo-dark-blue.png"
                          alt="LenderPrism Logo"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-congress-blue-500">
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="pb-6 px-5">
                    <div className="flex flex-col justify-center items-center">
                      <a
                        href="/registration"
                        className="w-1/2 flex items-center justify-center bg-congress-blue-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:congress-blue-600 mt-5"
                      >
                        Sign up
                      </a>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        Existing user?{" "}
                        <a href="/login" className="text-gray-900">
                          Sign in
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </header>
  );
}

export default Navigation;
