import React from "react";

export default function EmptyState({ next }) {
  return (
    <div
      className="bg-congress-blue-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4"
    >
      <div className="p-8" style={{ width: "120%" }}>
        <div className="lg:self-center">
          <h2
            className={
              next
                ? "text-3xl font-extrabold text-gray-600"
                : "text-3xl font-extrabold text-white"
            }
          >
            <span className="block text-white text-3xl">
              Ready to fund your loan?
            </span>
          </h2>
          <p className="mt-4 pr-2 text-base leading-6 text-white">
            Or use The Prospector, a simple search tool. Use it to search more than 10,000 banks and credit unions in
            over 100,000 locations nationwide. Enter the zip code in which the property is located and search by
            distance. Select lenders to add to your list. Contact banks and credit unions on your own
          </p>
          <a
            href="./lenders/search/new"
            className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-congress-blue-600 hover:bg-gray-50"
          >
            Search Banks and Credit Unions by Radius
          </a>
        </div>
      </div>
      <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
        <img
          className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
          src="https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/prospector-search-result.png"
          alt="Search result"
        />
      </div>
    </div>
  );
}
