import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import withGlobalProviders from "../../../general/withGlobalProvider";
import PageBody from "../../general/PageBody";
import Items from "./index/Items";
import { fetchSearches } from "../../../../actions/searchActions";

function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSearches());
  }, [dispatch]);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col gap-3">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Prospector
            </h1>
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">Start a search based on comprehensive <b>geographic, institution type, <br></br>financing types,</b> and other options.</p>
              <a
                href="/lenders/search/new"
                className="py-3 px-6 inline-flex items-center border border-transparent rounded shadow-lg text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
              >
                Find lenders
              </a>
            </div>
            <p className="text-lg font-bold mt-7">
              Lender Searches
            </p>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main>
              {/* Page header */}
              <div className="mt-8">
                <Items />
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
