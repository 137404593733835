import React, {useState} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import join from "lodash/join";

import { Transition } from "@headlessui/react";

import { DocumentMagnifyingGlassIcon } from "@heroicons/react/20/solid";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import EyeIcon from "../../../../../assets/svgs/EyeIcon";
import EditIcon from "../../../../../assets/svgs/EditIcon";
import DelIcon from "../../../../../assets/svgs/DelIcon";

const mokeLoanRequests = [
  {
    id: "123",
    attributes: {
      amount: "945345435",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
  {
    id: "234",
    attributes: {
      amount: "90464560",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
  {
    id: "345",
    attributes: {
      amount: "9000000",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
  {
    id: "456",
    attributes: {
      amount: "9000000",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
  {
    id: "567",
    attributes: {
      amount: "9000000",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
  {
    id: "678",
    attributes: {
      amount: "9000000",
      city: "New York",
      state: "Texas",
      postalCode: "35005",
      financeType: "Multifamily",
      createdAt: "2023-10-06T08:58:13.314Z",
      updatedAt: "2023-10-06T08:58:13.314Z",
    },
    lenders: [
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen123ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jen456ny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jenndfgy",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
      {
        bankName: "bankname 1",
        minimumLoanAmount: "1000000",
        maximumLoanAmount: "9999999",
        financeServices: "Permanent / Long-term",
        bankers: [
          {
            firstName: "Jewernny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
          {
            firstName: "Jensdfny",
            lastName: "Wilson",
            email: "jenny.wilson@gmail.com",
            phoneNumber: "555 555 1111",
          },
        ],
      },
    ],
  },
];

function ListItem({ id }) {
  // const loanRequest = useSelector(state => state.loanRequests.items[id])
  const [loanRequest, setLoanRequest] = useState(
    mokeLoanRequests.filter((request) => request.id == id)[0]
  );
  if (!loanRequest) return null;

  const { amount, city, state, postalCode, financeType, createdAt, updatedAt } =
    loanRequest.attributes;

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      <div>
        <div className="bg-white overflow-hidden sm:rounded-lg">
          <div className="px-4 py-3 sm:px-6 flex justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex">
                <DocumentMagnifyingGlassIcon className="h-5 w-5 mr-1" />
                Request of $
                {(amount / 100.0)
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                for {financeType || "Unspecified Property"}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                <span className="font-bold">Created:</span>{" "}
                {moment(createdAt).format("MMMM Do [at] h:mm A ")}{" "}
                <span className="px-3">|</span>
                <span className="font-bold">Updated:</span>{" "}
                {moment(updatedAt).format("MMMM Do [at] h:mm A ")}
              </p>
            </div>
            <div className="flex items-center">
              <span className="relative z-0 inline-flex">
                <a
                  href={"./lenders/saved/" + id}
                  className="relative inline-flex items-center gap-1 px-4 py-2 bg-white text-sm text-gray-700 font-bold hover:bg-gray-50 hover:border-congress-blue-500"
                >
                  <EyeIcon />
                  View saved lenders
                </a>
              </span>
              <span className="relative z-0 inline-flex cursor-pointer">
                <a
                  className="relative inline-flex items-center  gap-1 px-4 py-2 bg-white text-sm text-customYellow font-bold hover:bg-gray-50"
                >
                  <DelIcon color="text-red-600" />
                  Delete
                </a>
              </span>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">City</dt>
                <dd className="mt-1 text-sm text-gray-900">{city}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">State</dt>
                <dd className="mt-1 text-sm text-gray-900">{state}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Postal Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{postalCode}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Type of Financing
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {/* {join(typeOfFinancing, ", ")} */}
                  {financeType}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default withGlobalProviders(ListItem);
