import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  BuildingOfficeIcon,
  EnvelopeOpenIcon,
  PhoneIcon,
  EllipsisVerticalIcon,
  UserIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import BankerForm from "../../banker/forms/BankerForm";
function DropdownItem({ id, bankers, branche }) {
  const item = useSelector((state) => state.organizationBranches.items[id]);
  // const item = {
  //   attributes: {
  //     name: "name",
  //     street: "street",
  //     city: "city",
  //     state: "state",
  //   },
  // };
  // const mokeBankers = [
  //   {
  //     id: 1,
  //     firstName: "John",
  //     lastName: "Doe",
  //     email: "johndoe@example.com",
  //     phoneNumber: "123-456-7890",
  //   },
  //   {
  //     id: 2,
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     email: "janesmith@example.com",
  //     phoneNumber: "987-654-3210",
  //   },
  // ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddBankerModalOpen, setIsAddBankerModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openAddBankerModal = () => {
    setIsAddBankerModalOpen(true);
  };

  const closeAddBankerModal = () => {
    setIsAddBankerModalOpen(false);
  };

  return (
    <div className="block ml-3">
      <div className="px-3 py-4 ml-8">
        <div className="flex flex-row justify-start">
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <p className="flex flex-row items-center text-md font-medium truncate text-gray-400">
                <BuildingLibraryIcon
                  className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-black">{item.attributes.name}</span>,{" "}
                <p className="flex flex-row text-sm font-normal">
                  {/* <MapPinIcon
                  className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                /> */}
                  <span>{item.attributes.street}</span>,{" "}
                  <span>{item.attributes.city}</span>,{" "}
                  <span>{item.attributes.state}</span>
                </p>
              </p>
              <a
                onClick={openModal}
                className="text-blue-800 hover:cursor-pointer pr-4"
              >
                view details
              </a>
            </div>

            <div className="w-full flex flex-col text-sm ml-4 gap-2 font-normal">
              <div className="w-full mb-1" />
              {bankers.length > 0 ? (
                bankers.map((banker) => (
                  <p
                    key={banker.id}
                    className="flex flex-row gap-2 items-center w-full"
                  >
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="overflow-hidden whitespace-normal mr-12">
                      {banker.firstName} {banker.lastName}
                    </span>
                    <a
                      href={`mailto:${banker.email}`}
                      title={`Email: ${banker.email}`}
                      className="flex gap-3 cursor-pointer mr-12"
                    >
                      <EnvelopeIcon className="h-4 w-4" aria-hidden="true" />
                      {banker.email}
                    </a>
                    <a
                      href={`tel: +1 ${banker.phoneNumber}`}
                      title={`Call: +1 ${banker.phoneNumber}`}
                      className="flex gap-3 cursor-pointer mr-12"
                    >
                      <PhoneIcon className="h-4 w-4" />
                      {banker.phoneNumber}
                    </a>
                  </p>
                ))
              ) : (
                <span className="italic text-gray-400">There is no banker</span>
              )}
              <span
                className="text-blue-800 font-bold flex gap-3 cursor-pointer mr-3 w-fit"
                onClick={openAddBankerModal}
              >
                <PlusIcon className="h-4 w-4 text-blue-800" />
                Add Contact
              </span>
            </div>
          </div>
        </div>
      </div>

      <div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50">
              <div className="flex flex-col border-b border-gray-300 p-6">
                <p className="text-md font-medium text-black">
                  {item.attributes.name}
                </p>
                <p className=" text-base font-medium text-gray-400">
                  <span>{item.attributes.street}</span>,{" "}
                  <span>{item.attributes.city}</span>,{" "}
                  <span>{item.attributes.state}</span>
                </p>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    First Name
                  </p>
                  <p className="text-md font-medium text-black">first name</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Last Name
                  </p>
                  <p className="text-md font-medium text-black">Last name</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">Email</p>
                  <p className="text-md font-medium text-black">email</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Mobile Number
                  </p>
                  <p className="text-md font-medium text-black">
                    mobile number
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">City</p>
                  <p className="text-md font-medium text-black">city</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">State</p>
                  <p className="text-md font-medium text-black">state</p>
                </div>
              </div>
              <div className="flex flex-col gap-2 p-6">
                <p>Notes</p>
                <p>-</p>
              </div>
              <p
                onClick={closeModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                X
              </p>
            </div>
          </div>
        )}

        {isAddBankerModalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50">
              <p
                onClick={closeAddBankerModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                X
              </p>
              <BankerForm organizationBranchId={id} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropdownItem;
