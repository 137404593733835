const lenderSpotlight = { title: 'Lender Spotlight', link: 'https://the-multifamily-zone.mn.co/spaces/11762349?utm_source=manual' };
const brokerChatRoom = { title: 'Broker Chat Room', link: 'https://the-multifamily-zone.mn.co/spaces/13075637?utm_source=manual' };
const borrowerChatRoom = { title: 'Borrower Chat Room', link: 'https://the-multifamily-zone.mn.co/spaces/13075663?utm_source=manual' };

export const availableLinks = {
  borrower_bundle: [ lenderSpotlight, borrowerChatRoom ],
  borrower_lender_spotlight: [ lenderSpotlight ],
  borrower_chat: [ borrowerChatRoom ],
  broker_bundle: [ lenderSpotlight, brokerChatRoom ],
  broker_lender_spotlight: [ lenderSpotlight ],
  broker_community_chat: [ brokerChatRoom ],
  broker_premium_placement: [],
  lender_premium_placement: []
};
