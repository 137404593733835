import React, { Fragment } from "react";
import map from "lodash/map";
import PageBody from "../general/PageBody";
import withGlobalProviders from "../../../components/general/withGlobalProvider";
import Table, {
  SelectColumnFilter,
  StatusPill,
  PriceRangeColumnFilter,
} from "./dashboard/table";
function Dashboard({ loan_requests }) {
  // const user = useSelector((state) => state.session.currentUser);
  const getData = () => loan_requests;
  const jsonData = React.useMemo(() => getData(), []);
  let obj = JSON.parse(jsonData);
  let data = obj.data;
  const columns = React.useMemo(
    () => [
      {
        Header: "Property Type",
        accessor: "attributes.propertyType",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Loan amount",
        accessor: "attributes.purchasePrice",
        Cell: ({ value }) => {
          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value);
        },
      },
      {
        Header: "State",
        accessor: "attributes.locationState",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "City",
        accessor: "attributes.borrowerCity",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Type",
        accessor: "attributes.transactionType",
      },
      {
        Header: "Type of financing",
        accessor: "attributes.typeOfFinancing",
      },
      // {
      //   Header: "Start Date",
      //   accessor: "attributes.createdAt",
      //   Cell: ({ value }) => {
      //     const date = new Date(value);
      //     return (
      //       date.getFullYear() +
      //       "-" +
      //       (date.getMonth() + 1) +
      //       "-" +
      //       date.getDate()
      //     );
      //   },
      // },
      // {
      //   Header: "End Date",
      //   accessor: "attributes.purchaseClosingDate",
      //   Cell: ({ value }) => {
      //     if (!value) return "";
      //     const date = new Date(value);
      //     return (
      //       date.getFullYear() +
      //       "-" +
      //       (date.getMonth() + 1) +
      //       "-" +
      //       date.getDate()
      //     );
      //   },
      // },
      {
        Header: "Status",
        accessor: "attributes.status",
        Cell: StatusPill,
      },
    ],
    []
  );
  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Loan Requests
              </h1>
              <div className="py-3 px-4 flex flex-col md:flex-row gap-3 items-center text-sm font-medium leading-none text-gray-600 cursor-pointer"></div>
            </div>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main>
              {/* Page header */}
              <div className="mt-8">
                <Table columns={columns} data={data} />
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Dashboard);
