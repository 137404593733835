import React, { useState } from "react";
import withGlobalProviders from "../../../../general/withGlobalProvider";

import ListItem from '../savedLendersPerOrganization/SavedLenderItem'

function Items({ lenders }) {
  const [preferredLenders, setPreferredLenders] = useState(lenders.data);
  console.log('**', lenders)

  if (!preferredLenders)
    return <p>there are no preferred lenders</p>;

  return (
    <div className="flex space-y-6 flex-col">
      {preferredLenders.map((lender) => (
        <ListItem lender={lender.attributes} key={lender.id}/>
      ))}
    </div>
  );
}

export default withGlobalProviders(Items);
