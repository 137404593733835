import React from "react";
import { useSelector } from "react-redux";
import BorrowerSubscription from "../../borrower/Subscription";
import LenderSubscription from "../../lender/Subscription";
import BrokerSubscription from "../../broker/Subscription";

import withGlobalProviders from "../../../general/withGlobalProvider";

function Subscription() {
  const user = useSelector((state) => state.session.currentUser);

  if (user) {
    const { userRole } = user.attributes;

    if (userRole === "borrower") {
      return <BorrowerSubscription />;
    } else if (userRole === "lender") {
      return <LenderSubscription />;
    } else if (userRole === "broker") {
      return <BrokerSubscription />;
    }
  }
}

export default withGlobalProviders(Subscription);
