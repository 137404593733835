const lender_sportlight_benefits = [
  'Live Speaker Sessions',
  'Diverse Lender Lineup'
];

const community_chat_benefits = [
  'Dynamic Networking',
  'Real-Time Collaboration',
  'Professional Growth & Collaborative Learning'
];

const premium_placement_benefits = [
  'Top-of-Search Visibility',
  'Strategic Positioning',
  'Lead Generation Powerhouse',
  'Brand Authority Boost',
  'Time and Effort Savings'
];

export const data = {
  borrower_bundle: {
    title: 'Bundle',
    description: 'Choose to bundle and save BIG',
    price: '106.40',
    benefits: [ ... lender_sportlight_benefits, ...community_chat_benefits ]
  },
  borrower_lender_spotlight: {
    title: 'Lender Spotlight',
    description: 'Subscribe to our Lender Spotlight for added benefits',
    price: '99',
    benefits: [ ... lender_sportlight_benefits ]
  },
  borrower_chat: {
    title: 'Borrower Chat Rooms',
    description: 'Connect, collaborate, grow share intel on lenders',
    price: '34',
    benefits: [ ...community_chat_benefits ]
  },
  broker_bundle: {
    title: 'Bundle',
    description: 'Choose to bundle and save BIG',
    price: '136',
    benefits: [ ... lender_sportlight_benefits, ...community_chat_benefits, ...premium_placement_benefits ]
  },
  broker_lender_spotlight: {
    title: 'Lender Spotlight',
    description: 'Subscribe to our Lender Spotlight for added benefits',
    price: '99',
    benefits: [ ... lender_sportlight_benefits ]
  },
  broker_community_chat: {
    title: 'Broker Community Chat',
    description: 'Connect, collaborate, grow share intel on lenders',
    price: '34',
    benefits: [ ...community_chat_benefits ]
  },
  broker_premium_placement: {
    title: 'Premium Placement',
    description: 'Promote Yourself with premium placement in search results',
    price: '139',
    benefits: [ ...premium_placement_benefits ]
  },
  lender_premium_placement: {
    title: 'Premium Placement',
    description: 'Promote Yourself with premium placement in search results',
    price: '139',
    benefits: [ ...premium_placement_benefits ]
  }
};
