import React, { useState } from "react";

import { EnvelopeIcon, PhoneIcon, PlusIcon, UserIcon, } from "@heroicons/react/20/solid";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";

import BankerForm from "../../banker/forms/BankerForm";

function PreferedLenderItem({ lender }) {
  // const item = useSelector((state) => state.organizationBranches.items[id]);
  const [item, setItem] = useState(lender);

  const [isAddBankerModalOpen, setIsAddBankerModalOpen] = useState(false);

  const openAddBankerModal = () => {
    setIsAddBankerModalOpen(true);
  };

  const closeAddBankerModal = () => {
    setIsAddBankerModalOpen(false);
  };

  return (
    <div className="block bg-white rounded-lg">
      <div className="">
        <div className="flex flex-row justify-start">
          <div className="flex flex-col w-full">
            <div
              className="grid border-b border-gray-300 p-4"
              style={{ gridTemplateColumns: "repeat(24, minmax(0, 1fr))" }}
            >
              <div className="col-span-1">
                <MarkIcon filled={Boolean(item.interestedAt)}/>
              </div>
              <div className="sm:col-span-5">
                <dt
                  className="text-sm font-medium text-gray-500">{item?.userProfile?.company_name || item?.userProfile?.title || "LenderPrism"}</dt>
                <dd
                  className="mt-1 text-sm text-gray-900">{item?.userProfile?.first_name} {item?.userProfile?.last_name}</dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500">
                  Minimum Loan Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $
                  {(item.minimumLoanAmountCents / 100.0)
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500">
                  Maximum Loan Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $
                  {(item.maximumLoanAmountCents / 100.0)
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                  Financing Services
                </dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                  {item.typeOfFinancing}
                </dd>
              </div>
            </div>

            <div className="w-full flex flex-col text-sm gap-2 font-normal px-8 py-4">
              <div className="flex justify-between">
                <p className="font-bold text-sm">Bankers</p>
                <span
                  className="text-blue-800 font-bold flex gap-3 cursor-pointer mr-3 w-fit"
                  onClick={openAddBankerModal}
                >
                  <PlusIcon className="h-4 w-4 text-blue-800"/>
                  Add Contact
                </span>
              </div>
              {item?.bankers.length > 0 ? item?.bankers.map((banker) => (
                <p
                  key={banker.first_name}
                  className="flex flex-row gap-2 items-center w-full"
                >
                  <UserIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="overflow-hidden whitespace-normal mr-12">
                    {banker.first_name} {banker.last_name}
                  </span>
                  <a
                    href={`mailto:${banker.email}`}
                    title={`Email: ${banker.email}`}
                    className="flex gap-3 cursor-pointer mr-12"
                  >
                    <EnvelopeIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                    {banker.email}
                  </a>
                  <a
                    href={`tel: +1 ${banker.phone_number}`}
                    title={`Call: +1 ${banker.phone_number}`}
                    className="flex gap-3 cursor-pointer mr-12"
                  >
                    <PhoneIcon className="h-4 w-4"/>
                    {banker.phone_number}
                  </a>
                </p>
              )) : (
                <span className="italic text-gray-400">There is no banker</span>
              )
              }
            </div>
          </div>
        </div>
      </div>

      <div>
        {isAddBankerModalOpen && (
          <div
            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50">
              <p
                onClick={closeAddBankerModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                X
              </p>
              <BankerForm lenderId={item.lenderId} loanMatchId={item.id}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PreferedLenderItem;
