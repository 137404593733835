import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import join from "lodash/join";

import { Transition } from "@headlessui/react";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import EyeIcon from "../../../../../assets/svgs/EyeIcon";
import EditIcon from "../../../../../assets/svgs/EditIcon";
import DelIcon from "../../../../../assets/svgs/DelIcon";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";

function BrokerItem({ attributes: { profile: { company_name: companyName, title, first_name: firstName, last_name: lastName }, profileState } }) {
  return (
    <div className="bg-white overflow-hidden sm:rounded-lg flex-col col-span-6 sm:col-span-3 md:col-span-2">
      <div className="flex justify-between items-center p-6 border-b-2 border-gray-500" title="content-top">
        <div className="flex-col gap-2">
          <p className="mt-1 text-lg text-gray-900">
            {companyName}
          </p>
          <p className="text-sm font-medium text-gray-500">
            {title ? title : `${firstName} ${lastName}`}
          </p>
        </div>
        <img style={{ width: "100px", height: "100px", color: "white" }}
             src="https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/broker-icon.jpg"/>
      </div>
      <div className="flex p-6 justify-between" title="content-bottom">
        <div className="flex-col gap-2">
          <p className="text-sm font-medium text-gray-500">
            Markets Served
          </p>
          <p className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-11/12">
            {profileState}
          </p>
        </div>

        <div>
          <p className="text-sm font-medium text-gray-500">
            Property Types of Focus
          </p>
          <p className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-11/12">
            {profileState}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BrokerItem;
