import React from "react";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";

function LenderItem({ title, type, seeAll, lenders, preferred }) {

  return (
    <div className="flex flex-col sm:col-span-2 md:col-span-1">
      <div className="flex gap-6">
        <h2 className="text-xl font-bold leading-tight text-gray-900">
          {title}
        </h2>
        {seeAll ? (
          <a className="font-bold text-congress-blue-600" href="/lenders">See all</a>
        ) : (
          ""
        )}
      </div>
      {lenders?.length != 0 ?
        <div
          className="bg-white overflow-hidden sm:rounded-lg p-4 mt-6"
        >
          {lenders?.map((lender) => {
            const profile = preferred ? lender?.attributes?.userProfile : lender?.attributes?.profile;
            return (
              <div key={lender.id} className="flex items-center justify-start p-2">
                <div className="flex justify-start gap-6 items-center w-1/2">
                  <MarkIcon filled={preferred}/>
                  <div className="flex-col items-start">
                    <dt className="text-sm font-medium text-gray-500">
                      {profile?.company_name || profile?.title || "LenderPrism"}
                    </dt>
                    <dd
                      className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                      {profile?.first_name} {profile?.last_name}
                    </dd>
                  </div>
                </div>
                <div className="w-1/2">
                  <dt className="text-sm font-medium text-gray-500">
                    Financing Services
                  </dt>
                  <dd
                    className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">{type === "preferred" ? lender?.attributes?.typeOfFinancing : lender?.attributes?.lendingParameters?.typeOfFinancing}</dd>
                </div>
              </div>
            )
          })}
        </div>
        : <p className="text-sm font-medium text-gray-500 mt-6">Your bookmarked lenders will be listed here.</p>}
    </div>
  );
}

export default LenderItem;
