import React from "react";

export default function EmptyState() {
  return (
    <div
      className="flex flex-col gap-2"
    >
      <p className="text-base">No brokers found</p>
      <p className=" text-sm text-gray-300">
        Try changing the filters or search term
      </p>
    </div>
  );
}
