import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAllLoanRequest } from "../../../../actions/loanRequestMatchesActions";
import PageBody from "../../general/PageBody";
import withGlobalProviders from "../../../general/withGlobalProvider";
import { fetchLoanRequests } from "../../../../actions/loanRequestActions";
import { fetchLoanRequestMatches } from "../../../../actions/loanRequestMatchesActions";
import Unsubmitted from "./show/Unsubmitted";
import Processing from "./show/Processing";
import GeneratingQuotes from "./show/GeneratingQuotes";
import Complete from "./show/Complete";
import QuoteTabs from "./show/QuoteTabs";
import QuotesList from "./show/QuotesList";

import { progressWidth } from "./show/utils";
import MatchList from "./show/MatchList";
import ShowSection from "../searches/show/ShowSection";

function Show({ loanRequestId }) {
  const dispatch = useDispatch();
  const loanRequest = useSelector((state) => state.loanRequests.items);
  const [progress, setProgress] = useState(0);
  const [matchTab, setMatchTab] = useState("all");
  const [quoteTab, setQuoteTab] = useState("all");
  const [lenderNumber, setLenderNumber] = useState(0);
  const [loanAllMatches, setLoanAllMatches] = useState([])
  const [allSelectedItems, setAllSelectedItems] = useState([])
  const [showPreferLender, setShowPreferLender] = useState(false)
  const setAllLenders = () => {
    setAllSelectedItems(loanAllMatches)
    dispatch(saveAllLoanRequest(loanAllMatches))}
  const removeAllLenders = () => {
    setAllSelectedItems([])
      dispatch(saveAllLoanRequest([]))}
  useEffect(() => {
    dispatch(fetchLoanRequests());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchLoanRequestMatches({ loanRequestId }));
  }, [dispatch]);
  useEffect(() => {
    if (loanRequest) {
      switch (loanRequest?.attributes?.status) {
        case "closed":
          setProgress(3);
          break;
        case "quoting":
          setProgress(2);
          break;
        case "processing":
          setProgress(1);
          break;
        case "draft":
        default:
          break;
      }
    }
  }, [loanRequest]);
  const [loanAttributes, setLoanAttribute] = useState({});
  useEffect(() => {
    setLoanAttribute(loanRequest[loanRequestId]?.attributes || {});
  }, [loanRequest]);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between mb-9">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Request of $
              {(loanAttributes?.loanAmountCents / 100.0)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
              for {loanAttributes?.propertyType || "Unspecified Property"}
            </h1>
            <a
            href={`./${loanAttributes.id}/edit`}
            className="ml-3 h-10 inline-flex items-center px-4 py-2 
            border-2 border-congress-blue-600 rounded-md 
            text-sm text-congress-blue-600 font-medium bg-transparent 
            hover:bg-congress-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500 hover:text-white hover:cursor-pointer">
              Edit request
            </a>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
            <p className=" text-black font-bold text-2xl">
              Lenders matching your search{" "}
              <span className=" text-gray-600 text-sm">{lenderNumber} lenders</span>
            </p>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center mt-3">
            <div>
              <input onChange={(e) => setShowPreferLender(e.target.checked)} type="checkbox" id="checkbx" />
              <label htmlFor="checkbx" className=" pl-2">
                Show preferred lenders only
              </label>
            </div>
            <button onClick={setAllLenders} className="flex items-center gap-2 cursor-pointer rounded-md border-2 border-emerald-600 bg-white px-4 py-2 text-[14px] font-medium text-emerald-700 hover:bg-emerald-700 hover:text-white mr-12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.78562 9.7654C1.90075 9.65566 2.05475 9.59611 2.21376 9.59986C2.37278 9.60361 2.5238 9.67035 2.63362 9.78541L5.80002 13.1022V5.0998C5.80002 3.95937 6.25306 2.86565 7.05946 2.05925C7.86587 1.25284 8.95959 0.799805 10.1 0.799805C11.2405 0.799805 12.3342 1.25284 13.1406 2.05925C13.947 2.86565 14.4 3.95937 14.4 5.0998V7.3998C14.4 7.55893 14.3368 7.71155 14.2243 7.82407C14.1118 7.93659 13.9592 7.9998 13.8 7.9998C13.6409 7.9998 13.4883 7.93659 13.3758 7.82407C13.2632 7.71155 13.2 7.55893 13.2 7.3998V5.0998C13.2 4.27763 12.8734 3.48914 12.2921 2.90777C11.7107 2.32641 10.9222 1.9998 10.1 1.9998C9.27785 1.9998 8.48935 2.32641 7.90799 2.90777C7.32663 3.48914 7.00002 4.27763 7.00002 5.0998V13.1022L10.1656 9.78541C10.2755 9.6703 10.4267 9.60357 10.5858 9.5999C10.7449 9.59622 10.8989 9.6559 11.014 9.76581C11.1291 9.87571 11.1959 10.0268 11.1995 10.1859C11.2032 10.3451 11.1435 10.4991 11.0336 10.6142L6.83362 15.0142C6.7776 15.0729 6.71025 15.1196 6.63565 15.1515C6.56106 15.1834 6.48076 15.1999 6.39962 15.1999C6.31848 15.1999 6.23819 15.1834 6.16359 15.1515C6.089 15.1196 6.02165 15.0729 5.96562 15.0142L1.76562 10.6142C1.65587 10.4991 1.59633 10.3451 1.60008 10.1861C1.60383 10.0271 1.67057 9.87603 1.78562 9.7662V9.7654Z"
                  fill="#059669"
                />
              </svg>
              Select all
            </button>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
            <main>
              {progress < 2 && (
                <MatchList {...{ matchTab, setMatchTab, loanRequestId,setLenderNumber,setLoanAllMatches, allSelectedItems, setAllSelectedItems, removeAllLenders, showPreferLender }} />
              )}
              {progress >= 2 && <QuoteTabs {...{ quoteTab, setQuoteTab }} />}
              {progress >= 2 && (
                <QuotesList {...{ quoteTab, setQuoteTab, loanRequestId }} />
              )}
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Show);
