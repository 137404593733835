import React from "react";
import {useSelector} from "react-redux";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import SubscribedItem from "./SubscribedItem";
import NoSubscribedPlan from "./NoSubscribedPlan";

//while using old subscription, after a while remove this
const unavailableSubscriptionList = [
  "silver_monthly",
  "silver_yearly",
  "gold_monthly",
  "gold_yearly",
  "platinum_monthly",
  "platinum_yearly"
];

const activeSubscription = [  'active', 'trial_will_end' ];

function SubscriptionTab() {
  const userInfo = useSelector((state) => state.session.currentUser);
  const { userRole, subscribeType, subscription } = userInfo.attributes;

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <h2 className="text-lg leading-6 font-bold text-gray-900 mb-5">
          Subscription
        </h2>
        {
          subscribeType && !unavailableSubscriptionList.includes(subscribeType) && activeSubscription.includes(subscription) ?
            <SubscribedItem subscribeType = { subscribeType }/>
            : <NoSubscribedPlan />
        }
      </div>
    </div>
  );
}

export default withGlobalProviders(SubscriptionTab);
