import React, { Fragment, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import withGlobalProviders from "../../../general/withGlobalProvider";
import { destroyBanker, createBanker } from "../../../../actions/bankerActions";

function BankerDetail({ bankerDetail }) {
  const dispatch = useDispatch();

  // const [toggle, setToggle] = useState(false)

  if (!bankerDetail) return null;

  const hideBankerDetailStyle = {
    height: "0",
    transition: "height 0.3s",
    opacity: "0",
  };

  const showBankerDetailStyle = {
    height: "230px",
    transition: "height 0.3s",
    opacity: "1",
  };

  const {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    notes,
    state,
    city,
    organization,
    organizationBranch,
    createdAt,
    updatedAt,
  } = bankerDetail.attributes;

  const handleDelete = (organizationBranchId, bankerId) => {
    return dispatch(destroyBanker({ organizationBranchId, bankerId }));
  };

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
    >
      <div>
        <div className="bg-white shadow-lg sm:rounded-lg">
          <div className="px-4 py-3 sm:px-6 flex justify-between expand-button">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {firstName + " " + lastName || "Unnamed Search"}
                <span className="mt-1 max-w-2xl text-sm text-gray-500">
                  {" "}
                  ({organizationBranch.name})
                </span>
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Created: {moment(createdAt).format("MMMM Do [at] h:mm A ")} |
                Last Updated: {moment(updatedAt).format("MMMM Do [at] h:mm A ")}
              </p>
            </div>
          </div>
          <div style={showBankerDetailStyle}>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6  ">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    First Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{firstName}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Last Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{lastName}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{email}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{phoneNumber}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">City</dt>
                  <dd className="mt-1 text-sm text-gray-900">{city}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">State</dt>
                  <dd className="mt-1 text-sm text-gray-900">{state}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Organization
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {organization.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Notes</dt>
                  <dd className="mt-1 text-sm text-gray-900">{notes}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default withGlobalProviders(BankerDetail);
