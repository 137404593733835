import * as yup from "yup";

export const brokerInitialValues = {
  firstName: "",
  lastName: "",
  officePhone: "",
  personalPhone: "",
  companyName: "",
  title: "",
  profileState: "",
  city: "",
  typeOfInstitution: "",
  directLenderMortgageBroker: "",
  lendingStates: "",
  propertyTypesFinanced: "",
  elevatorPitch: "",
  comments: "",
};

export const borrowerInitialValues = {
  firstName: "",
  lastName: "",
  officePhone: "",
  personalPhone: "",
  companyName: "",
  title: "",
  profileState: "",
  city: ""
};
// export const brokerUpdateValidationSchema = yup.object().shape({
//   typeOfInstitution: yup
//     .array()
//     .min(1, "Can't be blank.")
//     .required("Can't be blank.")
//     .nullable(),
//   directLenderMortgageBroker: yup
//     .object()
//     .nullable()
//     .required("Can't be blank."),
//   lendingStates: yup
//     .array()
//     .min(1, "Can't be blank.")
//     .required("Can't be blank.")
//     .nullable(),
//   propertyTypesFinanced: yup
//     .array()
//     .min(1, "Can't be blank.")
//     .required("Can't be blank.")
//     .nullable()
// });

// export const borrowerUpdateValidationSchema = yup.object().shape({ });

export const brokerValidationSchema = yup.object().shape({
  firstName: yup.string().trim().nullable().required("Can't be blank."),
  lastName: yup.string().trim().nullable().required("Can't be blank."),
  officePhone: yup.string().trim().nullable(),
  personalPhone: yup.string().trim().nullable(),
  companyName: yup.string().trim().nullable().required("Can't be blank."),
  title: yup.string().trim().nullable(),
  profileState: yup.object().nullable().required("Can't be blank."),
  city: yup.string().trim().nullable(),
  typeOfInstitution: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  directLenderMortgageBroker: yup
    .object()
    .nullable()
    .required("Can't be blank."),
  lendingStates: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  propertyTypesFinanced: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable()
});

export const borrowerValidationSchema = yup.object().shape({
  firstName: yup.string().trim().nullable().required("Can't be blank."),
  lastName: yup.string().trim().nullable().required("Can't be blank."),
  officePhone: yup.string().trim().nullable(),
  personalPhone: yup.string().trim().nullable(),
  companyName: yup.string().trim().nullable().required("Can't be blank."),
  title: yup.string().trim().nullable(),
  profileState: yup.object().nullable().required("Can't be blank."),
  city: yup.string().trim().nullable()
});
