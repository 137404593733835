import React from "react";

function MarkIcon({ filled, size, color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled == true ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.32 2.57665C10.094 2.13851 13.906 2.13851 17.68 2.57665C19.177 2.75065 20.25 4.03665 20.25 5.50665V20.9996C20.2498 21.1274 20.217 21.2529 20.1547 21.3644C20.0925 21.4759 20.0027 21.5697 19.8941 21.6368C19.7854 21.7039 19.6614 21.7421 19.5338 21.7479C19.4062 21.7536 19.2793 21.7267 19.165 21.6696L12 18.0886L4.835 21.6716C4.72058 21.7288 4.59345 21.7557 4.4657 21.7498C4.33795 21.744 4.21381 21.7056 4.10509 21.6382C3.99637 21.5709 3.90667 21.4769 3.84452 21.3651C3.78237 21.2533 3.74983 21.1275 3.75 20.9996V5.50665C3.75 4.03665 4.823 2.75065 6.32 2.57665Z"
          fill="#F0BB00"
        />
      ) : (
        <path
          d="M17.593 3.32157C18.693 3.44957 19.5 4.39857 19.5 5.50657V20.9996L12 17.2496L4.5 20.9996V5.50657C4.5 4.39857 5.306 3.44957 6.407 3.32157C10.1232 2.89021 13.8768 2.89021 17.593 3.32157Z"
          stroke="#7C5A00"
          strokeOpacity="0.5"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}

export default MarkIcon;
