import React from "react";
import { data } from './subscribedItemHelpers';

function SubscribedItem(subscribeType) {
  const content = data[subscribeType.subscribeType];

  return (
    <div className="flex flex-col px-8 md:w-1/2 w-full border-2 border-congress-blue-600 rounded-md h-auto py-5">
      <p className="text-center font-bold text-2xl">{ content.title }</p>
      <p className="text-center text-sm mt-3">{ content.description }</p>
      <div className="flex flex-row items-center justify-center mt-3">
        <p className="text-lg text-black font-bold">${ content.price }</p>
        <p className="text-sm ml-2 align-bottom">per month</p>
      </div>
      <hr className="border-1 border-solid border-congress-blue-600 mt-3" />
      <p className="mt-4">What's included:</p>
      <ul className="mt-3 list-disc">
        {content.benefits.map((item) => (
          <li key={item} className="p-1 ml-6">
            { item }
          </li>
        ))}
      </ul>
      <div className="bg-green-600 rounded text-white w-full py-2 mt-4 text-center">
        Subscribed
      </div>
    </div>
  );
}

export default SubscribedItem;
