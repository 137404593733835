import normalize from "json-api-normalizer";
import { batch } from "react-redux";

import { POST, GET, DELETE, PATCH } from "../utility/api";
import { receiveOrganizations } from "./organizationActions";

export const RECEIVE_USER_SAVED_LENDERS = "RECEIVE_USER_SAVED_LENDERS";
export const REMOVE_USER_SAVED_LENDERS = "REMOVE_USER_SAVED_LENDERS";

export const receiveUserSavedLenders = (userSavedLenders) => ({
  type: RECEIVE_USER_SAVED_LENDERS,
  payload: userSavedLenders,
});

export const removeUserSavedLender = (userSavedLender) => ({
  type: REMOVE_USER_SAVED_LENDERS,
  payload: userSavedLender,
});


export const createUserSavedLenders =
  (loan_request_match_ids, loan_request_id) => (_dispatch) => {
    return POST(`/api/loan_requests/${loan_request_id}/save_selected_lenders`, {
      ...{ loan_request_match_ids},
    });
  };

export const fetchUserSavedLenders = (search_id) => (dispatch) => {
  return GET("/api/user_saved_lenders?search_id=" + search_id).then((res) => {
    return dispatch(receiveUserSavedLenders(res.data));
  });
};

export const fetchSavedLenders = () => (dispatch) => {
  return GET("/api/user_saved_lenders?load_lenders=true").then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveLenders(normalized.lender));
  });
}

export const destroySavedLender = (id) => (dispatch) => {
  // return DELETE("/api/remove_saved_lender/" + id).then((_res) => {
  //   return dispatch(removeUserSavedLender(id));
  // });
  return PATCH("/remove_saved_lender/" + id);
};
