import React, { useState } from "react";
import { useField, useFormikContext } from "formik";

import { Switch } from "@headlessui/react";
export default function ToggleField({ label, status, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [enabled, setEnabled] = useState(status);

  const handleChange = () => {
    setEnabled(!enabled);
    setFieldValue(field.name, !enabled);
  };

  return (
    <div className="flex flex-row items-center gap-3">
      <label
        htmlFor={props.id || props.name}
        className="text-base font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-2">
        <Switch
          {...field}
          {...props}
          checked={enabled}
          onChange={handleChange}
          className={`${
            enabled ? "bg-blue-900" : "bg-gray-400"
          } relative inline-flex h-5 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-0"
            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
    </div>
  );
}
