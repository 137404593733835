import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  DropdownIndicator,
  control,
  Control,
  SelectContainer,
  ValueContainer,
  IndicatorSeparator,
  Placeholder,
  MenuList,
  Menu,
  Option,
  SingleValue,
  MultiValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
} from "./SelectField/Components";
import { useField, useFormikContext } from "formik";

import customStyles from "./SelectField/customStyles";
import { object } from "yup";

export default function SelectField({
  name,
  label,
  options,
  required,
  isClearable,
  requires,
  requiresOptions,
  placeholder,
  selectAll = false,
  isMulti,
  ...props
}) {
  const { values, submitForm } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const [disabled, setDisabled] = useState(false);

  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    if (requires) {
      setDisabled(!values[requires]);
    }
  }, [values]);

  const calculatedOptions = requiresOptions
    ? disabled
      ? []
      : values[requires]
      ? requiresOptions[values[requires].value]
      : []
    : options;

  return (
    <div className="relative">
      {selectAll && (
        <div
          onClick={() => !disabled && setValue(calculatedOptions)}
          className={`text-sm -mt-5 self-end text-right font-medium underline text-congress-blue-500 cursor-pointer ${
            disabled ? "text-gray-600" : "cursor-pointer"
          }`}
        >
          Select All
        </div>
      )}
      <Select
        isMulti={isMulti}
        isDisabled={disabled}
        value={disabled ? null : value}
        onChange={setValue}
        options={
          requiresOptions
            ? disabled
              ? []
              : values[requires]
              ? requiresOptions[values[requires].value]
              : []
            : options
        }
        // placeholder="name"
        isError={meta.touched && meta.error}
        styles={customStyles}
        // there is a parent div and inside of it, there are so many child divs. and every child divs have their own divs too and so on. i hope to set style to button div inside of super parent div. how to select button div using super parent div using css?
        // https://stackoverflow.com/questions/43184636/how-to-set-style-to-button-div-inside-of-super-parent-div-using-css
        isClearable={value && isClearable}
        closeMenuOnSelect={!isMulti}
        placeholder={!disabled && placeholder}
        // className={`${
        //   (value && isClearable) || disabled
        //     ? "peer p-4 w-full placeholder:text-transparent focus:pt-5 focus:pb-3 border border-gray-200 rounded-lg text-sm [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:text-base autofill:pt-6 autofill:pb-2 focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500"
        //     : "peer p-4 w-full placeholder:text-transparent focus:pt-5 focus:pb-3 border border-gray-200 rounded-lg text-sm [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:text-base autofill:pt-6 autofill:pb-2 focus:outline-none focus:ring-deep-sea-500 focus:border-deep-sea-500"
        // }`}
        components={{
          DropdownIndicator,
          Control,
          SelectContainer,
          ValueContainer,
          IndicatorSeparator,
          Placeholder,
          MenuList,
          Menu,
          Option,
          SingleValue,
          MultiValueContainer,
          MultiValueLabel,
          NoOptionsMessage,
        }}
      />
      {((typeof(value) !== "string") && (value !== null) && !disabled) &&
      (
        <label
          htmlFor="hs-floating-input-email"
          className={`absolute top-4 left-2 ml-1 -translate-y-3 px-1 text-sm text-gray-400 duration-100
        ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base
        peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:text-sm
        peer-focus:px-1 ${
          required
            ? "after:content-['*'] after:text-red-500 after:pl-1"
            : "after:content-['(Optional)'] after:text-gray-300 after:pl-1"
        }`}
        >
          {label}
        </label>
      )}
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600">
          {meta.error && meta.error.value ? meta.error.value : meta.error}
        </p>
      ) : null}
    </div>
  );
}
