import * as yup from "yup";

export const initialValues = {
  typeOfInstitution: "",
  directLenderMortgageBroker: "",
  lendingStates: "",
  propertyTypesFinanced: ""
};

export const validationSchema = yup.object().shape({
  typeOfInstitution: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  directLenderMortgageBroker: yup
    .object()
    .nullable()
    .required("Can't be blank."),
  lendingStates: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable(),
  propertyTypesFinanced: yup
    .array()
    .min(1, "Can't be blank.")
    .required("Can't be blank.")
    .nullable()
});
