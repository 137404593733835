import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import withGlobalProviders from "../../../../general/withGlobalProvider";

import InputField from "../../../../forms/InputField";
import Select from "../../../../forms/SelectField";
import {subscribe} from "../../../../../actions/subscribeActions";
const tabs = [{ name: "Monthly" }, { name: "Yearly", off: "20" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// {
//     subType:"Silver",
//     money:"10",
//     perUnit:"Monthly",
//     benefits: ["benefit1 1", "benefit1 2", "benefit1 3"]
//   },

function CreditCard({ data , form , subType, index, subscribeInfo, termType, setSubscribeInfo, setCurrentType}) {
  const active = index +1 == subType ? true : false;
  const [currentTab, setCurrentTab] = useState( active ? (termType == 1  ?  "Monthly":  "Yearly") : "Monthly" );
  const [benefits, setBenefits] = useState([
    "benefit 1",
    "benefit 2",
    "benefit 3",
    "benefit 4",
  ]);
  useEffect(
    () => {
      console.log("active",active)
      active && setSubscribeInfo( active && (currentTab == "Monthly" ? "\u0024"+data.money + " /per month "  : "\u0024"+data.money * 12 * 0.8 + " /per year"))
      active && setCurrentType(active && data.subType)
    }, [active, data]
  )
  const [tempData, setTempData] = useState(data)
  const handleClickTab = (tab) => {
    if (!active) {
        setTempData({...tempData, perUnit: tab });
        form.setFieldValue("subscription", { perUnit: tab });
        setCurrentTab(tab);
    }
};
  const submitType = () => {
      subscribe({type:data.subType, subType: currentTab})
  }
  return (
    <div className=" border border-gray-300 rounded-lg flex flex-col p-4 relative col-span-6 sm:col-span-3 lg:col-span-2">
      {data.subType == "Gold" ? (
        <div className=" absolute top-0 right-0 px-1 py-2 text-white bg-congress-blue-500 rounded-tr-lg rounded-bl-lg">
          Best value
        </div>
      ) : (
        <></>
      )}
      <button
        style={{
          backgroundImage:
            data.subType == "Gold"
              ? "linear-gradient(130deg, #FFE27C 7.38%, #E9AD39 86.11%)"
              : "linear-gradient(130deg, #EEF1F9 7.38%, #B8BED3 86.11%)",
        }}
        className={classNames(
          data.subType == "Gold" ? "text-yellow-800" : "text-gray-600",
          "px-3 py-1 w-[fit-content] text-sm text-gray-600 border border-gray-300 rounded hover:bg-gray-300"
        )}
      >
        {data.subType}
      </button>
      <h1 className="mt-6 text-4xl font-bold flex items-center">
        ${currentTab == "Monthly" ? data.money : data.money * 12 * 0.8}
        {currentTab == "Monthly" ? (
          <span></span>
        ) : (
          <span className="text-gray-400 text-lg font-normal mx-1 line-through">{data.money * 12}$</span>
        )}
        <span className="text-gray-400 text-lg font-normal ml-1">
          /per month
        </span>
      </h1>
      <div className="hidden sm:block mt-5 pb-4 border-b border-gray-300">
        <nav
          className="flex space-x-2 bg-gray-50 border rounded-lg border-gray-100 p-1"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <a
              key={tab.name}
              className={classNames(
                tab.name === currentTab
                  ? "bg-white text-congress-blue-600 shadow py-2 px-3 font-bold"
                  : "text-gray-500 hover:text-gray-700",
                " px-3 font-small text-sm rounded cursor-pointer flex justify-center items-center"
              )}
              aria-current={tab.name === currentTab ? "page" : undefined}
              onClick={() => handleClickTab(tab.name)}
            >
              {tab.name}
              {tab?.off ? (
                <span className=" ml-2 text-green-800 text-3 px-1 border rounded border-green-800 py-0 bg-green-50">
                  {tab?.off}% off
                </span>
              ) : (
                <></>
              )}
            </a>
          ))}
        </nav>
      </div>
      <p className="mt-4">What's included:</p>
      <ul className="mt-3 list-disc">
        {data.benefits.map((benefit) => (
          <li key={benefit} className="p-1 ml-6">
            {benefit}
          </li>
        ))}
      </ul>
      {active ? (
        <button type="button" className="bg-congress-blue-600 rounded text-white w-full py-2 mt-4">
        Cancel
        </button>
      ) : (
        <button  onClick={() => {form.setFieldValue("subscription", tempData);}} className="bg-green-600 rounded text-white w-full py-2 mt-4">
        Subscribe
        </button>
      )
      }
      
    </div>
  );
}

export default withGlobalProviders(CreditCard);
