import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import concat from "lodash/concat";
import without from "lodash/without";
import uniq from "lodash/uniq";
import union from "lodash/union";
import intersection from "lodash/intersection";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import map from "lodash/map";
import SavedSelectedListItem from "./SavedSelectedListItem";

import {
  CreditCardIcon,
  UsersIcon,
  ListBulletIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/20/solid";

import List from "./List";
import Map from "./Map";
import {
  fetchSearch,
  fetchSearchResults,
} from "../../../../../actions/searchActions";

import SelectedList from "./SelectedList";
import { fetchUserSavedOrganizations } from "../../../../../actions/userSavedOrganizationActions";

const tabs = [
  { name: "List", icon: <ListBulletIcon className="w-4" /> },
  { name: "Map", icon: <GlobeAmericasIcon className="w-4" /> },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SavedShowSection({ searchId }) {
  const dispatch = useDispatch();
  const { loaded, items } = useSelector((state) => state.organizations);
  const userSavedOrganizations = useSelector(
    (state) => state.userSavedOrganizations.items
  );

  const [currentTab, setCurrentTab] = useState("Map");
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const search = useSelector((state) => state.searches.items[searchId]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      dispatch(fetchSearchResults(searchId));
      dispatch(fetchSearch(searchId));
      dispatch(fetchUserSavedOrganizations(searchId));
      setLoading(false);
    }, 5000);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setSelectedOrganizations((previousSelectedOrganizations) => {
        const savedOrganizations = items
          ? intersection(userSavedOrganizations, Object.keys(items))
          : [];
        return uniq(union(previousSelectedOrganizations, savedOrganizations));
      });
      setLoading(false);
    }, 5000);
  }, [userSavedOrganizations, items]);
  if (!loaded || !search || !userSavedOrganizations) return null;

  const { name, locationRange, postalCodeLongitude, postalCodeLatitude } =
    search.attributes;

  const updateSelectedOrganizations = (organizationId, remove = false) => {
    let actionMethod = remove ? without : concat;

    setSelectedOrganizations((previousSelectedOrganizations) =>
      uniq(actionMethod(previousSelectedOrganizations, organizationId))
    );
  };
  const SavedShowSectionDetails = () => {
    return (
      <section aria-labelledby="notes-title">
        <div className="sm:overflow-hidden">
          <div className="divide-y divide-gray-200">
            <dd className="">
              {/* <SelectedList
                items={selectedOrganizations}
                {...{
                  selectedOrganizations,
                  updateSelectedOrganizations,
                  setSelectedOrganizations,
                  searchId,
                  userSavedOrganizations,
                }}
              /> */}
              {map(selectedOrganizations, (id) => (
                <SavedSelectedListItem
                  {...{
                    key: id,
                    id,
                    updateSelectedOrganizations,
                    userSavedOrganizations,
                  }}
                />
              ))}
            </dd>
          </div>
        </div>
      </section>
    );
  };

  return <>{loading ? <Skeleton height={500} /> : SavedShowSectionDetails()}</>;
}
