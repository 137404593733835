import React, { useState } from "react";

import { EnvelopeIcon, PhoneIcon, PlusIcon, UserIcon, } from "@heroicons/react/20/solid";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";

import BankerForm from "../../banker/forms/BankerForm";

function SavedLenderItem({ lender }) {
  const [item, setItem] = useState(lender);

  const companyName = item?.userProfile?.company_name || item?.userProfile?.title || "LenderPrism";
  const firstName = item?.userProfile?.first_name || "";
  const lastName = item?.userProfile?.last_name || "";
  const cellularPhone = item?.userProfile?.cellular_phone || "";
  const officePhone = item?.userProfile?.office_phone || "";
  const {
    profileState,
    typeOfInstitution,
    directLenderMortgageBroker,
    recourse,
    typeOfFinancing,
    propertyTypesFinanced,
    elevatorPitch,
    comments
  } = item;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="block bg-white rounded-lg">
      <div className="">
        <div className="flex flex-row justify-start">
          <div className="flex flex-col w-full">
            <div
              className="grid border-b border-gray-300 p-4"
              style={{ gridTemplateColumns: "repeat(24, minmax(0, 1fr))" }}
            >
              <div className="col-span-1">
                <MarkIcon filled={Boolean(item.interestedAt)}/>
              </div>
              <div className="sm:col-span-5">
                <dt
                  className="text-sm font-medium text-gray-500">{item?.userProfile?.company_name || item?.userProfile?.title || "LenderPrism"}</dt>
                <dd
                  className="mt-1 text-sm text-gray-900">{item?.userProfile?.first_name} {item?.userProfile?.last_name}</dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500">
                  Minimum Loan Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $
                  {(item.minimumLoanAmountCents / 100.0)
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500">
                  Maximum Loan Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  $
                  {(item.maximumLoanAmountCents / 100.0)
                    .toFixed()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </div>
              <div className="sm:col-span-6">
                <dt className="text-sm font-medium text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                  Financing Services
                </dt>
                <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                  {item.typeOfFinancing}
                </dd>
              </div>
            </div>

            <div
              onClick={openModal}
              className="w-full px-8 py-4 text-blue-800 font-bold cursor-pointer mr-3 w-fit">
              Learn More and Contact Info
            </div>
          </div>
        </div>
      </div>

      <div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div
              className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50 overflow-auto"
              style={{ maxHeight: '90vh' }}
            >
              <div className="flex border-b align-center border-gray-300 p-6 gap-2">
                <img
                  className=" w-11 h-11"
                  src="https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/broker-icon.jpg"
                />
                <div className="flex flex-col">
                  <p className="text-md font-medium text-black">
                    {companyName}
                  </p>
                  <p className=" text-base font-medium text-gray-400">
                    {firstName} {lastName}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Mobile Number
                  </p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {cellularPhone}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Office Phone
                  </p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {officePhone}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">State</p>
                  <p className="text-md font-medium text-black">
                    {profileState}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Type of Institution
                  </p>
                  <p className="text-md font-medium text-black">
                    {typeOfInstitution}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Direct Lender/Mortgage Broker
                  </p>
                  <p className="text-md font-medium text-black">
                    {directLenderMortgageBroker}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Minimum Loan Amount
                  </p>
                  <p className="text-md font-medium text-black">
                    $
                    {(item.minimumLoanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Maximum Loan Amount
                  </p>
                  <p className="text-md font-medium text-black">
                    $
                    {(item.maximumLoanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Recourse
                  </p>
                  <p className="text-md font-medium text-black">
                    {recourse}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Property Type Financed
                  </p>
                  <p className="text-md font-medium text-black">
                    {propertyTypesFinanced}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Type of Financing Provided
                  </p>
                  <p className="text-md font-medium text-black">
                    {typeOfFinancing}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">Elevator Pitch</p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {elevatorPitch}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">Comments</p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {comments}
                  </p>
                </div>
              </div>
              <p
                onClick={closeModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                X
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SavedLenderItem;
