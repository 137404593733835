import React from "react";
import withGlobalProviders from "./withGlobalProvider";
function Hero() {
  return (
    <section className="bg-gray-100 relative h-screen flex flex-col items-center justify-center">
      <div className="z-10 max-w-md lg:max-w-full mx-3 xl:mx-32">
        <div className="flex flex-col lg:flex-row min-h-full">
          <div className="flex flex-col justify-center sm:justify-left basis-1/2">
            <h1>
              <span className="mt-1 block text-4xl font-bold tracking-tight sm:text-3xl xl:text-3xl 3xl:text-4xl  4xl:text-5xl">
                <span className="block mb-3 text-black text-center lg:text-left">
                  The Easy and Effective Way to Connect with Multifamily Lenders in Minutes
                </span>
              </span>
            </h1>
            <p className="mt-10 text-left text-gray-700 sm:mt-5 sm:text-lg lg:text-base xl:text-lg 3xl:text-2xl  4xl:text-3xl ">
              Get the funding you need for you multifamily or commercial real estate needs today
            </p>
            <div className="sm:flex sm:justify-center lg:justify-start mt-10 mx-10 sm:mx-0">
              <div className="rounded-md shadow">
                <a
                  href="/registration"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-congress-blue-600 px-8 py-1 text-base font-medium text-white hover:font-bold md:py-2 md:px-10 md:text-lg 3xl:text-3xl  4xl:text-4xl "
                >
                  Search for free
                </a>
              </div>
            </div>
            <div className="flex flex-row justify-items-center mt-10">
              <div className="text-4xl text-black w-1/2 px-5">
                <p className="text-2xl font-bold text-center text-gray-700 mb-3">10,000+</p>
                <p className="text-base text-center text-gray-700">banks, credit unions, and non bank lenders</p>
              </div>
              <div className="text-4xl text-black w-1/2 px-5">
                <p className="text-2xl text-center font-bold text-gray-700 mb-3">90,000+</p>
                <p className="text-base text-center text-gray-700">locations nationwide</p>
              </div>
            </div>
          </div>
          <div className="hidden lg:block h-3/5 my-auto ml-10 pl-4 4xl:w-1/2">
            <video
              className=" rounded-lg shadow-orange-lg"
              src="https://jdffrqoludeprmyyavwe.supabase.co/storage/v1/object/public/lenderprism/lenderprismhero.mp4"
              type="video/mp4"
              autoPlay
              muted
              loop
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
}
export default withGlobalProviders(Hero);
