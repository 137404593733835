import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import withGlobalProviders from "../../../general/withGlobalProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BrokerDetail({ brokerDetail }) {
  const dispatch = useDispatch();

  // const [toggle, setToggle] = useState(false)

  if (!brokerDetail) return null;

  const hideBrokerDetailStyle = {
    height: "0",
    transition: "height 0.3s",
    opacity: "0",
  };

  const showBrokerDetailStyle = {
    height: "230px",
    transition: "height 0.3s",
    opacity: "1",
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brokerInfo, setBrokerInfo] = useState({});
  const [brokerProfile, setBrokerProfile] = useState({});
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    setBrokerInfo(brokerDetail?.attributes);
  }, [brokerDetail]);

  const company_name = brokerInfo?.profile?.company_name;
  const title = brokerInfo?.profile?.title;
  const first_name = brokerInfo?.profile?.first_name;
  const last_name = brokerInfo?.profile?.last_name;
  const cellular_phone = brokerInfo?.profile?.cellular_phone;
  const city = brokerInfo?.profile?.city;
  const { email, profileState } = brokerInfo;
  const marketedServed = brokerInfo?.lendingParameterStates?.map(state => state.name).join(", ");
  const propertyTypesOfFocus = brokerInfo?.lendingParameters?.propertyTypesFinanced.join(", ")

  return (
    <div className="m-0 p-3" style={{ margin: 0 }}>
      <dl
        className="grid grid-cols-1 gap-x-4 gap-y-8"
        style={{ gridTemplateColumns: "repeat(24, minmax(0, 1fr))" }}
      >
        <div
          style={{ gridColumn: "span 5" }}
          className="self-center flex gap-2 items-center"
        >
          <img
            className=" w-11 h-11"
            src="https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/broker-icon.jpg"
          />
          <div className="flex flex-col">
            <dt className="text-sm font-normal text-gray-500">
              <span>{company_name || title || "LenderPrism"}</span>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 overflow-hidden break-words">
              {first_name} {last_name}
            </dd>
          </div>
        </div>
        <div style={{ gridColumn: "span 3" }} className="self-center">
          <dt className="text-sm font-normal text-gray-500">State</dt>
          <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
            {profileState ? profileState : ""}
          </dd>
        </div>
        <div style={{ gridColumn: "span 3" }} className="self-center">
          <dt className="text-sm font-normal text-gray-500">City</dt>
          <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
            {city ? city : ""}
          </dd>
        </div>
        <div style={{ gridColumn: "span 5" }} className="self-center">
          <dt className="text-sm font-normal text-gray-500">Markets Served</dt>
          <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
            {marketedServed}
          </dd>
        </div>
        <div style={{ gridColumn: "span 5" }} className="self-center">
          <dt className="text-sm font-normal text-gray-500">
            Property Types of Focus
          </dt>
          <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
            {propertyTypesOfFocus}
          </dd>
        </div>
        <div
          style={{ gridColumn: "span 3" }}
          className="self-center rounded-md"
        >
          <button
            rel="noopener noreferrer"
            className="w-full cursor-pointer rounded-md border border-transparent px-2 py-2 text-[14px] font-medium text-congress-blue-500"
            onClick={openModal}
          >
            View details
          </button>
        </div>
      </dl>

      <div>
        {isModalOpen && (
          <div
            className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50">
              <div className="flex border-b align-center border-gray-300 p-6 gap-2">
                <img
                  className=" w-11 h-11"
                  src="https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/broker-icon.jpg"
                />
                <div className="flex flex-col">
                  <p className="text-md font-medium text-black">
                    {company_name ? company_name : "LenderPrism"}
                  </p>
                  <p className="text-md font-medium text-black">
                    {title ? title : ""}
                  </p>
                  <p className=" text-base font-medium text-gray-400">
                    {city ? city : ""}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    First Name
                  </p>
                  <p className="text-md font-medium text-black">
                    {first_name}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Last Name
                  </p>
                  <p className="text-md font-medium text-black">
                    {last_name}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">Email</p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {email}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">
                    Mobile Number
                  </p>
                  <p className="text-md font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {cellular_phone}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">City</p>
                  <p className="text-md font-medium text-black">
                    {city}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-base font-medium text-gray-400">State</p>
                  <p className="text-md font-medium text-black">
                    {profileState}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2 p-6 mb-8">
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Markets Served
                  </p>
                  <p className="text-md font-medium text-black">
                    {marketedServed}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-base font-medium text-gray-400">
                    Property Types of Focus
                  </p>
                  <p className="text-md font-medium text-black">
                    {propertyTypesOfFocus}
                  </p>
                </div>
              </div>
              <p
                onClick={closeModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                X
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withGlobalProviders(BrokerDetail);
