import React from "react";

function CuDocumentIcon({className, style}) {

  return (
    <span className={className} style={style}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 14.75V12.125C19.5 11.2299 19.1444 10.3715 18.5115 9.73851C17.8786 9.10558 17.0201 8.75 16.125 8.75H14.625C14.3266 8.75 14.0405 8.63147 13.8295 8.4205C13.6185 8.20952 13.5 7.92337 13.5 7.625V6.125C13.5 5.22989 13.1444 4.37145 12.5115 3.73851C11.8785 3.10558 11.0201 2.75 10.125 2.75H8.25M13.481 16.231L15 17.75M13.481 16.231C13.7248 15.9872 13.9183 15.698 14.0502 15.3795C14.1821 15.0611 14.25 14.7197 14.25 14.375C14.25 13.6788 13.9734 13.0111 13.4812 12.5188C12.9889 12.0266 12.3212 11.75 11.625 11.75C10.9288 11.75 10.2611 12.0266 9.76884 12.5188C9.27656 13.0111 9 13.6788 9 14.375C9 14.7197 9.0679 15.0611 9.19982 15.3795C9.33173 15.698 9.52509 15.9874 9.76884 16.2312C10.0126 16.4749 10.302 16.6683 10.6205 16.8002C10.9389 16.9321 11.2803 17 11.625 17C11.9697 17 12.3111 16.9321 12.6295 16.8002C12.948 16.6683 13.2372 16.4748 13.481 16.231ZM10.5 2.75H5.625C5.004 2.75 4.5 3.254 4.5 3.875V20.375C4.5 20.996 5.004 21.5 5.625 21.5H18.375C18.996 21.5 19.5 20.996 19.5 20.375V11.75C19.5 9.36305 18.5518 7.07387 16.864 5.38604C15.1761 3.69821 12.8869 2.75 10.5 2.75Z" stroke="#1F2126" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default CuDocumentIcon;
