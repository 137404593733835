import React, { useEffect, useState } from "react";

const Typewriter = () => {
  const words = [
    "Discover Multifamily Lenders with One Click",
    "Thousands of Lenders, Nationwide Locations",
    "Multifamily or Commercial Real Estate Loan",
  ];
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (reverse && subIndex === 0) {
      setReverse(false);
      setIndex((lastValue) => (lastValue + 1) % words.length);
      return;
    }

    if (!reverse && text === words[index]) {
      setTimeout(() => {
        setReverse(true);
      }, 2000);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((lastValue) => lastValue + (reverse ? -1 : 1));
    }, 80);

    return () => clearTimeout(timeout);
  }, [text, subIndex, index, reverse]);

  useEffect(() => {
    setText((lastValue) => words[index].substring(0, subIndex));
  }, [subIndex, index]);

  return (
    <div className="w-full h-full flex justify-center items-center mb-8">
      <h1 className="text-4xl sm:text-6xl font-bold">
        {text || <span style={{ color: "transparent" }}>_</span>}
      </h1>
    </div>
  );
};

export default Typewriter;
