import React from "react";

function PercentDiscountsIcon({className, style}) {

  return (
    <span className={className} style={style}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66699 13.3333L13.3337 6.66663M7.0835 7.08325H7.5835M7.0835 7.58325H7.5835M12.4168 12.4166H12.9168M12.4168 12.9166H12.9168M17.015 7.68023C16.7703 7.3537 16.6344 6.99996 16.58 6.5918L16.3625 5.0952C16.2537 4.30608 15.6284 3.68023 14.8127 3.54418L13.3173 3.32649C12.9367 3.27207 12.5561 3.1088 12.2298 2.89112L11.0607 2.02037C10.7344 1.77547 10.3538 1.66663 9.97314 1.66663C9.5925 1.66663 9.21185 1.77547 8.88559 2.02037L7.68929 2.91833C7.36302 3.16322 7.00957 3.29928 6.60174 3.3537L5.13355 3.57139C4.34508 3.68023 3.71974 4.30608 3.58379 5.12241L3.36629 6.61901C3.31191 6.99996 3.14878 7.38091 2.93127 7.70744L2.03404 8.90472C1.54464 9.55778 1.54464 10.4557 2.03404 11.0816L2.93127 12.2789C3.17596 12.6054 3.31191 12.9591 3.36629 13.3673L3.58379 14.8639C3.69255 15.653 4.31789 16.2789 5.13355 16.4149L6.62893 16.6326C7.00957 16.687 7.39021 16.8503 7.71648 17.068L8.91278 17.9659C9.56531 18.4557 10.4625 18.4557 11.0879 17.9659L12.2842 17.068C12.6104 16.8231 12.9639 16.687 13.3717 16.6326L14.8671 16.4149C15.6556 16.3061 16.2809 15.6802 16.4169 14.8639L16.6344 13.3673C16.6887 12.9864 16.8519 12.6054 17.0694 12.2789L17.9666 11.0816C18.456 10.4285 18.456 9.53057 17.9666 8.90472L17.015 7.68023ZM8.00033 7.33329C8.00033 7.70148 7.70185 7.99996 7.33366 7.99996C6.96547 7.99996 6.66699 7.70148 6.66699 7.33329C6.66699 6.9651 6.96547 6.66663 7.33366 6.66663C7.70185 6.66663 8.00033 6.9651 8.00033 7.33329ZM13.3337 12.6666C13.3337 13.0348 13.0352 13.3333 12.667 13.3333C12.2988 13.3333 12.0003 13.0348 12.0003 12.6666C12.0003 12.2984 12.2988 12 12.667 12C13.0352 12 13.3337 12.2984 13.3337 12.6666Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </span>
  );
}

export default PercentDiscountsIcon;
