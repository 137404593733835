import React from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import {
  MultiBox,
  SingleBox,
  InputField,
} from "../forms/SelectField/FormElement";
import {
  initialValues as baseValues,
  propertyTypeOptions,
  stateOptions,
  validationSchema,
  counties,
  locationRangeOptions,
  typeOfInstitutionOptions,
  directLenderMortgageBrokerOptions,
  retailWholesaleOptions,
  typeOfFinancingOptions,
  recourseOptions,
  foreignNationalsConsideredOptions,
} from "../authenticated/borrower/searches/forms/helpers";
import { createHandleSubmit } from "../../utility/helpers";
import { landSearch } from "../../actions/searchActions";
export default LandSearch = function ({ providedValues }) {
  const dispatch = useDispatch();

  const initialValues = providedValues || baseValues;
  return (
    <Formik
      {...{ initialValues }}
      onSubmit={(values, actions) => dispatch(landSearch({ ...values }))}
    >
      {({ values, setFieldValue, touched, errors, submitForm }) => {
        return (
          <Form>
            <div className="w-full flex md:flex-row flex-col items-center justify-center md:space-x-3 bg-opacity-50 bg-white text-black text-start text-sm font-medium px-4 py-2 md:rounded-full rounded-lg">
              <div className="w-full md:w-56 flex-initial">
                <InputField
                  money={true}
                  name="loanAmountRequested"
                  color="congress-blue"
                  type="number"
                  label="Loan Amount"
                  step="1000"
                  required={true}
                />
              </div>
              <div className="w-full md:w-56 flex-initial z-30">
                <MultiBox
                  name="propertyTypeFinanced"
                  options={propertyTypeOptions}
                  label={"Property Type"}
                />
              </div>

              <div className="w-full h-full md:w-56 flex-initial">
                <SingleBox
                  name="locationState"
                  options={stateOptions}
                  label={"State"}
                />
              </div>
              <div className="w-full items-center m-1 p-1 text-center md:w-32 flex-initial border border-transparent text-base font-medium rounded-lg shadow-sm text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500">
                <a href="/login" className="btn text-center">
                  Search Now
                </a>
                {/* <button type="submit" className="text-center">
                      Search Now
                    </button> */}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
