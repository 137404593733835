import React, { Fragment, useEffect, useState } from "react";
import PageBody from "../general/PageBody";
import withGlobalProviders from "../../../components/general/withGlobalProvider";
import GhostContentAPI from "@tryghost/content-api";
import forEach from "lodash/forEach";
import map from "lodash/map";
import moment from "moment";
import List from "./loan_requests/index/List";
import Lenders from "./loan_requests/index/Lenders";
import { useDispatch } from "react-redux";
import { fetchAPILoanRequests } from "../../../actions/loanRequestActions";
import Brokers from "./loan_requests/brokers/Brokers";
import EmptyState from "./loan_requests/index/EmptyState";
import LenderSpotlight from "./loan_requests/index/LenderSpotlight";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import "../../../assets/css/searchButton.css";

function Dashboard() {
  // const user = useSelector((state) => state.session.currentUser);
  // const [posts, setPosts] = useState([]);

  // const api = new GhostContentAPI({
  //   host: 'https://lenderprism.ghost.io',
  //   key: 'de51275448401f3c6d86e29e76',
  //   version: "v3"
  // });

  // useEffect(() => {
  //   api.posts.browse({ limit: 5, filter: 'featured:true', include: 'tags,authors' }).then((loadedPosts) => {
  //     setPosts(loadedPosts);
  //   });
  // }, [])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAPILoanRequests());
  }, [dispatch]);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Dashboard
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-8 lg:px-8 flex justify-between items-center">
            <div className="flex gap-6">
              <h2 className="text-xl font-bold leading-tight text-gray-900">
                Recent Loan Requests
              </h2>
              <a className="font-bold text-congress-blue-600" href="/loans">See all</a>
            </div>
            <a
              href="/loans/new"
              className="ml-3 h-10 inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
            >
              Search for Active Lenders
              <span className="search-button">
                <span
                  className="tooltip"
                  tooltip="Use the Lender Funnel to search for hundreds of active multifamily and CRE lenders. We have their lending criteria. We also have contact info for real people at these shops."
                >
                  <QuestionMarkCircleIcon className="h-6 w-6 ml-2" />
                </span>
              </span>
            </a>
          </div>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <main>
                {/* Page header */}
                <div className="mt-4">
                  <List count={2} />
                </div>
                {/* Lender Section */}
                <Lenders />
                {/* Broker Section */}
                <div className="flex-col mt-10 mb-16">
                  <div className="flex justify-between w-full items-center">
                    <h2 className="text-xl font-bold leading-tight text-gray-900">
                      Brokers
                    </h2>
                    <a href="/brokers" className="ml-3 h-10 inline-flex items-center cursor-pointer px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500">
                      Find a broker
                    </a>
                  </div>
                  <Brokers />
                </div>
                {/* Empty section */}
                <div className="flex flex-col gap-6 md:flex-row">
                  <LenderSpotlight />
                  <EmptyState next={true} />
                </div>
              </main>
            </div>
          </main>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Dashboard);
