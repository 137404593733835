import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import concat from "lodash/concat";
import without from "lodash/without";
import uniq from "lodash/uniq";

import PageBody from "../../general/PageBody";
import withGlobalProviders from "../../../../components/general/withGlobalProvider";
import {
  CreditCardIcon,
  UsersIcon,
  ListBulletIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/20/solid";

import {
  fetchUserSavedOrganizations,
  fetchSavedOrganizations,
  destroySavedOrganization,
} from "../../../../actions/userSavedOrganizationActions";

import { fetchLoanRequests } from "../../../../actions/loanRequestActions";

import SavedLenders from "./savedLenders/List";
import PreferredLenders from "./preferredLenders/List";

import List from "./index/List";

const tabs = [
  { name: "Saved Lender Lists"},
  { name: "Preferred Lenders"},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Index({loan_requests, preferred_lenders, saved_searches_with_org, user_saved_organizations}) {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("Saved Lender Lists");
  // const { loaded } = useSelector(
  //   (state) => state.userSavedOrganizations
  // );
  const { loaded, items } = useSelector(
    (state) => state.userSavedOrganizations
  );
  // const userSavedOrganizations = useSelector(
  //   (state) => state.userSavedOrganizations.items
  // );

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(fetchUserSavedOrganizations());
    dispatch(fetchSavedOrganizations());
    dispatch(fetchLoanRequests());
  }, [dispatch]);

  const removeSavedOrganization = (id) => {
    dispatch(destroySavedOrganization(id));
  };

  const handleClick = (organizationId, remove = false) => {
    let actionMethod = remove ? without : concat;

    setSelected((previousSelected) =>
      uniq(actionMethod(previousSelected, organizationId))
    );
  };

  if (!loaded) return null;

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 text-shadow-sm">
              Lenders
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main>
              {/* Page header */}
              <div className="mt-8 ">
                <div className="hidden sm:block">
                  <nav
                    className="flex space-x-2  bg-gray-100 border-b border-gray-300"
                    aria-label="Tabs"
                  >
                    {tabs.map((tab) => (
                      <a
                        key={tab.name}
                        className={classNames(
                          tab.name === currentTab
                            ? "text-dark border-b-2 border-congress-blue-500"
                            : "text-gray-500 hover:text-gray-700",
                          " px-2.5 py-2 font-bold text-sm cursor-pointer flex justify-center items-center"
                        )}
                        aria-current={
                          tab.name === currentTab ? "page" : undefined
                        }
                        onClick={() => setCurrentTab(tab.name)}
                      >
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <section aria-labelledby="notes-title">
                  <div className="sm:overflow-hidden mt-6">
                    {currentTab === "Saved Lender Lists" && <SavedLenders lenders={loan_requests} prospectors={saved_searches_with_org} />}
                    {currentTab === "Preferred Lenders" && (
                      // <List
                      //   {...{ selected, handleClick, removeSavedOrganization }}
                      // />
                      <PreferredLenders lenders={preferred_lenders}/>
                    )}
                  </div>
                </section>
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
