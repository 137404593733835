const customStyles = {
  container: () => ({
    position: "relative",
  }),
  clearIndicator: () => ({}),
  dropdownIndicator: () => ({}),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorSeparator: () => ({}),
  input: () => ({
    maxHeight: 20,
    marginTop: -4
  }),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: (provided) => {
    return {
      ...provided,
      // maxHeight: '200px',
      // overflowY: 'hidden',
    }
  },
  menuList: () => ({
    maxHeight: "200px",
  }),
  // menuPortal: () => ({}),
  multiValue: () => ({
    color: "#fff !important",
    backgroundColor: "#1F2126 !important",
    fill: "#1F2126 !important",
    borderRadius: "4px",
    marginTop: "14px",
  }),
  multiValueRemove: () => ({
    color: "#fff !important",
    backgroundColor: "#1F2126 !important",
  }),
  multiValueLabel: () => ({
    backgroundColor: "#1F2126 !important",
    color: "#fff",
    borderRadius: "4px 0px 0px 4px",
    fill: "#1F2126 !important",
  }),
  noOptionsMessage: () => ({
  }),
  option: (base, state) => {
    if (state.isFocused) {
      return {
        backgroundColor: "#012758 !important",
        color: "#fff !important"
      };
    } else {
      return {color: "#111827 !important"};
    }
  },
  placeholder: () => ({
    marginTop: "0.35rem",
    marginBottom: "0.25rem",
  }),
  singleValue: () => ({
    // backgroundColor: "#2E96E1 !important"
    marginTop: "12px"
  }),
  valueContainer: () => ({
    // backgroundColor: "#2E96E1 !important"
  }),
  control: (base, state) => {
    let controlStyle = {
      borderRadius: "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      boxShadow: "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    };

    if (state.isFocused && state.selectProps.isError) {
      controlStyle = {
        boxShadow: "0 0 0 1px #EF4444",
        borderRadius: "0.375rem",
      };
    } else if (state.isFocused && state.selectProps.menuIsOpen) {
      controlStyle = {
        boxShadow: "0 0 0 1px #023E8A",
        borderRadius: "0.375rem",
      };
    }

    return controlStyle;
  },
};

export default customStyles;
