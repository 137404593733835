import React from "react";

function EditIcon({type, size, color}) {

  return (
    <svg className={`${color}`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2413 3.4915L12.366 2.36616C12.6005 2.13171 12.9184 2 13.25 2C13.5816 2 13.8995 2.13171 14.134 2.36616C14.3685 2.60062 14.5002 2.9186 14.5002 3.25016C14.5002 3.58173 14.3685 3.89971 14.134 4.13416L4.55467 13.7135C4.20222 14.0657 3.76758 14.3246 3.29 14.4668L1.5 15.0002L2.03333 13.2102C2.17552 12.7326 2.43442 12.2979 2.78667 11.9455L11.242 3.4915H11.2413ZM11.2413 3.4915L13 5.25016" stroke="#012758" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default EditIcon;