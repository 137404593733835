import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import join from "lodash/join";

import { Transition } from "@headlessui/react";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import EyeIcon from "../../../../../assets/svgs/EyeIcon";
import EditIcon from "../../../../../assets/svgs/EditIcon";
import DelIcon from "../../../../../assets/svgs/DelIcon";
import LenderItem from "./LenderItem";

function Lenders() {
  const { loaded, items } = useSelector((state) => state.loanRequests);
  
  const registeredLenders = items?.new_registered_lenders?.data.slice(0, 3)
  const preferredLenders = items?.preferred_lenders?.data.slice(0, 3)
  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-250"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-250"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={true}
      className="mt-12 grid grid-cols-2 gap-8"
    >
      <LenderItem title={"Preferred Lenders"} seeAll={true} lenders={preferredLenders} type={"preferred"} preferred={true}/>
      <LenderItem title={"New Registered Lenders"} seeAll={false} lenders={registeredLenders} type={"registered"} preferred={false}/>
    </Transition>
  );
}

export default Lenders;
