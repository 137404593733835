import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import axios from "axios";

import withGlobalProviders from "../../../../general/withGlobalProvider";

import Select from "../../../../forms/SelectField";
import InputField from "../../../../forms/InputField";
import { createHandleSubmit } from "../../../../../utility/helpers";

import {
  directLenderMortgageBrokerOptions,
  initialValues as baseValues,
  propertyTypeOptions,
  recourseOptions,
  stateOptions,
  typeOfFinancingOptions,
  typeOfInstitutionOptions,
  updateValidationSchema,
  validationSchema as defaultSchema,
} from "./helpers";

import { setLoading, updateSetup } from "../../../../../actions/setupActions";
import toast, { Toaster } from "react-hot-toast";

function LendingParameterForm({ providedValues }) {
  const isLoading = useSelector((state) => state.setup.loading);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const onSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      return dispatch(updateSetup(values));
    },
    handleSuccess: (response) => {
      if (response.profile.length > 0) {
        const csrf = document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content");
        if (file !== null) {
          let formData = new FormData();
          formData.append("document", file);
          formData.append("profile", response.profile);
          axios
            .post(`${window.location.href}/api/update-file`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                "X-CSRF-Token": csrf,
              },
              withCredentials: true,
            })
            .then((response) => {
              if (response.status == 200) {
                toast.success("Successfully updated lending parameters.");
                dispatch(setLoading(false))
                if (response.data) {
                  setIsModalOpen(true);
                }
              }
            })
            .catch(err => {
              dispatch(setLoading(false))
              toast.error(err.message);
            })
        } else {
          dispatch(setLoading(false))
          toast.success("Successfully updated lending parameters.");
          setIsModalOpen(true);
        }
      } else {
        // toast.success("Successfully updated lending parameters.")
        toast.error("Invalid parameters.");
        dispatch(setLoading(false))
      }
    },
    handleErrors: (errors) => {
      toast.error(errors);
      dispatch(setLoading(false))
    },
  });

  const initialValues = providedValues || baseValues;

  const [file, setFile] = useState(null);

  const onChangePicture = (e) => {
    setFile(e.target.files[0]);
  };

  let validationSchema = providedValues
    ? updateValidationSchema
    : defaultSchema;

  return (
    <div
      as="div"
      key={"lender-search-parameters"}
      aria-labelledby="lender-search-parameters"
      className="bg-white shadow-lg sm:rounded-lg"
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{
          marginTop: 60,
        }}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
        }}
      />
      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        <Form>
          <dd className="">
            <div className="border-gray-200 p-4">
              {!providedValues && (
                <div className="flex  px-6 pt-6 pb-3">
                  <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                    Personal Profile
                  </div>
                  <div className="w-2/3 gap-8 grid grid-cols-4">
                    <div className="sm:col-span-2">
                      <InputField
                        name="firstName"
                        type="text"
                        label="First Name"
                        color="congress-blue"
                        required={true}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="lastName"
                        type="text"
                        label="Last Name"
                        color="congress-blue"
                        required={true}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="officePhone"
                        type="text"
                        label="Office Phone"
                        color="congress-blue"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="personalPhone"
                        type="text"
                        label="Mobile Phone"
                        color="congress-blue"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="companyName"
                        type="text"
                        label="Company Name"
                        color="congress-blue"
                        required={true}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="title"
                        type="text"
                        label="Job Title"
                        color="congress-blue"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <Select
                        name="profileState"
                        options={stateOptions}
                        label={"State In Which You Are Based"}
                        isClearable={true}
                        required={true}
                        placeholder={<div className=" text-gray-500 text-base">State In Which You Are Based<span
                          className=" text-red-400 ml-1">*</span></div>}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="city"
                        type="text"
                        label="City"
                        color="congress-blue"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <InputField
                        name="file"
                        type="file"
                        label="Upload File"
                        color="congress-blue"
                        required={false}
                        onChange={onChangePicture}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex  px-6 pt-6 pb-3">
                <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                  Lending Parameters
                </div>
                <div className=" w-2/3 gap-8 grid grid-cols-6">
                  <div className="w-full col-span-6 lg:col-span-3">
                    <Select
                      name="typeOfInstitution"
                      options={typeOfInstitutionOptions}
                      isMulti={true}
                      label={"Type of Institution"}
                      required={true}
                      placeholder={<div className=" text-gray-500 text-base">Type of Institution<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                  <div className="w-full col-span-6 lg:col-span-3">
                    <Select
                      name="directLenderMortgageBroker"
                      options={directLenderMortgageBrokerOptions}
                      label={"Direct Lender / Mortgage Broker"}
                      isClearable={true}
                      required={true}
                      placeholder={<div className=" text-gray-500 text-base">Direct Lender / Mortgage Broker<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                  <div className="w-full col-span-6 lg:col-span-3">
                    <InputField
                      money={true}
                      name="minimumLoanAmount"
                      color="congress-blue"
                      type="number"
                      label={"Minimum Loan Amount"}
                      step="1000"
                      required={true}
                    />
                  </div>
                  <div className="w-full col-span-6 lg:col-span-3">
                    <InputField
                      money={true}
                      name="maximumLoanAmount"
                      color="congress-blue"
                      type="number"
                      label={"Maximum Loan Amount"}
                      step="1000"
                      required={true}
                    />
                  </div>
                  <div className="w-full col-span-6">
                    <Select
                      name="recourse"
                      options={recourseOptions}
                      label={"Recourse"}
                      isMulti={true}
                      required={true}
                      placeholder={<div className=" text-gray-500 text-base">Recourse<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                  <div className="w-full col-span-6">
                    <Select
                      name="lendingStates"
                      options={stateOptions}
                      label={"States In Which You Operate"}
                      isClearable={true}
                      required={true}
                      isMulti={true}
                      selectAll={true}
                      placeholder={<div className=" text-gray-500 text-base">States In Which You Operate<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                  <div className="w-full col-span-6">
                    <Select
                      name="propertyTypesFinanced"
                      options={propertyTypeOptions}
                      isMulti={true}
                      label={"Property Type Financed"}
                      required={true}
                      placeholder={<div className=" text-gray-500 text-base">Property Type Financed<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                  <div className="w-full col-span-6">
                    <Select
                      name="typeOfFinancing"
                      options={typeOfFinancingOptions}
                      label={"Type of Financing Provided"}
                      isMulti={true}
                      required={true}
                      placeholder={<div className=" text-gray-500 text-base">Type of Financing Provided<span
                        className=" text-red-400 ml-1">*</span></div>}
                    />
                  </div>
                </div>
              </div>
              <div className="flex px-6 py-3 bg-gray-50">
                <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                  Elevator Pitch
                </div>
                <div className=" w-2/3 gap-8 grid grid-cols-6">
                  <div className="w-full col-span-6">
                    <InputField
                      name="elevatorPitch"
                      type="textarea"
                      label="Elevator Pitch"
                      color="congress-blue"
                    />
                  </div>
                </div>
              </div>
              <div className="flex  px-6 py-3">
                <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                  Comments
                </div>
                <div className=" w-2/3 gap-8 grid grid-cols-6">
                  <div className="w-full col-span-6">
                    <InputField
                      name="comments"
                      type="textarea"
                      label="Comments"
                      color="congress-blue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </dd>
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-md">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-congress-blue-600 text-base font-medium text-white hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {isLoading ? 'Saving...' : 'Save Account Settings'}
            </button>
          </div>
        </Form>
      </Formik>

      <div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 lg:w-3/5 rounded-lg shadow-lg hover:cursor-default z-50 py-12 px-6 md:px-8 lg:px-10">
              <p className="text-2xl font-medium text-black text-center">
                Your registration is pending approval by the LenderPrism admin. Be on the lookout for an email from the LenderPrism admin.
              </p>
              <div className="flex flex-row justify-around mt-8">
                <a href="/"
                   className="rounded-lg text-center text-white bg-congress-blue-600 mt-5 lg:mt-0 text-md px-4 py-1 md:py-2"
                >
                  Go to Login
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withGlobalProviders(LendingParameterForm);
