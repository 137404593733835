import React from "react";
import { useDispatch } from "react-redux";

import { Formik, Form } from "formik";

import withGlobalProviders from "../../../../general/withGlobalProvider";

import Select from "../../../../forms/SelectField";
import InputField from "../../../../forms/InputField";
import { createHandleSubmit } from "../../../../../utility/helpers";
import {
  createSearch,
  updateSearch,
} from "../../../../../actions/searchActions";

import {
  initialValues as baseValues,
  validationSchema,
  propertyTypeOptions,
  stateOptions,
  counties,
  locationRangeOptions,
  typeOfInstitutionOptions,
  directLenderMortgageBrokerOptions,
  retailWholesaleOptions,
  typeOfFinancingOptions,
  recourseOptions,
  foreignNationalsConsideredOptions,
} from "./helpers";

function SearchForm({ providedValues }) {
  const dispatch = useDispatch();
  const onSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      if (providedValues) {
        return dispatch(updateSearch(providedValues.id, { ...values }));
      } else {
        return dispatch(createSearch({ ...values }));
      }
    },
    handleSuccess: (redirect) => {
      if (redirect) {
        window.location.assign(redirect);
      }
    },
    handleErrors: () => {},
  });

  const initialValues = providedValues || baseValues;

  return (
    <div
      as="div"
      key={"lender-search-parameters"}
      aria-labelledby="lender-search-parameters"
      className="bg-white shadow-lg sm:rounded-lg"
    >
      <Formik {...{ initialValues, validationSchema, onSubmit }}>
        {({ values, setFieldValue, touched, errors, submitForm }) => {
          return (
            <Form className="p-8">
              <div>
                <div className="flex  p-6">
                  <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                    Search Details
                  </div>
                  <div className="flex flex-col w-2/3 gap-6">
                    <div className="w-full">
                      <InputField
                        name="name"
                        type="text"
                        label="Search Name"
                        color="congress-blue"
                      />
                    </div>
                    <div className="w-full flex flex-col gap-8 lg:flex-row">
                      <div className="w-full">
                        <InputField
                          money={true}
                          name="loanAmountRequested"
                          color="congress-blue"
                          type="number"
                          label={"Loan Amount Requested"}
                          step="1000"
                          required={true}
                        />
                      </div>
                      <div className="w-full">
                        <Select
                          name="propertyTypeFinanced"
                          options={propertyTypeOptions}
                          isMulti={true}
                          label={"Property Type Financed"}
                          required={true}
                          placeholder={<div className=" text-gray-500 text-base">Property Type Financed<span className=" text-red-500 ml-1">*</span></div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex p-6 bg-gray-50">
                  <div className="w-1/3 my-2 text-lg leading-5 font-medium text-gray-900">
                    Search By Location
                  </div>
                  <div className="flex flex-col w-2/3 gap-6">
                    <div className="w-full flex flex-col gap-8 lg:flex-row">
                      <div className="w-full">
                        <InputField
                          name="locationCity"
                          type="text"
                          label="City"
                          color="congress-blue"
                        />
                      </div>
                      <div className="w-full">
                        <Select
                          name="locationState"
                          options={stateOptions}
                          label={"State"}
                          isClearable={true}
                          required={true}
                          placeholder={<div className=" text-gray-500 text-base">State<span className=" text-red-500 ml-1">*</span></div>}
                        />
                      </div>
                      <div className="w-full">
                        <InputField
                          name="locationPostalCode"
                          type="text"
                          label="Postal Code"
                          color="congress-blue"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-8 lg:flex-row">
                      <div className="w-full">
                        <Select
                          name="locationCounty"
                          label={"County"}
                          requires={"locationState"}
                          requiresOptions={counties}
                          isClearable={true}
                          placeholder={<div className=" text-gray-500 text-base">County<span className=" text-gray-300 ml-1">(Optional)</span></div>}
                        />
                      </div>
                      <div className="w-full">
                        <Select
                          name="locationRange"
                          isClearable={true}
                          options={locationRangeOptions}
                          label={"Range / Distance"}
                          requires={"locationPostalCode"}
                          required={true}
                          placeholder={<div className=" text-gray-500 text-base">Range / Distance<span className=" text-red-500 ml-1">*</span></div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end text-sm font-medium text-gray-500 py-4 hover:text-gray-700 sm:rounded-b-lg">
                <button
                  type="submit"
                  className="inline-flex items-center w-min justify-center mr-2 px-7 py-4 whitespace-nowrap border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                >
                  {providedValues ? "Update Search" : "Create Search"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withGlobalProviders(SearchForm);
