import "regenerator-runtime";
import React, { useState } from "react";
import moment from "moment";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Button, PageButton } from "./shared/Button";
import { SortDownIcon, SortUpIcon, SortIcon } from "./shared/Icon";
import { classNames } from "./shared/Utils";
import { MapProvider } from "./map-context";
import MarkerButton from "./MarkerButton";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline relative">
      <label className="absolute top-[14px] left-[14px]">
        <MagnifyingGlassIcon className="w-6 h-6 text-gray-500" />
      </label>
      <input
        type="text"
        className="block w-full py-3 pl-12 border border-gray-200 bg-white rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search`}
      />
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <select
        className="block w-full py-3 pl-3 pr-10 border border-gray-200 bg-white rounded focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="" className=" text-gray-300">
          {render("Header")}
        </option>
        {options.map((option, i) => (
          <option
            key={i}
            value={option}
            className="hover:bg-congress-blue-600 hover:text-white active:bg-white active:text-congress-blue"
          >
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-2 uppercase leading-wide font-bold text-xs rounded-md",
        status.startsWith("processing") ? "bg-cyan-100 text-cyan-800" : null,
        status.startsWith("quoting") ? "bg-yellow-100 text-yellow-700" : null,
        status.startsWith("closed") ? "bg-red-100 text-gray-700" : null,
        status.startsWith("draft") ? "bg-red-100 text-red-700" : null
      )}
    >
      {status}
    </span>
  );
}
export function PriceRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values.price : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values.price : 0;

    preFilteredRows.forEach((row) => {
      min = Math.min(row.values.price, min);
      max = Math.max(row.values.price, max);
    });
    return [min, max];
  }, [preFilteredRows]);

  return (
    <div style={{ display: "flex" }}>
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min ($${min})`}
        style={{ width: "70px", marginRight: "0.5rem" }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max ($${max})`}
        style={{ width: "70px", marginLeft: "0.5rem" }}
      />
    </div>
  );
}

export function LocateCell({ value, column, row }) {
  // function handleClick() {
  //   row.original[column.mapAccessor].flyTo(row.original[column.posAccessor]);
  // }

  return (
    <>
      <MapProvider>
        <MarkerButton pos={row.original[column.posAccessor]} />
      </MapProvider>
    </>
  );
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClickHint, setIsClickHint] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = (data) => {
    setModalData(data.original.attributes);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsClickHint(true);
  };

  const handleMouseLeave = () => {
    setIsClickHint(false);
  };

  // Render the UI for your table
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-5 items-center">
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div key={column.id} className="text-sm">
                  {column.render("Filter")}
                </div>
              ) : null
            )
          )}
        </div>
        <div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-col overflow-hidden">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-t-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-white rounded-t-lg">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className={`group p-6 pb-4 px-4 text-left text-sm font-medium uppercase tracking-wider ${
                            column.isSorted || column.isSortedDesc
                              ? "text-black"
                              : "text-gray-500"
                          }`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-center gap-1 whitespace-nowrap">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white [&>*:nth-child(even)]:bg-gray-50"
                >
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="p-6 whitespace-nowrap"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        <td className="relative">
                          <button
                            className=" p-6 whitespace-nowrap"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            <EllipsisVerticalIcon className="w-6 text-gray-400 hover:cursor-pointer" />
                          </button>
                          {/* {!isClickHint && (
                            <div className="absolute flex flex-col bg-white p-4 rounded-md shadow-md bottom-0 left-0 transform translate-y-full transition-all duration-300 ease-in-out min-w-[430px]">
                              <button>Archive</button>
                              <button>View</button>
                              <button>Delete</button>
                            </div>
                          )} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 px-6 flex items-center justify-between bg-white sm:rounded-b-lg shadow-lg">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center gap-x-2 justify-between w-full pr-3">
            <span className="text-sm text-gray-400">
              Showing{" "}
              <span className="font-medium">
                {state.pageIndex * state.pageSize + 1} -{" "}
                {(state.pageIndex + 1) * state.pageSize}
              </span>{" "}
              of <span className="font-medium">{data.length}</span>
            </span>
            <label className="flex items-center justify-center gap-1">
              <p className=" whitespace-nowrap text-gray-400">Show loans</p>
              <select
                className="block w-full border border-gray-300 bg-white rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize} className="text-sm">
                    {pageSize}
                  </option>
                ))}
              </select>
              <p className=" whitespace-nowrap text-gray-400">per page</p>
            </label>
          </div>
          <div>
            <nav
              className="inline-flex rounded-md shadow-sm -space-x-px gap-1"
              aria-label="Pagination"
            >
              <PageButton
                className=""
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className=""
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>

      <div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40 hover:cursor-default">
            <div className="bg-white w-4/5 rounded-lg shadow-lg flex flex-col relative hover:cursor-default z-50">
              <div className="flex border-b border-gray-300 p-6 gap-2">
                <p className=" text-2xl font-bold">Loan Request</p>
              </div>
              <div className="flex border-b border-gray-300 p-6 gap-2">
                <div className="flex flex-col">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Request of $
                    {(modalData.loanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                    for {modalData.propertyType || "Unspecified Property"}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Created:{" "}
                    {moment(modalData.createdAt).format("MMMM Do [at] h:mm A ")}{" "}
                    | Last Updated:{" "}
                    {moment(modalData.updatedAt).format("MMMM Do [at] h:mm A ")}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Loan Amount Requested
                  </p>
                  <p className="text-sm font-medium text-black">
                    $
                    {(modalData?.loanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Property Type
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.propertyType}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Comments
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {modalData?.propertyComments}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    City
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.locationCity}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    State
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.locationState}
                  </p>
                </div>
                <div className="flex flex-col col-span-2">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Postal Code
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {modalData?.postalCode}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Type of Financing Provided
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.typeOfFinancing}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Type
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.transactionType}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Price
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    $
                    {(modalData?.purchasePrice / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Under Contract
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    not clear
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4 border-b border-gray-300 p-6 gap-4">
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    {modalData?.borrowerName}
                  </p>
                  <p className="text-sm font-medium text-black">firstName</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    {modalData?.borrowerName}
                  </p>
                  <p className="text-sm font-medium text-black">lastName</p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Email
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {modalData?.borrowerEmail}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Mobile Number
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {modalData?.borrowerMobileNumber}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    City
                  </p>
                  <p className="text-sm font-medium text-black">
                    {modalData?.borrowerName}
                  </p>
                </div>
                <div className="flex flex-col col-span-1">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    State
                  </p>
                  <p className="text-sm font-medium text-black">state</p>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2 p-6">
                <div className="flex flex-col col-span-2">
                  <p className=" text-sm mb-1 font-medium text-gray-400">
                    Transaction Summary
                  </p>
                  <p className="text-sm font-medium text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {modalData?.transactionSummary}
                  </p>
                </div>
              </div>
              <p
                onClick={closeModal}
                className=" absolute top-3 right-4 hover:cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.28015 5.21985C6.13798 5.08737 5.94993 5.01524 5.75563 5.01867C5.56133 5.0221 5.37594 5.10081 5.23853 5.23822C5.10112 5.37564 5.02241 5.56102 5.01898 5.75532C5.01555 5.94963 5.08767 6.13767 5.22015 6.27985L8.94015 9.99985L5.22015 13.7198C5.14647 13.7885 5.08736 13.8713 5.04637 13.9633C5.00538 14.0553 4.98334 14.1546 4.98156 14.2553C4.97979 14.356 4.99831 14.4561 5.03603 14.5494C5.07375 14.6428 5.1299 14.7277 5.20112 14.7989C5.27233 14.8701 5.35717 14.9262 5.45056 14.964C5.54394 15.0017 5.64397 15.0202 5.74468 15.0184C5.84538 15.0167 5.94469 14.9946 6.03669 14.9536C6.12869 14.9126 6.21149 14.8535 6.28015 14.7798L10.0002 11.0598L13.7202 14.7798C13.7888 14.8535 13.8716 14.9126 13.9636 14.9536C14.0556 14.9946 14.1549 15.0167 14.2556 15.0184C14.3563 15.0202 14.4564 15.0017 14.5498 14.964C14.6431 14.9262 14.728 14.8701 14.7992 14.7989C14.8704 14.7277 14.9266 14.6428 14.9643 14.5494C15.002 14.4561 15.0205 14.356 15.0187 14.2553C15.017 14.1546 14.9949 14.0553 14.9539 13.9633C14.9129 13.8713 14.8538 13.7885 14.7802 13.7198L11.0602 9.99985L14.7802 6.27985C14.9126 6.13767 14.9848 5.94963 14.9813 5.75532C14.9779 5.56102 14.8992 5.37564 14.7618 5.23822C14.6244 5.10081 14.439 5.0221 14.2447 5.01867C14.0504 5.01524 13.8623 5.08737 13.7202 5.21985L10.0002 8.93985L6.28015 5.21985Z"
                    fill="#747A88"
                  />
                </svg>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Table;
