import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import map from "lodash/map";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import { fetchLoanRequestMatches } from "../../../../../actions/loanRequestMatchesActions";

import MatchListItem from "./MatchListItem";
import SelectedMatchListItem from "./SelectedMatchListItem";
import MatchListEmpty from "./MatchListEmpty";
import { createHandleSubmit } from "../../../../../utility/helpers";
import {
  createUserSavedLenders,
  fetchUserSavedLenders,
  fetchSavedLenders,
  destroySavedLender,
} from "../../../../../actions/userSavedLendersActions";

function MatchList({ matchTab, showPreferLender, setMatchTab, loanRequestId, setLenderNumber, setLoanAllMatches, removeAllLenders, allSelectedItems, setAllSelectedItems }) {
  const [visibleMatches, setVisibleMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [processedTab, setProcessedTab] = useState();
  const [matches, setMatches] = useState([]);
  const dispatch = useDispatch();
  const [selectedLoanRequests, setSelectedLoanRequests] = useState([]);
  const selectLoanRequestMatches = createSelector(
    (state) => state.loanRequestMatches.items,
    (loanRequestMatches) =>
      filter(
        loanRequestMatches,
        (loanRequestMatch) =>
          loanRequestMatch.attributes.loanRequestId === loanRequestId
      )
  );
  const loanRequestMatches = useSelector(selectLoanRequestMatches);
  const selectSavedItems = createSelector(
    (state) => state.loanRequestMatches.selectedItems,
    (selectedItems) => selectedItems
  );
  const savedItems = useSelector(selectSavedItems);
  useEffect(
    () => {
      setMatches(allSelectedItems)
    }, [allSelectedItems]
  )
  useEffect(
    () => {
      setLoanAllMatches(visibleMatches)
    }, [visibleMatches]
  )
  useEffect(() => {
    setSelectedMatches(savedItems);
  }, [savedItems]);
  useEffect(() => {
    dispatch(fetchLoanRequestMatches({ loanRequestId }));
  }, [dispatch]);
  useEffect(() => {
    if (
      loanRequestMatches &&
      loanRequestMatches?.length > 0 &&
      matchTab !== processedTab
    ) {
      switch (matchTab) {
        case "new":
          setVisibleMatches(
            filter(
              loanRequestMatches,
              ({ attributes }) =>
                !(attributes.interestedAt || attributes.notInterestedAt)
            )
          );
          setProcessedTab(matchTab);
          break;
        case "interested":
          setVisibleMatches(
            filter(
              loanRequestMatches,
              ({ attributes }) => attributes.interestedAt
            )
          );
          setProcessedTab(matchTab);
          break;
        case "not_interested":
          setVisibleMatches(
            filter(
              loanRequestMatches,
              ({ attributes }) => attributes.notInterestedAt
            )
          );
          setProcessedTab(matchTab);
          break;
        case "all":
        default:
          setVisibleMatches(loanRequestMatches);
          setProcessedTab(matchTab);
          break;
      }
    }
  }, [matchTab, loanRequestMatches]);
  const handleSelect = (id) => {
    if (selectedLoanRequests.includes(id)) {
      setSelectedLoanRequests(
        selectedLoanRequests.filter((itemId) => itemId !== id)
      );
    } else {
      setSelectedLoanRequests([...selectedLoanRequests, id]);
    }
  };
  setLenderNumber(loanRequestMatches.length);

  const onSubmit = createHandleSubmit({
    handleSubmit: () => {
      const newItems = matches;
      return dispatch(
        createUserSavedLenders(newItems, loanRequestId)
      );
    },
    handleSuccess: () => {
      window.location.assign("/lenders");
    },
    handleErrors: () => {},
  });

  return (
    <div className="mt-8 rounded-lg bg-white flex flex-col gap-5">
      <div className="p-4 flex flex-col gap-5 [&>*:nth-child(even)]:bg-gray-50 overflow-x-scroll">
        {visibleMatches &&
          visibleMatches.length > 0 &&
          map(showPreferLender? visibleMatches.filter(item => item.attributes.interestedAt):visibleMatches, ({ id }) => (
            <MatchListItem
              key={id}
              {...{ loanRequestId, id, matches, setMatches, allSelectedItems }}
            />
          ))}
      </div>
      <div className="p-4 flex flex-col gap-5 border-t border-gray-300 [&>*:nth-child(even)]:bg-gray-50 overflow-x-scroll">
        <div className="p-4 pb-0">
          <p>selected lenders</p>
        </div>
        {map((matches), ({ id }) => {
          return <SelectedMatchListItem key={id} {...{ loanRequestId, id, matches, setMatches }} />;
        })}
        <div className="w-full flex gap-4 justify-end pr-6 pb-6">
          <button onClick={(e) =>{setMatches([]),removeAllLenders()}} className="ml-3 h-10 inline-flex items-center px-4 py-2 border border-gray-600 rounded-md text-sm font-medium text-black bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Clear
          </button>
          <button
            onClick={onSubmit}
            className="ml-3 h-10 inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
          >
            Save Selected Lenders
          </button>
        </div>
      </div>
    </div>
  );
}

export default withGlobalProviders(MatchList);
