import React, { useState } from "react";
import withGlobalProviders from "../../../../general/withGlobalProvider";

import ListItem from './ListItem'

const mokeSavedLendersList = [
  "123", "234", "345", "456", "567", "678",
]

function Items() {
  // const { loaded, items } = useSelector((state) => state.loanRequests);
  const [savedLenders, setSavedLenders] = useState(mokeSavedLendersList)
  // const slicedItems = take(itemsArray, 2);

  if (!savedLenders)
    return <p>there are no saved preferred lenders</p>;

  return (
    <div className="flex space-y-6 flex-col">
      {savedLenders.map((lenderID) => (
        <ListItem {...{ key: lenderID, id: lenderID }} />
      ))}
    </div>
  );
}

export default withGlobalProviders(Items);
