import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import withGlobalProviders from "../../../general/withGlobalProvider";
import PageBody from "../../general/PageBody";
import { fetchLoanRequests } from "../../../../actions/loanRequestActions";

import List from "./index/List";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLoanRequests());
  }, [dispatch]);

  return (
    <PageBody>
      <div className="py-10 bg-gray-100 flagged">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-end">
            <div className="flex flex-col gap-6">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Lender Funnel
              </h1>
              <p>Search for hundreds of active multifamily and CRE lenders. We have their lending criteria.
                <br/>We also have contact info for real people at these shops.</p>
            </div>
            <a
              href="/loans/new"
              className="ml-3 h-10 inline-flex items-center px-4 py-2 border border-transparent rounded text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
            >
              Search for Active Lenders
              <div
                title="Use the Lender Funnel to search for hundreds of active multifamily and CRE lenders. We have their lending criteria. We also have contact info for real people at these shops.">
                <QuestionMarkCircleIcon className="h-6 w-6 ml-2"/>
              </div>
            </a>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <main>
              {/* Page header */}
              <div className="mt-8 flex flex-col gap-6">
                <h2 className="text-xl font-bold leading-tight">Loan Requests</h2>
                <List count={0}/>
              </div>
            </main>
          </div>
        </main>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
