import React from "react";

function MapPinIcon({type, size, color}) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 11C15 11.7956 14.6839 12.5587 14.1213 13.1213C13.5587 13.6839 12.7956 14 12 14C11.2044 14 10.4413 13.6839 9.87868 13.1213C9.31607 12.5587 9 11.7956 9 11C9 10.2044 9.31607 9.44129 9.87868 8.87868C10.4413 8.31607 11.2044 8 12 8C12.7956 8 13.5587 8.31607 14.1213 8.87868C14.6839 9.44129 15 10.2044 15 11Z" stroke="#1F2126" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.1864 13.5C19.3861 12.7141 19.5 11.8801 19.5 11C19.5 9.01088 18.7098 7.10322 17.3033 5.6967C15.8968 4.29018 13.9891 3.5 12 3.5C10.0109 3.5 8.10322 4.29018 6.6967 5.6967C5.29018 7.10322 4.5 9.01088 4.5 11C4.5 18.142 12 22.25 12 22.25C12 22.25 12.3934 22.0345 13 21.6165" stroke="#1F2126" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.481 19.981L20 21.5M18.481 19.981C18.7248 19.7372 18.9183 19.448 19.0502 19.1295C19.1821 18.8111 19.25 18.4697 19.25 18.125C19.25 17.4288 18.9734 16.7611 18.4812 16.2688C17.9889 15.7766 17.3212 15.5 16.625 15.5C15.9288 15.5 15.2611 15.7766 14.7688 16.2688C14.2766 16.7611 14 17.4288 14 18.125C14 18.4697 14.0679 18.8111 14.1998 19.1295C14.3317 19.448 14.5251 19.7374 14.7688 19.9812C15.0126 20.2249 15.302 20.4183 15.6205 20.5502C15.9389 20.6821 16.2803 20.75 16.625 20.75C16.9697 20.75 17.3111 20.6821 17.6295 20.5502C17.948 20.4183 18.2372 20.2248 18.481 19.981Z" stroke="#1F2126" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default MapPinIcon;
