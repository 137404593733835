import React from 'react'

const LenderSpotlight = () => {

  return (
    <div className='p-8 border border-purple-600 rounded-xl flex flex-col gap-6 md:max-w-[385px]'>
      <div className='flex justify-start items-center gap-6'>
        <img className="w-16 h-16"
          src='https://lenderprism-v2-public.s3.us-west-1.amazonaws.com/images/M 2-01.png' alt='M Logo'/>
        <h2 className='text-xl font-bold leading-tight text-gray-900'>
          Lender Spotlight
        </h2>
      </div>
      <div>
        <p className='mt-1 text-sm text-gray-900'>
          The Lender Spotlight will feature one or more lenders each week. Lenders who will offer brief presentations
          and take your questions.
        </p>
      </div>
      <div>
        <a href="https://multifamilyfountainhead.com/spaces/11762349?utm_source=manual" target="_blank"
           className='text-end font-bold'>Join <span
          className='text-purple-600 font-extrabold'>the Lender Spotlight</span></a>
      </div>
    </div>
  )
}

export default LenderSpotlight;