import React from "react";
import { useDispatch, useSelector } from "react-redux";

import withGlobalProviders from "../../../../general/withGlobalProvider";
import { updateLoanRequestMatchInterest } from "../../../../../actions/loanRequestMatchesActions";
import MarkIcon from "../../../../../assets/svgs/MarkIcon";
import DelIcon from "../../../../../assets/svgs/DelIcon";
import { deleteLoanRequestMatches } from "../../../../../actions/loanRequestMatchesActions";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SelectedMatchListItem({ loanRequestId, id, matches, setMatches }) {
  const dispatch = useDispatch();
  const handleInterest = (interest) => {
    dispatch(updateLoanRequestMatchInterest({ loanRequestId, id, interest }));
  };
  const loanRequest = useSelector((state) => state.loanRequestMatches.items[id]);

  return (
    <div className="min-w-[900px]">
      <div className="">
        <div className="p-4 sm:px-6">
          <dl
            className="grid grid-cols-1 gap-x-4 gap-y-8"
            style={{ gridTemplateColumns: "repeat(24, minmax(0, 1fr))" }}
          >
            {loanRequest.attributes.notInterestedAt ? (
              <button
                className="sm:col-span-1 self-center"
                onClick={() => handleInterest(true)}
              >
                <MarkIcon filled={false} />
              </button>
            ) : (
              <button
                className="sm:col-span-1 self-center"
                onClick={() => handleInterest(false)}
              >
                <MarkIcon filled={true} />
              </button>
            )}
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">Lender</dt>
              {/* <dd className="mt-1 text-sm text-gray-900">{bankName}</dd> */}
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                {loanRequest?.attributes?.userprofile?.companyName
                  ? loanRequest?.attributes?.userprofile?.companyName
                  : "LenderPrism"}
              </dd>
            </div>

            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Minimum Loan Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                $
                {loanRequest?.attributes?.minimumLoanAmountCents
                  ? (loanRequest?.attributes?.minimumLoanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : "0"}
              </dd>
            </div>

            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Maximum Loan Amount
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                $
                {loanRequest?.attributes?.maximumLoanAmountCents
                  ? (loanRequest?.attributes?.maximumLoanAmountCents / 100.0)
                      .toFixed()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : "0"}
              </dd>
            </div>
            <div style={{ gridColumn: "span 5" }} className="self-center">
              <dt className="text-sm font-medium text-gray-500">
                Financing Services
              </dt>
              {/* <dd className="mt-1 text-sm text-gray-900">{typeOfFinancing}</dd> */}
              <dd className="mt-1 text-sm text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis">
                {loanRequest?.attributes?.typeOfFinancing
                  ? loanRequest?.attributes?.typeOfFinancing
                  : ""}
              </dd>
            </div>
            <div  className="justify-self-end self-center" style={{ gridColumn: "span 3" }}>
              <button
              onClick={() => {setMatches(matches.filter((item) => {return item.id != loanRequest.id}));dispatch(deleteLoanRequestMatches({loanRequest}));}}
                rel="noopener noreferrer"
                className="cursor-pointer px-4 py-2"
              >
                <DelIcon color={"gray"} />
              </button>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default withGlobalProviders(SelectedMatchListItem);
