import React from "react";

function PrivacyTermsIcon({className, style}) {

  return (
    <span className={className} style={style}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_907_2112" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="38" height="38">
          <path d="M19.9206 1.66666L4.2986 1.68538L8.40051 5.78729C4.29118 9.14447 1.6665 14.2516 1.6665 19.9721C1.6665 20.0348 1.66682 20.0976 1.66745 20.1602L1.68317 35.701L5.788 31.5991C9.14519 35.7084 14.2523 38.3331 19.9728 38.3331C25.9259 38.3331 31.2146 35.4916 34.5578 31.091L27.8343 24.2968C26.2803 27.1708 23.2413 29.0175 19.973 29.0175C17.0343 29.0175 14.1074 27.292 12.5465 24.8424L17.3058 20.0831L10.9828 20.0819C10.9823 20.0454 10.9821 20.0088 10.9821 19.9723C10.9821 17.0336 12.7076 14.1067 15.1573 12.5458L19.9165 17.3051L19.9177 10.983C19.9543 10.9825 19.9909 10.9823 20.0275 10.9823C22.9662 10.9823 25.8932 12.7078 27.454 15.1574L22.6947 19.9167L38.3326 19.9197L38.3332 19.9208L38.3144 4.29876L34.2125 8.40066C30.8553 4.29134 25.7482 1.66666 20.0278 1.66666C19.9918 1.66666 19.9565 1.66645 19.9206 1.66666Z" fill="black"/>
        </mask>
        <g mask="url(#mask0_907_2112)">
          <path d="M38.3333 19.9208C38.3333 19.8933 38.3146 4.29879 38.3146 4.29879L34.2127 8.40069C30.8555 4.29137 25.7483 1.66669 20.0279 1.66669C14.0748 1.66669 8.78609 4.50822 5.44287 8.90879L12.1664 15.703C13.7204 12.829 16.7593 10.9823 20.0277 10.9823C22.9663 10.9823 25.8933 12.7078 27.4541 15.1574L22.6949 19.9167L38.3328 19.9197" fill="#1C3AA9"/>
          <g filter="url(#filter0_d_907_2112)">
            <path d="M19.9206 1.66669L4.2986 1.68541L8.40051 5.78732C4.29118 9.1445 1.6665 14.2516 1.6665 19.9721C1.6665 25.9252 4.50808 31.2139 8.90861 34.5571L15.7029 27.8336C12.8289 26.2796 10.9821 23.2407 10.9821 19.9723C10.9821 17.0337 12.7076 14.1067 15.1573 12.5459L19.9165 17.3051L19.9196 1.66721" fill="#4285F4"/>
          </g>
          <g filter="url(#filter1_d_907_2112)">
            <path d="M1.66748 20.079L1.68328 35.701L5.78812 31.5991C9.1453 35.7084 14.2524 38.3331 19.9729 38.3331C25.926 38.3331 31.2147 35.4916 34.5579 31.091L27.8344 24.2967C26.2804 27.1707 23.2415 29.0175 19.9731 29.0175C17.0345 29.0175 14.1075 27.292 12.5467 24.8423L17.3059 20.0831L1.668 20.08" fill="#ABABAB"/>
          </g>
        </g>
        <defs>
          <filter id="filter0_d_907_2112" x="-4.3335" y="-4.33331" width="30.2539" height="44.8904" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="3"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_907_2112"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_907_2112" result="shape"/>
          </filter>
          <filter id="filter1_d_907_2112" x="-4.33252" y="14.079" width="44.8906" height="30.2541" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="3"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_907_2112"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_907_2112" result="shape"/>
          </filter>
        </defs>
      </svg>
    </span>
  );
}

export default PrivacyTermsIcon;
