import React from "react";

function BarsUserIcon({type, size, color}) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25 12C9.25 12.4142 9.58579 12.75 10 12.75C10.4142 12.75 10.75 12.4142 10.75 12H9.25ZM10.75 8C10.75 7.58579 10.4142 7.25 10 7.25C9.58579 7.25 9.25 7.58579 9.25 8H10.75ZM13.25 16C13.25 16.4142 13.5858 16.75 14 16.75C14.4142 16.75 14.75 16.4142 14.75 16H13.25ZM14.75 8C14.75 7.58579 14.4142 7.25 14 7.25C13.5858 7.25 13.25 7.58579 13.25 8H14.75ZM17.25 16C17.25 16.4142 17.5858 16.75 18 16.75C18.4142 16.75 18.75 16.4142 18.75 16H17.25ZM18.75 10C18.75 9.58579 18.4142 9.25 18 9.25C17.5858 9.25 17.25 9.58579 17.25 10H18.75ZM1.25 10C1.25 10.4142 1.58579 10.75 2 10.75C2.41421 10.75 2.75 10.4142 2.75 10H1.25ZM12 19.25C11.5858 19.25 11.25 19.5858 11.25 20C11.25 20.4142 11.5858 20.75 12 20.75V19.25ZM6.25 15C6.25 15.6904 5.69036 16.25 5 16.25V17.75C6.51878 17.75 7.75 16.5188 7.75 15H6.25ZM5 16.25C4.30964 16.25 3.75 15.6904 3.75 15H2.25C2.25 16.5188 3.48122 17.75 5 17.75V16.25ZM3.75 15C3.75 14.3096 4.30964 13.75 5 13.75V12.25C3.48122 12.25 2.25 13.4812 2.25 15H3.75ZM5 13.75C5.69036 13.75 6.25 14.3096 6.25 15H7.75C7.75 13.4812 6.51878 12.25 5 12.25V13.75ZM8.99485 20.25H1.00515V21.75H8.99485V20.25ZM8.25 20.9949C8.25 20.5835 8.5835 20.25 8.99485 20.25V21.75C9.41189 21.75 9.75 21.4119 9.75 20.9949H8.25ZM5 17.75C6.79334 17.75 8.25 19.204 8.25 20.9949H9.75C9.75 18.3731 7.61925 16.25 5 16.25V17.75ZM1.75 20.9949C1.75 19.204 3.20666 17.75 5 17.75V16.25C2.38075 16.25 0.25 18.3731 0.25 20.9949H1.75ZM0.25 20.9949C0.25 21.4119 0.588089 21.75 1.00515 21.75V20.25C1.41652 20.25 1.75 20.5835 1.75 20.9949H0.25ZM10.75 12V8H9.25V12H10.75ZM14.75 16L14.75 8H13.25L13.25 16H14.75ZM18.75 16V10H17.25V16H18.75ZM4 4.75H20V3.25H4V4.75ZM21.25 6V18H22.75V6H21.25ZM21.25 18C21.25 18.6904 20.6904 19.25 20 19.25V20.75C21.5188 20.75 22.75 19.5188 22.75 18H21.25ZM20 4.75C20.6904 4.75 21.25 5.30964 21.25 6H22.75C22.75 4.48122 21.5188 3.25 20 3.25V4.75ZM4 3.25C2.48122 3.25 1.25 4.48122 1.25 6H2.75C2.75 5.30964 3.30964 4.75 4 4.75V3.25ZM2.75 10V6H1.25V10H2.75ZM20 19.25H12V20.75H20V19.25Z" fill="#012758"/>
    </svg>
  );
}

export default BarsUserIcon;
