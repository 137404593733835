import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PageBody from "../general/PageBody";
import { CheckIcon } from '@heroicons/react/20/solid';
import withGlobalProviders from "../../general/withGlobalProvider";

import {createHandleSubmit} from "../../../utility/helpers";
import toast from "react-hot-toast";
import {subscribe} from "../../../actions/subscribeActions";

function Index() {
  const user = useSelector((state) => state.session.currentUser);
  const dispatch = useDispatch();

  if (!user) return null;

  const { subscribeType, subscription } = user.attributes;
  const {
    first_name,
    last_name,
    office_phone,
    cellular_phone,
    company_name,
    title
  } = user.attributes.profile;
  const isActiveSubscription = subscribeType && [ 'active', 'trial_will_end' ].includes(subscription);

  const initialValues = {
    firstName: first_name || "",
    lastName: last_name,
    officePhone: office_phone,
    personalPhone: cellular_phone,
    companyName: company_name,
    title: title,
    subscription: {},
    profileState: user.attributes.profileState ? {
      value: user.attributes.profileState,
      label: user.attributes.profileState,
    } : null,
  };

  const onSubscribe = (e) => {
    const type = e.target.id.toString();
    dispatch(subscribe({type}))
      .then(({data: {url}}) => {
        if(url) {
          window.location.assign(url);
        }
      })
      .catch(() => {
        toast.error("There is a problem to subscribe.");
      })
  };

  return (
    <PageBody>
      <div className="flex flex-col">
        <div className="absolute -z-50 bg-gray-100 w-full" style={{height: "360px"}}></div>
        <h1 className="w-full lg:w-3/5 lg:ml-32 py-40 xl:px-32 md:px-20 px-10">
          <span className="mt-1 block text-4xl font-bold tracking-tight sm:text-3xl xl:text-4xl 3xl:text-5xl  4xl:text-6xl">
            <span className="block mb-3 text-black text-center lg:text-left">
              Maximize Your Visibility, Minimize Your Effort – Premium Placement Now Available!
            </span>
          </span>
        </h1>
        <div className="xl:px-64 md:px-20 px-10">
          <p className="text-lg text-black mb-6 w-full">
            Unlock unparalleled exposure for your lending or brokerage services with our premium placement in the search results of the go-to online directory for commercial real estate professionals. <br />Elevate your digital presence and connect with potential clients effortlessly.
          </p>
          <p className="text-xl font-bold text-black mb-12 md:w-9/10 w-full">
            Here's why premium placement is the key to dominating the online marketplace
          </p>
        </div>
        <div className="flex flex-col lg:flex-row mt-10 py-10 xl:px-64 md:px-20 px-10 bg-gray-100">
          <div className="flex flex-col md:w-1/2 w-full">
            <div className="px-5">
              <p className="text-lg text-black font-bold mb-6">Top-of-Search Visibility</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Catapult your profile to the forefront of search results.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Ensure your services are the first to be seen by potential clients.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Maximize visibility and stand out in a crowded marketplace.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Make a lasting impression on potential clients browsing the directory.</p>
            </div>
            <div className="mt-8 px-5">
              <p className="text-lg text-black font-bold mb-6">Strategic Positioning</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Secure prime real estate at the top of relevant search pages.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Position your brand as a leader in commercial real estate lending or brokerage.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Enhance credibility and trust with premium visibility.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Maximize opportunities to convert leads into clients.</p>
            </div>
          </div>
          <div className="flex flex-col md:w-1/2 w-full px-5">
            <div className="px-5">
              <p className="text-lg text-black font-bold mb-6">Lead Generation Powerhouse</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Generate more high-quality leads with enhanced visibility.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Increase inquiries and client interactions through strategic placement.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Secure your position as the go-to lender or broker in your niche.</p>
            </div>
            <div className="mt-8 px-5">
              <p className="text-lg text-black font-bold mb-6">Brand Authority Boost</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Establish your brand as a trusted authority in the commercial real estate space.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Build credibility through premium visibility and strategic positioning.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Elevate your brand recognition among industry professionals.</p>
            </div>
            <div className="mt-8 px-5">
              <p className="text-lg text-black font-bold mb-6">Time and Effort Savings</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Skip the struggle for attention – be the first choice for potential clients.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Minimize marketing efforts while maximizing results with premium visibility.</p>
              <CheckIcon className="float-left h-5 w-5 text-green-600" /><p className="px-8">Enjoy the convenience of attracting clients effortlessly.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-congress-blue-600 xl:px-64 md:px-20 px-10 py-12">
          <div className="flex flex-col w-full text-white">
            <p className="text-4xl font-bold w-full mb-8">Ready to dominate the online landscape and accelerate your business growth?</p>
            <p className="text-lg w-full">Secure your premium placement now in the top-tier commercial real estate directory</p>
          </div>
          <div className="flex flex-col bg-white w-full mt-12 rounded-md h-auto p-8">
            <div className="flex sm:flex-row flex-col items-center justify-between itmes-center mb-8">
              <p className="text-2xl font-bold">Premium Placement</p>
              <div className="flex items-center">
                <div className='flex flex-col text-center items-center'>
                  <div className="flex items-center">
                    <p className="text-lg text-black font-bold">$250</p>
                    <p className="text-sm ml-2">per month</p>
                  </div>
                  <div className="flex items-center">
                    <div className="text-black text-sm">Use Promo Code 50FIRST200 </div>
                    <p className="line-through decoration-slate-200 text-md text-gray ml-2"> $250</p>
                    <p className="text-lg text-black font-bold">$125</p>
                  </div>
                </div>
                {
                  isActiveSubscription && subscribeType === 'lender_premium_placement' ?
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-gray-400"
                      disabled
                    >
                      Subscribed
                    </button> :
                    <button
                      className="ml-5 mt-5 md:mt-0 sm:h-10 h-20 w-auto text-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-congress-blue-600 hover:bg-congress-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-congress-blue-500"
                      id="lender_premium_placement"
                      onClick={onSubscribe}>
                      Subscribe now
                    </button>
                }
              </div>
            </div>
            <hr />
            <p className="text-md text-black mt-6">Cancel anytime. No long-term commitments for monthly plans.</p>
          </div>
        </div>
      </div>
    </PageBody>
  );
}

export default withGlobalProviders(Index);
