import React, {useState} from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import withGlobalProviders from "../../../../general/withGlobalProvider";

import ListItem from './ListItem'
import ProspectListItem from "./ProspectListItem";

function Items({lenders, prospectors}) {
  // const { loaded, items } = useSelector((state) => state.loanRequests);
  const [savedLenders, setSavedLenders] = useState(lenders.data)
  const [savedProspectors, setSavedProspectors] = useState(prospectors.data)
  // const slicedItems = take(itemsArray, 2);

  function removeSavedLender(id) {
    setSavedLenders(savedLenders.filter(lender => lender.id !== id));
  }

  function removeSavedProspector(id) {
    setSavedProspectors(savedProspectors.filter(prospector => prospector.id !== id));
  }

  if (!savedLenders && !savedProspectors)
    return <p>there are no saved lenders</p>;

  return (
    <div className="flex space-y-6 flex-col">
      {savedLenders.map((savedLender) => (
        <ListItem lender={savedLender} onRemoveSavedLender={removeSavedLender} />
      ))}
      {savedProspectors.map((savedProspector) => (
        <ProspectListItem prospector={savedProspector} onRemoveSavedProspector={removeSavedProspector} />
      ))}
    </div>
  );
}

export default withGlobalProviders(Items);
